import React, { useRef, useEffect, useState } from "react";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import QuizVideoNavigation from "./components/QuizVideoNavigation";
import QuizImageNavigation from "./components/QuizImageNavigation";
import arrow from "../../assets/right-arrow-icon.png";
import "./styles/QuizModule.scss";
import { EnduserCertificate, EnduserQuiz, EnduserSlide, UserScreenCertificationStep } from "../models";
import Loading from "../global/components/Loading";
import {
	Statement,
	useQuiz_FinalizetocreateonlinequizresultMutation,
	useQuiz_GradingMutation,
} from "../../graphql/generated/endusergraphql";
import { Translation } from "../../translations/translationTypes";
import QuizMedia from "./components/QuizMedia";
import { ResultStatusType } from "../userScreenPage/components/UserScreen7";
import QuizMediaIMG from "./components/QuizMediaIMG";
import Counter from "./components/Counter";
import TimeIsUpBox from "./components/TimeIsUpBox";
import QuizArrows, { QuizArrow } from "./components/QuizArrow";
import { QuizInstantFeedback } from "./components/QuizInstantFeedback";

export interface QuizModuleProps {
	isPreview?: boolean;
	timeToComplete: string;
	certificate: EnduserCertificate;
	setTempLoader: (value: boolean | ((prevState: boolean) => boolean)) => void;
	slide: EnduserSlide;
	quizID: number | undefined;
	quizState: EnduserQuiz;
	currentCount: number;
	setCurrentCount: (value: number | ((prevState: number) => number)) => void;
	slideArrayLength: number;
	chosenLanguage: Translation;
	enduser: any;
	cpuserID: number;
}

/* This is the core of the quiz, it manages which kind of media to display and when all the functional things are coming up.
	It should probably divide its responsibility, but i see no way of doing it. */

export const QuizModule: React.FC<QuizModuleProps> = (props: QuizModuleProps) => {
	const [hasVideoEnded, setHasVideoEnded] = useState<boolean>(props.slide.media.slice(0,4) === "LINK" ? false : true);
	const [isVideo, setIsVideo] = useState<boolean>(props.slide.media.slice(0,4) === "LINK" ? true : false);
	const [isAnswered, setIsAnswered] = useState<boolean>(false);
	const [slideSrc, setSlideSrc] = useState(
		props.slide.media instanceof Blob ? URL.createObjectURL(props.slide.media) : props.slide.media,
	);
	const [timeTaken, setTimeTaken] = useState<string>();
	const [endPrematurely, setEndPrematurely] = useState(false);

	useEffect(() => {
		setHasVideoEnded(props.slide.media.slice(0,4) === "LINK" ? false : true);
		setIsVideo(props.slide.media.slice(0,4) === "LINK" ? true : false);
		setSlideSrc(props.slide.media instanceof Blob ? URL.createObjectURL(props.slide.media) : props.slide.media.slice(4));
	}, [props.slide.media]);

	/* history to manage route. redirect to quiz module */
	const history = useHistory();

	const [statements, setStatements] = useState(props.slide.statements);
	const [isSoundError, setIsSoundError] = useState(false);

	const [text, setText] = useState(props.slide.introQuestion);

	const [gradeSlideandStatements, { called }] = useQuiz_GradingMutation({
		onCompleted: (data) => {},
	});

	const [finalizeQuiz] = useQuiz_FinalizetocreateonlinequizresultMutation({
		onCompleted: (data) => {},
	});

	async function gradeQuiz() {
		if(!props.isPreview){
			const totalAmount = props.quizState.slides.length
			let correctAmount = 0;
			for (let i = 0; i < props.quizState.slides.length; ) {
				const gradedStatements: Statement[] = [];
			props.quizState.slides[i].statements.forEach(async (statement, statementIndex) => {
				if (statement.answer === statement.correctAnswer) {
					gradedStatements.push({ statement_id: statement.id, pass_status: true });
				} else {
					gradedStatements.push({ statement_id: statement.id, pass_status: false });
				}
			}),
				await gradeSlideandStatements({
					variables: {
						slide_to_be_graded: {
							quiz_slide_id: props.quizState.slides[i].id,
							statements: gradedStatements,
						},
					},
				}).then((data) => {
					data.data
					if(data.data?.quiz_grading === "slide_passed"){
						correctAmount++;
					}
					i++;
				});
			}
			return {totalAmount, correctAmount}
		}
	}

	let isNotAnswered = true;

	useEffect(() => {
		if (props.slide.statements.length === 0) {
			if (hasVideoEnded) {
				setIsAnswered(true);
			}
		} else {
			props.slide.statements.map((statement) => {
				if (statement.answer === 0) {
					isNotAnswered = false;
				}
			});
			if (isNotAnswered) {
				console.log("i execute");
				setIsAnswered(true);
			}
		}
	});

	/*useEffect for listening to if all statements are answered and ending the quiz*/
	useEffect(() => {
		console.log(props.slide.statements);

		if (isNotAnswered) {
			setIsAnswered(true);
			//Logic for ending quiz
			// if (props.slideArrayLength - 1 === props.currentCount) {
			// 	if (props.slide.statements.length !== 0) {
			// 		endQuiz()
			// 	}
			// }
		}
	}, [isAnswered]);

	/*useEffect for listening to when the timer runs out */
	useEffect(() => {
		if (endPrematurely) {
			setTimeout(() => endQuiz(), 4000);
		}
	}, [endPrematurely]);

	const sendQuizState = () => {
		const newQuizState = props.quizState;
		delete newQuizState.soundPlayer;
		delete newQuizState.indexPlayer;
		return newQuizState;
		
	}

	function endQuiz() {
		if(!props.isPreview){
		if (!called) {
			setTimeout(() => props.setTempLoader(true), 1500);
			gradeQuiz().then((data) => {
				const quizStatus = data;
				finalizeQuiz({
					variables: {
						online_quiz_id: props.quizID!,
						quiz_token_id: localStorage.getItem("quizToken")!,
						online_quiz_time_taken: endPrematurely ? props.timeToComplete : timeTaken,
					},
				}).then(async (data) => {
					console.log(data);

					if ((await data.data?.quiz_finalizetocreateonlinequizresult) === "quiz_no_tries_left") {
						history.replace({
							pathname: `/${props.certificate.id}`,
							state: {
								endedPrematurely: endPrematurely,
								userScreen: UserScreenCertificationStep.Seventh,
								resultStatus: ResultStatusType.FailedNoMoreTries,
								certificate: props.certificate,
								isEnd: true,
								enduser: props.enduser,
								finishDate: new Date(),
								quizStatus: quizStatus,
								quizState: sendQuizState()
							},
						});
					} else if (
						typeof (await data.data?.quiz_finalizetocreateonlinequizresult) === "number" ||
						(await data.data?.quiz_finalizetocreateonlinequizresult) === "quiz_no_try_limit"
					) {
						history.replace({
							pathname: `/${props.certificate.id}`,
							state: {
								endedPrematurely: endPrematurely,
								cpuserid: props.cpuserID,
								userScreen: UserScreenCertificationStep.Seventh,
								resultStatus: ResultStatusType.FailedMoreTries,
								certificate: props.certificate,
								isEnd: true,
								enduser: props.enduser,
								finishDate: new Date(),
								quizStatus: quizStatus,
								quizState: sendQuizState()
							},
						});
					}else if (await data.data?.quiz_finalizetocreateonlinequizresult === "read_only_passed"){
						history.replace({
							pathname: `/${props.certificate.id}`,
							state: {
								cpuserid: props.cpuserID,
								userScreen: UserScreenCertificationStep.Seventh,
								resultStatus: ResultStatusType.Passed,
								certificate: props.certificate,
								isEnd: true,
								enduser: props.enduser,
								isReadOnly: true,
								finishDate: new Date(),
								quizStatus: quizStatus,
								quizState: sendQuizState()
							},
						});
					}else if (await data.data?.quiz_finalizetocreateonlinequizresult === "read_only_not_passed"){
						history.replace({
							pathname: `/${props.certificate.id}`,
							state: {
								endedPrematurely: endPrematurely,
								cpuserid: props.cpuserID,
								userScreen: UserScreenCertificationStep.Seventh,
								resultStatus: ResultStatusType.FailedMoreTries,
								certificate: props.certificate,
								isEnd: true,
								enduser: props.enduser,
								isReadOnly: true,
								finishDate: new Date(),
								quizStatus: quizStatus,
								quizState: sendQuizState()
							},
						});
					} else {
						history.replace({
							pathname: `/${props.certificate.id}`,
							state: {
								cpuserid: props.cpuserID,
								userScreen: UserScreenCertificationStep.Seventh,
								resultStatus: ResultStatusType.Passed,
								certificate: data.data?.quiz_finalizetocreateonlinequizresult,
								isEnd: true,
								enduser: props.enduser,
								finishDate: new Date(),
								quizStatus: quizStatus,
								quizState: sendQuizState()
							},
						});
					}
				});
			});
		}
	}
	}

	const incrementClick = () => {
		
		if (props.slideArrayLength - 1 === props.currentCount) {
			endQuiz()
		}else{
			props.setCurrentCount(props.currentCount + 1);
			setHasVideoEnded(false);
			setIsAnswered(false);
		}
	};
	const decrementClick = () => {
		props.setCurrentCount(props.currentCount - 1);
	};

	useEffect(() => {
		setIsAnswered(false);
		setStatements(props.slide.statements);
		setText(props.slide.introQuestion);
	}, [props.slide]);

	// const defineVideo = (): void => {
	// 	if (typeof props.slide.media === "object") {
	// 		setHasVideoEnded(true);
	// 		setIsVideo(false);
	// 	} else {
	// 		setHasVideoEnded(false);
	// 		setIsVideo(true);
	// 	}
	// 	console.log("define video is done");
	// };


	const renderQuizMedia = (
		<>
			{isVideo ? (
				<QuizMedia
					isPreview={props.isPreview}
					key={slideSrc}
					mediaFile={slideSrc}
					isVideo={isVideo}
					hasVideoEnded={hasVideoEnded}
					setHasVideoEnded={setHasVideoEnded}
				/>
			) : (
				<QuizMediaIMG
					key={slideSrc}
					mediaFile={slideSrc}
					isVideo={isVideo}
					hasVideoEnded={hasVideoEnded}
					setHasVideoEnded={setHasVideoEnded}
				/>
			)}
		</>
	);

	return (
		<>
			<div className="quiz-module-page">
				<Counter
					key={props.timeToComplete}
					timeToComplete={props.timeToComplete}
					setTimeTaken={setTimeTaken}
					setEndPrematurely={setEndPrematurely}
				></Counter>
				{renderQuizMedia}
				{props.slide.statements.length > 0 ? (
					<QuizImageNavigation
						isPreview={props.isPreview}
						hasVideoEnded={hasVideoEnded}
						statements={statements}
						setStatements={setStatements}
						text={text}
						soundPlayer={props.quizState.soundPlayer!}
						indexPlayer={props.quizState.indexPlayer!}
						introQuestion={props.slide.introQuestion}
						introQuestionSound={props.slide.introQuestionSound}
						chosenLanguage={props.chosenLanguage}
						setIsAnswered={setIsAnswered}
						isAnswered={isAnswered}
					/>
				) : null}

				<div className="rightArrow">
					<QuizArrow
						key={props.currentCount}
						isAnswered={isAnswered}
						currentCount={props.currentCount}
						slideArrayLength={props.slideArrayLength}
						onClick={incrementClick}
						slide={props.slide}
					/>
				</div>
			</div>
			{endPrematurely ? <TimeIsUpBox endQuiz={endQuiz} languageString={props.chosenLanguage} /> : null}
		</>
	);
};

export default QuizModule;
