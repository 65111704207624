import {
	ActiveInactiveType,
	CertificateHeadlineType,
	CertificateLanguageType,
	CertificateStatusType,
	DateFormatType,
	QuestionAnswerType,
} from "../pages/models";
import { getStringsForChosenLanguage } from "./translationProvider";
import { LanguageType, Translation } from "./translationTypes";

export const convertCertificateStatusTypeToString = (
	certificateStatusType: boolean,
	languageString: Translation,
): string => {
	if (certificateStatusType) {
		return languageString.enums.certificateStatusType.EditDraft;
	} else {
		return languageString.enums.certificateStatusType.Active;
	}

	//return languageString.enums.certificateStatusType.Expired;
};

export const convertActiveInactiveTypeToString = (
	activeInactiveType: ActiveInactiveType,
	languageString: Translation,
): string => {
	switch (activeInactiveType) {
		case ActiveInactiveType.On: {
			return languageString.enums.activeInactiveType.On;
		}
		case ActiveInactiveType.Off: {
			return languageString.enums.activeInactiveType.Off;
		}
		default: {
			return "";
		}
	}
};

export const convertDateFormatTypeToString = (dateFormatType: DateFormatType, languageString: Translation): string => {
	switch (dateFormatType) {
		case DateFormatType.UK: {
			return languageString.enums.dateFormatType.UK;
		}
		case DateFormatType.US: {
			return languageString.enums.dateFormatType.US;
		}
		case DateFormatType.EU: {
			return languageString.enums.dateFormatType.EU;
		}
		default: {
			return "";
		}
	}
};

export const convertCertificateHeadlineTypeToString = (
	certificateHeadlineType: CertificateHeadlineType,
	languageString: Translation,
): string => {
	switch (certificateHeadlineType) {
		case CertificateHeadlineType.OnlineCourse: {
			return languageString.enums.certificateHeadlineType.OnlineCourse;
		}

		case CertificateHeadlineType.Certificate: {
			return languageString.enums.certificateHeadlineType.Certificate;
		}
		case CertificateHeadlineType.Diploma: {
			return languageString.enums.certificateHeadlineType.Diploma;
		}
		case CertificateHeadlineType.ProofOfCompletion: {
			return languageString.enums.certificateHeadlineType.ProofOfCompletion;
		}
		default: {
			return "";
		}
	}
};

export const convertCertificateLanguageTypeToString = (
	certificateLanguageType: CertificateLanguageType,
	languageString: Translation,
): string => {
	switch (certificateLanguageType) {
		case CertificateLanguageType.English: {
			return languageString.enums.certificateLanguageType.English;
		}
		case CertificateLanguageType.Danish: {
			return languageString.enums.certificateLanguageType.Danish;
		}
		case CertificateLanguageType.Polish: {
			return languageString.enums.certificateLanguageType.Polish;
		}
		default: {
			return "";
		}
	}
};

export const convertIDToHeadlineType = (id: number): CertificateHeadlineType => {
	switch (id) {
		case 1: {
			return CertificateHeadlineType.OnlineCourse;
		}
		case 2: {
			return CertificateHeadlineType.Certificate;
		}
		case 3: {
			return CertificateHeadlineType.Diploma;
		}
		case 4: {
			return CertificateHeadlineType.ProofOfCompletion;
		}
		default: {
			return CertificateHeadlineType.Certificate;
		}
	}
};

export const convertQuestionAnswerTypeToString = (
	questionAnswerType: QuestionAnswerType,
	languageString: Translation,
): string => {
	switch (questionAnswerType) {
		case QuestionAnswerType.Yes: {
			return languageString.enums.questionAnswerType.Yes;
		}
		case QuestionAnswerType.No: {
			return languageString.enums.questionAnswerType.No;
		}
		case QuestionAnswerType.Both: {
			return languageString.enums.questionAnswerType.Both;
		}
		default: {
			return "";
		}
	}
};

export const convertIDToLanguageString = (id: number): Translation => {
	switch (id) {
		case 1:
			return getStringsForChosenLanguage(LanguageType.Danish);
		case 2:
			return getStringsForChosenLanguage(LanguageType.English);
		case 3:
			return getStringsForChosenLanguage(LanguageType.Polish);
		case 4:
			return getStringsForChosenLanguage(LanguageType.EnglishUS);
		case 5: 
			return getStringsForChosenLanguage(LanguageType.Finnish);
		case 6:
			return getStringsForChosenLanguage(LanguageType.French);
		case 7:
			return getStringsForChosenLanguage(LanguageType.German);
		case 8:
			return getStringsForChosenLanguage(LanguageType.Italian);
		case 9: 
			return getStringsForChosenLanguage(LanguageType.Norwegian);
		case 10: 
			return getStringsForChosenLanguage(LanguageType.Spanish);
		case 11: 
			return getStringsForChosenLanguage(LanguageType.Swedish);
		default:
			return getStringsForChosenLanguage(LanguageType.English);
	}
};

export const notEnduserLanguages = (): string[] => {
	return ["English", "Danish"];
}
