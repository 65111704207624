import React, { FC } from "react";
import { getChosenLanguage, getStringsForChosenLanguage } from "../../../translations/translationProvider";
import Logo from "../../../assets/EP_2022_LOGO.png";
import "./styles/EasyPiecyLogo.scss";
import { useHistory } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../constants/pathConstants";

export interface EasyPiecyLogoProps {
	hasDescription?: boolean;
	homeClickDisabled?: boolean;
}

export const EasyPiecyLogo: FC<EasyPiecyLogoProps> = (props: EasyPiecyLogoProps) => {
	const languageStrings = getStringsForChosenLanguage(getChosenLanguage());
	const history = useHistory();
	const handleOnClick = () => {
		if (props.homeClickDisabled) {
			return;
		} else {
			history.push(PATH_DASHBOARD);
		}
	};

	return (
		<>
			<img
				className={`logoHeading ${props.homeClickDisabled ? "" : "clickable"}`}
				src={Logo}
				alt="EasyPiecy Logo"
				onClick={handleOnClick}
			/>
			{props.hasDescription ? (
				<p className="descriptionHeading">{languageStrings.global.easyPiecyLogo.description}</p>
			) : null}
		</>
	);
};

export default EasyPiecyLogo;
