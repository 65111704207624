import React, { FC } from "react";
import "./styles/CertificateConfigurationTextArea.scss";

export interface CertificateConfigurationTextAreaProps {
	isFaded?: boolean;
	labelText?: string;
	hasSemicolonAfterLabel?: boolean;
	placeholder: string;
	rows: number;
	cols: number;
	value: string;
	handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	characterLimit?: number;
}

export const CertificateConfigurationTextArea: FC<CertificateConfigurationTextAreaProps> = (
	props: CertificateConfigurationTextAreaProps,
) => {
	const label = props.labelText ? (
		<div>
			<label>
				{props.labelText}
				{props.hasSemicolonAfterLabel ? ": " : ""}
			</label>
		</div>
	) : null;

	return (
		<div className={props.isFaded ? "textFieldConfig fadedStyle" : "textFieldConfig"}>
			{label}
			<textarea
				placeholder={props.placeholder}
				className="textareaNotResizable"
				rows={props.rows}
				cols={props.cols}
				maxLength={props.characterLimit}
				value={props.value}
				onChange={props.handleChange}
			/>
		</div>
	);
};

export default CertificateConfigurationTextArea;
