import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  Time: any;
};

export type Cpc = {
  email: Scalars['String'];
  company_name: Scalars['String'];
  optional_note?: Maybe<Scalars['String']>;
};

export type Cpc_Update = {
  cpc_id: Scalars['ID'];
  company_name: Scalars['String'];
  optional_note?: Maybe<Scalars['String']>;
};

export type Content = {
  content_id?: Maybe<Scalars['Int']>;
  certificate_id: Scalars['Int'];
  certificate_language?: Maybe<Scalars['Int']>;
  should_auto_gen?: Maybe<Scalars['Boolean']>;
  pitch?: Maybe<Scalars['Int']>;
  rate?: Maybe<Scalars['Int']>;
  content_create_date?: Maybe<Scalars['DateTime']>;
  content_mod_date?: Maybe<Scalars['DateTime']>;
  slides: Array<Maybe<Slide>>;
};


export type InsertLaterDate = {
  certificate_id: Scalars['Int'];
  enduser_ids: Scalars['String'];
  date: Scalars['DateTime'];
};


export type Mutation = {
  __typename?: 'Mutation';
  /** Management of User object */
  CreateCPC: Scalars['JSON'];
  UpdateCPC: Scalars['JSON'];
  LoginUser: User;
  RefreshUser: User;
  AddPointUser: Scalars['Int'];
  AddCPC: Scalars['JSON'];
  UserLogout: Scalars['JSON'];
  deleteCPC: Scalars['String'];
  deleteCertificateClient: Scalars['String'];
  /** Management of End-user object */
  manageInvoice: Scalars['JSON'];
  deleteEnduserBatch: Scalars['JSON'];
  updateLaterDate?: Maybe<Scalars['JSON']>;
  /** Certificate Object management */
  manageCertificate?: Maybe<Scalars['String']>;
  addLogoCertificate: Scalars['String'];
  certDraftStatus: Scalars['String'];
  certPublish: Scalars['JSON'];
  certExpiryDate: Scalars['String'];
  certPhoneNumbers: Scalars['String'];
  deleteCertificate: Scalars['String'];
  cloneCertificate: Scalars['JSON'];
  /**
   * Content object management
   *     These mutations are upserts for objects inside Content service domain
   */
  manageContent: Scalars['JSON'];
  manageSlide: Scalars['String'];
  manageStatement: Scalars['String'];
  manageTag: Scalars['String'];
  addTagSlide: Scalars['String'];
  manageStatementSound: Scalars['String'];
  manageDefaultSound: Scalars['String'];
  /** Delete mutations for content (Soft delete, no objects are deleted) */
  deleteContent: Scalars['String'];
  deleteSlide: Scalars['String'];
  deleteTag: Scalars['String'];
  deleteTagSlide: Scalars['String'];
  deleteStatement: Scalars['String'];
  deleteStatementSound: Scalars['String'];
  deleteDefNarration: Scalars['String'];
};


export type MutationCreateCpcArgs = {
  new_cpc: Cpc;
};


export type MutationUpdateCpcArgs = {
  cpc_update: Cpc_Update;
};


export type MutationLoginUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRefreshUserArgs = {
  old_token: Scalars['String'];
};


export type MutationAddPointUserArgs = {
  added_user_id: Scalars['Int'];
  points_add: Scalars['Int'];
};


export type MutationAddCpcArgs = {
  cpc_list: Array<Maybe<Certificate_Cpc>>;
};


export type MutationDeleteCpcArgs = {
  cpc_list?: Maybe<Array<Scalars['Int']>>;
};


export type MutationDeleteCertificateClientArgs = {
  certificate_id: Scalars['Int'];
  cpc_id: Scalars['Int'];
};


export type MutationManageInvoiceArgs = {
  user_invoices?: Maybe<Array<Invoiced>>;
};


export type MutationDeleteEnduserBatchArgs = {
  enduser_list?: Maybe<Array<EndUser>>;
};


export type MutationUpdateLaterDateArgs = {
  certificate_id: Scalars['Int'];
  date: Scalars['DateTime'];
  enduser_ids: Scalars['String'];
};


export type MutationManageCertificateArgs = {
  certificate_id?: Maybe<Scalars['Int']>;
  cert_name: Scalars['String'];
  cert_internal_name: Scalars['String'];
  cert_content_desc: Scalars['String'];
  cert_optional_value?: Maybe<Scalars['String']>;
  cert_custom_logo?: Maybe<Scalars['String']>;
  cert_date_format: Scalars['String'];
  cert_creation_date: Scalars['DateTime'];
  cert_expiry_date: Scalars['DateTime'];
  cert_time_to_complete?: Maybe<Scalars['Time']>;
  cert_headline_id: Scalars['Int'];
  ep_cert_language_id: Scalars['Int'];
  cert_sms_skip?: Maybe<Scalars['Boolean']>;
  cert_sms_text?: Maybe<Scalars['String']>;
  cert_shuffle_slides?: Maybe<Scalars['Boolean']>;
  cert_shuffle_statements?: Maybe<Scalars['Boolean']>;
  cert_fixed_slides?: Maybe<Scalars['Int']>;
  cert_download_allowed?: Maybe<Scalars['Boolean']>;
  cert_max_tries: Scalars['Int'];
  cert_max_uses: Scalars['Int'];
  cert_refresh?: Maybe<Scalars['Boolean']>;
  cert_refresh_days?: Maybe<Scalars['Int']>;
  cert_individual_slide_max_error: Scalars['Int'];
  cert_select_users?: Maybe<Scalars['Boolean']>;
  cert_optional_min?: Maybe<Scalars['Int']>;
  cert_optional_max?: Maybe<Scalars['Int']>;
  cert_is_draft: Scalars['Boolean'];
  cert_cpc?: Maybe<Scalars['Int']>;
  cert_phone_numbers?: Maybe<Scalars['String']>;
  cert_tags?: Maybe<Array<Maybe<Tag>>>;
};


export type MutationAddLogoCertificateArgs = {
  certificate_id: Scalars['Int'];
  new_logo: Scalars['String'];
  new_logo_color?: Maybe<Scalars['String']>;
};


export type MutationCertDraftStatusArgs = {
  certificate_id: Scalars['Int'];
  cert_is_draft: Scalars['Boolean'];
};


export type MutationCertPublishArgs = {
  certificate_id: Scalars['Int'];
};


export type MutationCertExpiryDateArgs = {
  certificate_id: Scalars['Int'];
  cert_expiry_date: Scalars['DateTime'];
};


export type MutationCertPhoneNumbersArgs = {
  certificate_id: Scalars['Int'];
  cert_phone_numbers: Scalars['String'];
};


export type MutationDeleteCertificateArgs = {
  certificate_id: Scalars['Int'];
};


export type MutationCloneCertificateArgs = {
  certificate_id: Scalars['Int'];
  new_internal_name?: Maybe<Scalars['String']>;
  new_logo?: Maybe<Scalars['String']>;
  new_logo_color?: Maybe<Scalars['String']>;
};


export type MutationManageContentArgs = {
  new_content: Content;
};


export type MutationManageSlideArgs = {
  new_slide: Slide;
};


export type MutationManageStatementArgs = {
  New_statement: Statement;
};


export type MutationManageTagArgs = {
  new_tag: Tag;
  certificate_id?: Maybe<Scalars['Int']>;
};


export type MutationAddTagSlideArgs = {
  tag_id: Scalars['Int'];
  slide_id: Scalars['Int'];
};


export type MutationManageStatementSoundArgs = {
  new_statement_sound: Statement_Sound;
};


export type MutationManageDefaultSoundArgs = {
  new_statement_sound: Statement_Sound;
};


export type MutationDeleteContentArgs = {
  deleted_content: Scalars['Int'];
};


export type MutationDeleteSlideArgs = {
  deleted_slide: Scalars['Int'];
};


export type MutationDeleteTagArgs = {
  deleted_tag_id: Scalars['Int'];
};


export type MutationDeleteTagSlideArgs = {
  tag_id: Scalars['Int'];
  slide_id: Scalars['Int'];
};


export type MutationDeleteStatementArgs = {
  delete_statement_id: Scalars['Int'];
};


export type MutationDeleteStatementSoundArgs = {
  deleted_statement_sound: Scalars['Int'];
};


export type MutationDeleteDefNarrationArgs = {
  deleted_statement_sound: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  /** User service data lookouts */
  getUsername: Scalars['String'];
  getCPC: Scalars['JSON'];
  getCertificateClients: Scalars['JSON'];
  GetUserPoints: Scalars['Int'];
  GetUserPointsLeft: Scalars['Int'];
  getUserLogo?: Maybe<Scalars['String']>;
  getUserLogoCert?: Maybe<Scalars['String']>;
  /** Certificate service data lookouts */
  getLanguages?: Maybe<Scalars['JSON']>;
  getHeadlines?: Maybe<Scalars['JSON']>;
  getCertificate?: Maybe<Scalars['JSON']>;
  getCPCDropdown?: Maybe<Scalars['JSON']>;
  getNumbers?: Maybe<Scalars['JSON']>;
  getCertPhoneNumbers?: Maybe<Scalars['JSON']>;
  dashboardView?: Maybe<Scalars['JSON']>;
  getCertCount: Scalars['Int'];
  verCertificate: Scalars['Boolean'];
  /** Content service data lookouts */
  getContentId: Scalars['JSON'];
  getContentCertificate: Scalars['JSON'];
  getSlide: Scalars['JSON'];
  getContentSlides: Scalars['JSON'];
  getSlideStatements: Scalars['JSON'];
  getStatementSounds: Scalars['JSON'];
  getDefaultNarrations: Scalars['JSON'];
  getAvailableSounds: Scalars['JSON'];
  getSlideMedia: Scalars['JSON'];
  getAvailableTags: Scalars['JSON'];
  getSlideTags: Scalars['JSON'];
  getMediaTypes: Scalars['JSON'];
  getPossibleAnswers: Scalars['JSON'];
  /** Azure queries */
  getSAS: Scalars['String'];
  encoderTask: Scalars['JSON'];
  encodingProgress: Scalars['JSON'];
  /** Statistics service data lookouts */
  getCP?: Maybe<Scalars['JSON']>;
  getCPC_stats?: Maybe<Scalars['JSON']>;
  getCertificates?: Maybe<Scalars['JSON']>;
  getEndusers?: Maybe<Scalars['JSON']>;
  getEndusersCPC?: Maybe<Scalars['JSON']>;
  getAllTags?: Maybe<Scalars['JSON']>;
  getCPCTagInfo?: Maybe<Scalars['JSON']>;
  getCertTagInfo?: Maybe<Scalars['JSON']>;
  getTagEndusers?: Maybe<Scalars['JSON']>;
  getTodaysSMS?: Maybe<Scalars['JSON']>;
  triggerSendLowPointEmails?: Maybe<Scalars['JSON']>;
  checkPassword?: Maybe<Scalars['JSON']>;
  sendDiplomaSMSNow?: Maybe<Scalars['JSON']>;
};


export type QueryGetCertificateClientsArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryGetUserLogoArgs = {
  user_id?: Maybe<Scalars['Int']>;
};


export type QueryGetUserLogoCertArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryGetLanguagesArgs = {
  language_id?: Maybe<Scalars['Int']>;
};


export type QueryGetHeadlinesArgs = {
  language_id?: Maybe<Scalars['Int']>;
};


export type QueryGetCertificateArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryGetCpcDropdownArgs = {
  cp_id?: Maybe<Scalars['ID']>;
  cpc_id: Scalars['ID'];
};


export type QueryGetNumbersArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryGetCertPhoneNumbersArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryVerCertificateArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryGetContentIdArgs = {
  content_id: Scalars['Int'];
};


export type QueryGetContentCertificateArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryGetSlideArgs = {
  slide_id: Scalars['Int'];
};


export type QueryGetContentSlidesArgs = {
  content_id: Scalars['Int'];
};


export type QueryGetSlideStatementsArgs = {
  slide_id: Scalars['Int'];
};


export type QueryGetStatementSoundsArgs = {
  statement_id: Scalars['Int'];
};


export type QueryGetAvailableSoundsArgs = {
  statement_id: Scalars['Int'];
};


export type QueryGetSlideMediaArgs = {
  slide_id: Scalars['Int'];
};


export type QueryGetAvailableTagsArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryGetSlideTagsArgs = {
  slide_id: Scalars['Int'];
};


export type QueryEncoderTaskArgs = {
  file_name: Scalars['String'];
};


export type QueryEncodingProgressArgs = {
  certificate_id: Scalars['ID'];
};


export type QueryGetCpc_StatsArgs = {
  user_id: Scalars['Int'];
};


export type QueryGetCertificatesArgs = {
  CPC_User_id?: Maybe<Scalars['Int']>;
};


export type QueryGetEndusersArgs = {
  certificate_id: Scalars['Int'];
  tag_id?: Maybe<Scalars['Int']>;
};


export type QueryGetEndusersCpcArgs = {
  CPC_User_id: Scalars['Int'];
};


export type QueryGetAllTagsArgs = {
  cert_id?: Maybe<Scalars['ID']>;
  cpc_id?: Maybe<Scalars['ID']>;
  cp_id?: Maybe<Scalars['ID']>;
};


export type QueryGetCpcTagInfoArgs = {
  cpc_id?: Maybe<Scalars['ID']>;
};


export type QueryGetCertTagInfoArgs = {
  cert_id: Scalars['ID'];
};


export type QueryGetTagEndusersArgs = {
  tag_id: Scalars['ID'];
};


export type QueryCheckPasswordArgs = {
  id: Scalars['Int'];
  password: Scalars['String'];
};


export type QuerySendDiplomaSmsNowArgs = {
  users?: Maybe<Array<Maybe<UserInput>>>;
  certInfo?: Maybe<CertInfo>;
};

export type RegisterUserInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  confirmpassword: Scalars['String'];
  email: Scalars['String'];
  role: Scalars['String'];
};

export type Slide = {
  slide_id?: Maybe<Scalars['Int']>;
  content_id?: Maybe<Scalars['Int']>;
  intro_question?: Maybe<Scalars['String']>;
  intro_question_media_location?: Maybe<Scalars['String']>;
  media_location?: Maybe<Scalars['String']>;
  slide_weight?: Maybe<Scalars['Float']>;
  slide_create_date?: Maybe<Scalars['DateTime']>;
  slide_modified_date?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  statements?: Maybe<Array<Maybe<Statement>>>;
};

export type Statement = {
  statement_id?: Maybe<Scalars['Int']>;
  slide_id?: Maybe<Scalars['Int']>;
  statement_elaboration?: Maybe<Scalars['String']>;
  statement_answer_id: Scalars['Int'];
  statement_create_date?: Maybe<Scalars['DateTime']>;
  statement_modified_date?: Maybe<Scalars['DateTime']>;
  statement_sound?: Maybe<Array<Maybe<Statement_Sound>>>;
};

export type Statement_Sound = {
  statement_sound_id?: Maybe<Scalars['Int']>;
  statement_id?: Maybe<Scalars['Int']>;
  statement_narration?: Maybe<Scalars['String']>;
  statement_sound_location?: Maybe<Scalars['String']>;
};

export type Tag = {
  tag_id?: Maybe<Scalars['Int']>;
  tag_name?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type CertInfo = {
  certName: Scalars['String'];
  certCode: Scalars['String'];
};

export type Certificate_Cpc = {
  certificate_id: Scalars['Int'];
  cpc_id: Scalars['Int'];
};

export type EndUser = {
  enduser_id: Scalars['ID'];
};

export type Invoiced = {
  enduser_id: Scalars['ID'];
  invoiced: Scalars['Boolean'];
};

export type UserInput = {
  phoneNumber: Scalars['String'];
  userID: Scalars['Int'];
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { LoginUser: (
    { __typename?: 'User' }
    & Pick<User, 'accessToken' | 'refreshToken'>
  ) }
);

export type RefreshUserMutationVariables = Exact<{
  old_token: Scalars['String'];
}>;


export type RefreshUserMutation = (
  { __typename?: 'Mutation' }
  & { RefreshUser: (
    { __typename?: 'User' }
    & Pick<User, 'refreshToken' | 'accessToken'>
  ) }
);

export type DashboardViewQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardViewQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'dashboardView'>
);

export type GetLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLanguagesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLanguages'>
);

export type GetHeadlinesQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
}>;


export type GetHeadlinesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getHeadlines'>
);

export type DeleteCertificateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCertificate'>
);

export type CloneCertificateMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
  new_internal_name?: Maybe<Scalars['String']>;
  new_logo?: Maybe<Scalars['String']>;
  new_logo_color?: Maybe<Scalars['String']>;
}>;


export type CloneCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cloneCertificate'>
);

export type GetUsernameQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsernameQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUsername'>
);

export type GetClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCPC'>
);

export type LogOutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UserLogout'>
);

export type ManageCertificateMutationVariables = Exact<{
  certificate_id?: Maybe<Scalars['Int']>;
  cert_name: Scalars['String'];
  cert_internal_name: Scalars['String'];
  cert_content_desc: Scalars['String'];
  cert_is_draft: Scalars['Boolean'];
  cert_optional_value?: Maybe<Scalars['String']>;
  cert_date_format: Scalars['String'];
  cert_creation_date: Scalars['DateTime'];
  cert_time_to_complete?: Maybe<Scalars['Time']>;
  cert_expiry_date: Scalars['DateTime'];
  cert_headline_id: Scalars['Int'];
  ep_cert_language_id: Scalars['Int'];
  cert_shuffle_slides?: Maybe<Scalars['Boolean']>;
  cert_shuffle_statements?: Maybe<Scalars['Boolean']>;
  cert_fixed_slides?: Maybe<Scalars['Int']>;
  cert_download_allowed?: Maybe<Scalars['Boolean']>;
  cert_max_tries: Scalars['Int'];
  cert_max_uses: Scalars['Int'];
  cert_select_users?: Maybe<Scalars['Boolean']>;
  cert_optional_min?: Maybe<Scalars['Int']>;
  cert_optional_max?: Maybe<Scalars['Int']>;
  cert_cpc: Scalars['Int'];
  cert_phone_numbers?: Maybe<Scalars['String']>;
  cert_individual_slide_max_error: Scalars['Int'];
  cert_tags?: Maybe<Array<Maybe<Tag>> | Maybe<Tag>>;
  cert_custom_logo?: Maybe<Scalars['String']>;
  cert_sms_text?: Maybe<Scalars['String']>;
  cert_sms_skip?: Maybe<Scalars['Boolean']>;
  cert_refresh?: Maybe<Scalars['Boolean']>;
  cert_refresh_days?: Maybe<Scalars['Int']>;
}>;


export type ManageCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageCertificate'>
);

export type GetCertificateQueryVariables = Exact<{
  certificate_id: Scalars['Int'];
}>;


export type GetCertificateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCertificate'>
);

export type GetContentCertificateQueryVariables = Exact<{
  certificate_id: Scalars['Int'];
}>;


export type GetContentCertificateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContentCertificate'>
);

export type GetAvailableTagsQueryVariables = Exact<{
  certificate_id: Scalars['Int'];
}>;


export type GetAvailableTagsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAvailableTags'>
);

export type GetDefaultNarrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultNarrationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDefaultNarrations'>
);

export type ManageContentMutationVariables = Exact<{
  new_content: Content;
}>;


export type ManageContentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageContent'>
);

export type AddLogoCertificateMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
  new_logo: Scalars['String'];
  new_logo_color: Scalars['String'];
}>;


export type AddLogoCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addLogoCertificate'>
);

export type CertDraftStatusMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
  cert_is_draft: Scalars['Boolean'];
}>;


export type CertDraftStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'certDraftStatus'>
);

export type GetSasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSAS'>
);

export type CertExpiryDateMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
  cert_expiry_date: Scalars['DateTime'];
}>;


export type CertExpiryDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'certExpiryDate'>
);

export type CertPhoneNumbersMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
  cert_phone_numbers: Scalars['String'];
}>;


export type CertPhoneNumbersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'certPhoneNumbers'>
);

export type GetUserPointsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserPointsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetUserPoints'>
);

export type GetUserPointsLeftQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserPointsLeftQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetUserPointsLeft'>
);

export type CreateCpcMutationVariables = Exact<{
  new_cpc: Cpc;
}>;


export type CreateCpcMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateCPC'>
);

export type CertPublishMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
}>;


export type CertPublishMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'certPublish'>
);

export type DeleteCpcMutationVariables = Exact<{
  cpc_list?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteCpcMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCPC'>
);

export type UpdateCpcMutationVariables = Exact<{
  cpc_update: Cpc_Update;
}>;


export type UpdateCpcMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateCPC'>
);

export type GetEndusersQueryVariables = Exact<{
  certificate_id: Scalars['Int'];
  tag_id?: Maybe<Scalars['Int']>;
}>;


export type GetEndusersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEndusers'>
);

export type GetCpcDropdownQueryVariables = Exact<{
  cpc_id: Scalars['ID'];
}>;


export type GetCpcDropdownQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCPCDropdown'>
);

export type ManageInvoiceMutationVariables = Exact<{
  user_invoices?: Maybe<Array<Invoiced> | Invoiced>;
}>;


export type ManageInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'manageInvoice'>
);

export type DeleteEnduserBatchMutationVariables = Exact<{
  enduser_list?: Maybe<Array<EndUser> | EndUser>;
}>;


export type DeleteEnduserBatchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEnduserBatch'>
);

export type GetEndusersCpcQueryVariables = Exact<{
  CPC_User_id: Scalars['Int'];
}>;


export type GetEndusersCpcQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEndusersCPC'>
);

export type GetCertificatesQueryVariables = Exact<{
  CPC_User_id: Scalars['Int'];
}>;


export type GetCertificatesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCertificates'>
);

export type GetUserLogoQueryVariables = Exact<{
  user_id?: Maybe<Scalars['Int']>;
}>;


export type GetUserLogoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserLogo'>
);

export type GetUserLogoCertQueryVariables = Exact<{
  certificate_id: Scalars['Int'];
}>;


export type GetUserLogoCertQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserLogoCert'>
);

export type GetCertificateClientsQueryVariables = Exact<{
  certificate_id: Scalars['Int'];
}>;


export type GetCertificateClientsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCertificateClients'>
);

export type AddCpcMutationVariables = Exact<{
  cpc_list: Array<Maybe<Certificate_Cpc>> | Maybe<Certificate_Cpc>;
}>;


export type AddCpcMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AddCPC'>
);

export type DeleteCertificateClientMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
  cpc_id: Scalars['Int'];
}>;


export type DeleteCertificateClientMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCertificateClient'>
);

export type GetAllTagsQueryVariables = Exact<{
  cert_id?: Maybe<Scalars['ID']>;
  cpc_id?: Maybe<Scalars['ID']>;
  cp_id?: Maybe<Scalars['ID']>;
}>;


export type GetAllTagsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllTags'>
);

export type GetCpcTagInfoQueryVariables = Exact<{
  cpc_id: Scalars['ID'];
}>;


export type GetCpcTagInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCPCTagInfo'>
);

export type GetCertTagInfoQueryVariables = Exact<{
  cert_id: Scalars['ID'];
}>;


export type GetCertTagInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCertTagInfo'>
);

export type GetTagEndusersQueryVariables = Exact<{
  tag_id: Scalars['ID'];
}>;


export type GetTagEndusersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTagEndusers'>
);

export type CheckPasswordQueryVariables = Exact<{
  id: Scalars['Int'];
  password: Scalars['String'];
}>;


export type CheckPasswordQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkPassword'>
);

export type UpdateLaterDateMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
  date: Scalars['DateTime'];
  enduser_ids: Scalars['String'];
}>;


export type UpdateLaterDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLaterDate'>
);

export type SendDiplomaSmsQueryVariables = Exact<{
  users?: Maybe<Array<Maybe<UserInput>> | Maybe<UserInput>>;
  certInfo: CertInfo;
}>;


export type SendDiplomaSmsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sendDiplomaSMSNow'>
);


export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  LoginUser(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RefreshUserDocument = gql`
    mutation RefreshUser($old_token: String!) {
  RefreshUser(old_token: $old_token) {
    refreshToken
    accessToken
  }
}
    `;
export type RefreshUserMutationFn = Apollo.MutationFunction<RefreshUserMutation, RefreshUserMutationVariables>;

/**
 * __useRefreshUserMutation__
 *
 * To run a mutation, you first call `useRefreshUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUserMutation, { data, loading, error }] = useRefreshUserMutation({
 *   variables: {
 *      old_token: // value for 'old_token'
 *   },
 * });
 */
export function useRefreshUserMutation(baseOptions?: Apollo.MutationHookOptions<RefreshUserMutation, RefreshUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshUserMutation, RefreshUserMutationVariables>(RefreshUserDocument, options);
      }
export type RefreshUserMutationHookResult = ReturnType<typeof useRefreshUserMutation>;
export type RefreshUserMutationResult = Apollo.MutationResult<RefreshUserMutation>;
export type RefreshUserMutationOptions = Apollo.BaseMutationOptions<RefreshUserMutation, RefreshUserMutationVariables>;
export const DashboardViewDocument = gql`
    query dashboardView {
  dashboardView
}
    `;

/**
 * __useDashboardViewQuery__
 *
 * To run a query within a React component, call `useDashboardViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardViewQuery(baseOptions?: Apollo.QueryHookOptions<DashboardViewQuery, DashboardViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardViewQuery, DashboardViewQueryVariables>(DashboardViewDocument, options);
      }
export function useDashboardViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardViewQuery, DashboardViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardViewQuery, DashboardViewQueryVariables>(DashboardViewDocument, options);
        }
export type DashboardViewQueryHookResult = ReturnType<typeof useDashboardViewQuery>;
export type DashboardViewLazyQueryHookResult = ReturnType<typeof useDashboardViewLazyQuery>;
export type DashboardViewQueryResult = Apollo.QueryResult<DashboardViewQuery, DashboardViewQueryVariables>;
export const GetLanguagesDocument = gql`
    query getLanguages {
  getLanguages
}
    `;

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
      }
export function useGetLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLanguagesQuery, GetLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLanguagesQuery, GetLanguagesQueryVariables>(GetLanguagesDocument, options);
        }
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>;
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>;
export type GetLanguagesQueryResult = Apollo.QueryResult<GetLanguagesQuery, GetLanguagesQueryVariables>;
export const GetHeadlinesDocument = gql`
    query getHeadlines($id: Int) {
  getHeadlines(language_id: $id)
}
    `;

/**
 * __useGetHeadlinesQuery__
 *
 * To run a query within a React component, call `useGetHeadlinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeadlinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeadlinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHeadlinesQuery(baseOptions?: Apollo.QueryHookOptions<GetHeadlinesQuery, GetHeadlinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeadlinesQuery, GetHeadlinesQueryVariables>(GetHeadlinesDocument, options);
      }
export function useGetHeadlinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeadlinesQuery, GetHeadlinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeadlinesQuery, GetHeadlinesQueryVariables>(GetHeadlinesDocument, options);
        }
export type GetHeadlinesQueryHookResult = ReturnType<typeof useGetHeadlinesQuery>;
export type GetHeadlinesLazyQueryHookResult = ReturnType<typeof useGetHeadlinesLazyQuery>;
export type GetHeadlinesQueryResult = Apollo.QueryResult<GetHeadlinesQuery, GetHeadlinesQueryVariables>;
export const DeleteCertificateDocument = gql`
    mutation deleteCertificate($id: Int!) {
  deleteCertificate(certificate_id: $id)
}
    `;
export type DeleteCertificateMutationFn = Apollo.MutationFunction<DeleteCertificateMutation, DeleteCertificateMutationVariables>;

/**
 * __useDeleteCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificateMutation, { data, loading, error }] = useDeleteCertificateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCertificateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCertificateMutation, DeleteCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCertificateMutation, DeleteCertificateMutationVariables>(DeleteCertificateDocument, options);
      }
export type DeleteCertificateMutationHookResult = ReturnType<typeof useDeleteCertificateMutation>;
export type DeleteCertificateMutationResult = Apollo.MutationResult<DeleteCertificateMutation>;
export type DeleteCertificateMutationOptions = Apollo.BaseMutationOptions<DeleteCertificateMutation, DeleteCertificateMutationVariables>;
export const CloneCertificateDocument = gql`
    mutation cloneCertificate($certificate_id: Int!, $new_internal_name: String, $new_logo: String, $new_logo_color: String) {
  cloneCertificate(
    certificate_id: $certificate_id
    new_internal_name: $new_internal_name
    new_logo: $new_logo
    new_logo_color: $new_logo_color
  )
}
    `;
export type CloneCertificateMutationFn = Apollo.MutationFunction<CloneCertificateMutation, CloneCertificateMutationVariables>;

/**
 * __useCloneCertificateMutation__
 *
 * To run a mutation, you first call `useCloneCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneCertificateMutation, { data, loading, error }] = useCloneCertificateMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      new_internal_name: // value for 'new_internal_name'
 *      new_logo: // value for 'new_logo'
 *      new_logo_color: // value for 'new_logo_color'
 *   },
 * });
 */
export function useCloneCertificateMutation(baseOptions?: Apollo.MutationHookOptions<CloneCertificateMutation, CloneCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneCertificateMutation, CloneCertificateMutationVariables>(CloneCertificateDocument, options);
      }
export type CloneCertificateMutationHookResult = ReturnType<typeof useCloneCertificateMutation>;
export type CloneCertificateMutationResult = Apollo.MutationResult<CloneCertificateMutation>;
export type CloneCertificateMutationOptions = Apollo.BaseMutationOptions<CloneCertificateMutation, CloneCertificateMutationVariables>;
export const GetUsernameDocument = gql`
    query getUsername {
  getUsername
}
    `;

/**
 * __useGetUsernameQuery__
 *
 * To run a query within a React component, call `useGetUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsernameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsernameQuery(baseOptions?: Apollo.QueryHookOptions<GetUsernameQuery, GetUsernameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsernameQuery, GetUsernameQueryVariables>(GetUsernameDocument, options);
      }
export function useGetUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsernameQuery, GetUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsernameQuery, GetUsernameQueryVariables>(GetUsernameDocument, options);
        }
export type GetUsernameQueryHookResult = ReturnType<typeof useGetUsernameQuery>;
export type GetUsernameLazyQueryHookResult = ReturnType<typeof useGetUsernameLazyQuery>;
export type GetUsernameQueryResult = Apollo.QueryResult<GetUsernameQuery, GetUsernameQueryVariables>;
export const GetClientsDocument = gql`
    query getClients {
  getCPC
}
    `;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const LogOutDocument = gql`
    mutation logOut {
  UserLogout
}
    `;
export type LogOutMutationFn = Apollo.MutationFunction<LogOutMutation, LogOutMutationVariables>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(baseOptions?: Apollo.MutationHookOptions<LogOutMutation, LogOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(LogOutDocument, options);
      }
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<LogOutMutation, LogOutMutationVariables>;
export const ManageCertificateDocument = gql`
    mutation manageCertificate($certificate_id: Int, $cert_name: String!, $cert_internal_name: String!, $cert_content_desc: String!, $cert_is_draft: Boolean!, $cert_optional_value: String, $cert_date_format: String!, $cert_creation_date: DateTime!, $cert_time_to_complete: Time, $cert_expiry_date: DateTime!, $cert_headline_id: Int!, $ep_cert_language_id: Int!, $cert_shuffle_slides: Boolean, $cert_shuffle_statements: Boolean, $cert_fixed_slides: Int, $cert_download_allowed: Boolean, $cert_max_tries: Int!, $cert_max_uses: Int!, $cert_select_users: Boolean, $cert_optional_min: Int, $cert_optional_max: Int, $cert_cpc: Int!, $cert_phone_numbers: String, $cert_individual_slide_max_error: Int!, $cert_tags: [Tag], $cert_custom_logo: String, $cert_sms_text: String, $cert_sms_skip: Boolean, $cert_refresh: Boolean, $cert_refresh_days: Int) {
  manageCertificate(
    certificate_id: $certificate_id
    cert_name: $cert_name
    cert_internal_name: $cert_internal_name
    cert_content_desc: $cert_content_desc
    cert_optional_value: $cert_optional_value
    cert_date_format: $cert_date_format
    cert_creation_date: $cert_creation_date
    cert_is_draft: $cert_is_draft
    cert_time_to_complete: $cert_time_to_complete
    cert_expiry_date: $cert_expiry_date
    cert_headline_id: $cert_headline_id
    ep_cert_language_id: $ep_cert_language_id
    cert_shuffle_slides: $cert_shuffle_slides
    cert_shuffle_statements: $cert_shuffle_statements
    cert_fixed_slides: $cert_fixed_slides
    cert_download_allowed: $cert_download_allowed
    cert_max_tries: $cert_max_tries
    cert_max_uses: $cert_max_uses
    cert_select_users: $cert_select_users
    cert_optional_min: $cert_optional_min
    cert_optional_max: $cert_optional_max
    cert_cpc: $cert_cpc
    cert_phone_numbers: $cert_phone_numbers
    cert_individual_slide_max_error: $cert_individual_slide_max_error
    cert_tags: $cert_tags
    cert_custom_logo: $cert_custom_logo
    cert_sms_text: $cert_sms_text
    cert_sms_skip: $cert_sms_skip
    cert_refresh: $cert_refresh
    cert_refresh_days: $cert_refresh_days
  )
}
    `;
export type ManageCertificateMutationFn = Apollo.MutationFunction<ManageCertificateMutation, ManageCertificateMutationVariables>;

/**
 * __useManageCertificateMutation__
 *
 * To run a mutation, you first call `useManageCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageCertificateMutation, { data, loading, error }] = useManageCertificateMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      cert_name: // value for 'cert_name'
 *      cert_internal_name: // value for 'cert_internal_name'
 *      cert_content_desc: // value for 'cert_content_desc'
 *      cert_is_draft: // value for 'cert_is_draft'
 *      cert_optional_value: // value for 'cert_optional_value'
 *      cert_date_format: // value for 'cert_date_format'
 *      cert_creation_date: // value for 'cert_creation_date'
 *      cert_time_to_complete: // value for 'cert_time_to_complete'
 *      cert_expiry_date: // value for 'cert_expiry_date'
 *      cert_headline_id: // value for 'cert_headline_id'
 *      ep_cert_language_id: // value for 'ep_cert_language_id'
 *      cert_shuffle_slides: // value for 'cert_shuffle_slides'
 *      cert_shuffle_statements: // value for 'cert_shuffle_statements'
 *      cert_fixed_slides: // value for 'cert_fixed_slides'
 *      cert_download_allowed: // value for 'cert_download_allowed'
 *      cert_max_tries: // value for 'cert_max_tries'
 *      cert_max_uses: // value for 'cert_max_uses'
 *      cert_select_users: // value for 'cert_select_users'
 *      cert_optional_min: // value for 'cert_optional_min'
 *      cert_optional_max: // value for 'cert_optional_max'
 *      cert_cpc: // value for 'cert_cpc'
 *      cert_phone_numbers: // value for 'cert_phone_numbers'
 *      cert_individual_slide_max_error: // value for 'cert_individual_slide_max_error'
 *      cert_tags: // value for 'cert_tags'
 *      cert_custom_logo: // value for 'cert_custom_logo'
 *      cert_sms_text: // value for 'cert_sms_text'
 *      cert_sms_skip: // value for 'cert_sms_skip'
 *      cert_refresh: // value for 'cert_refresh'
 *      cert_refresh_days: // value for 'cert_refresh_days'
 *   },
 * });
 */
export function useManageCertificateMutation(baseOptions?: Apollo.MutationHookOptions<ManageCertificateMutation, ManageCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageCertificateMutation, ManageCertificateMutationVariables>(ManageCertificateDocument, options);
      }
export type ManageCertificateMutationHookResult = ReturnType<typeof useManageCertificateMutation>;
export type ManageCertificateMutationResult = Apollo.MutationResult<ManageCertificateMutation>;
export type ManageCertificateMutationOptions = Apollo.BaseMutationOptions<ManageCertificateMutation, ManageCertificateMutationVariables>;
export const GetCertificateDocument = gql`
    query getCertificate($certificate_id: Int!) {
  getCertificate(certificate_id: $certificate_id)
}
    `;

/**
 * __useGetCertificateQuery__
 *
 * To run a query within a React component, call `useGetCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useGetCertificateQuery(baseOptions: Apollo.QueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateQuery, GetCertificateQueryVariables>(GetCertificateDocument, options);
      }
export function useGetCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateQuery, GetCertificateQueryVariables>(GetCertificateDocument, options);
        }
export type GetCertificateQueryHookResult = ReturnType<typeof useGetCertificateQuery>;
export type GetCertificateLazyQueryHookResult = ReturnType<typeof useGetCertificateLazyQuery>;
export type GetCertificateQueryResult = Apollo.QueryResult<GetCertificateQuery, GetCertificateQueryVariables>;
export const GetContentCertificateDocument = gql`
    query getContentCertificate($certificate_id: Int!) {
  getContentCertificate(certificate_id: $certificate_id)
}
    `;

/**
 * __useGetContentCertificateQuery__
 *
 * To run a query within a React component, call `useGetContentCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentCertificateQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useGetContentCertificateQuery(baseOptions: Apollo.QueryHookOptions<GetContentCertificateQuery, GetContentCertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentCertificateQuery, GetContentCertificateQueryVariables>(GetContentCertificateDocument, options);
      }
export function useGetContentCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentCertificateQuery, GetContentCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentCertificateQuery, GetContentCertificateQueryVariables>(GetContentCertificateDocument, options);
        }
export type GetContentCertificateQueryHookResult = ReturnType<typeof useGetContentCertificateQuery>;
export type GetContentCertificateLazyQueryHookResult = ReturnType<typeof useGetContentCertificateLazyQuery>;
export type GetContentCertificateQueryResult = Apollo.QueryResult<GetContentCertificateQuery, GetContentCertificateQueryVariables>;
export const GetAvailableTagsDocument = gql`
    query getAvailableTags($certificate_id: Int!) {
  getAvailableTags(certificate_id: $certificate_id)
}
    `;

/**
 * __useGetAvailableTagsQuery__
 *
 * To run a query within a React component, call `useGetAvailableTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableTagsQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useGetAvailableTagsQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>(GetAvailableTagsDocument, options);
      }
export function useGetAvailableTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>(GetAvailableTagsDocument, options);
        }
export type GetAvailableTagsQueryHookResult = ReturnType<typeof useGetAvailableTagsQuery>;
export type GetAvailableTagsLazyQueryHookResult = ReturnType<typeof useGetAvailableTagsLazyQuery>;
export type GetAvailableTagsQueryResult = Apollo.QueryResult<GetAvailableTagsQuery, GetAvailableTagsQueryVariables>;
export const GetDefaultNarrationsDocument = gql`
    query getDefaultNarrations {
  getDefaultNarrations
}
    `;

/**
 * __useGetDefaultNarrationsQuery__
 *
 * To run a query within a React component, call `useGetDefaultNarrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultNarrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultNarrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultNarrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultNarrationsQuery, GetDefaultNarrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultNarrationsQuery, GetDefaultNarrationsQueryVariables>(GetDefaultNarrationsDocument, options);
      }
export function useGetDefaultNarrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultNarrationsQuery, GetDefaultNarrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultNarrationsQuery, GetDefaultNarrationsQueryVariables>(GetDefaultNarrationsDocument, options);
        }
export type GetDefaultNarrationsQueryHookResult = ReturnType<typeof useGetDefaultNarrationsQuery>;
export type GetDefaultNarrationsLazyQueryHookResult = ReturnType<typeof useGetDefaultNarrationsLazyQuery>;
export type GetDefaultNarrationsQueryResult = Apollo.QueryResult<GetDefaultNarrationsQuery, GetDefaultNarrationsQueryVariables>;
export const ManageContentDocument = gql`
    mutation manageContent($new_content: Content!) {
  manageContent(new_content: $new_content)
}
    `;
export type ManageContentMutationFn = Apollo.MutationFunction<ManageContentMutation, ManageContentMutationVariables>;

/**
 * __useManageContentMutation__
 *
 * To run a mutation, you first call `useManageContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageContentMutation, { data, loading, error }] = useManageContentMutation({
 *   variables: {
 *      new_content: // value for 'new_content'
 *   },
 * });
 */
export function useManageContentMutation(baseOptions?: Apollo.MutationHookOptions<ManageContentMutation, ManageContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageContentMutation, ManageContentMutationVariables>(ManageContentDocument, options);
      }
export type ManageContentMutationHookResult = ReturnType<typeof useManageContentMutation>;
export type ManageContentMutationResult = Apollo.MutationResult<ManageContentMutation>;
export type ManageContentMutationOptions = Apollo.BaseMutationOptions<ManageContentMutation, ManageContentMutationVariables>;
export const AddLogoCertificateDocument = gql`
    mutation addLogoCertificate($certificate_id: Int!, $new_logo: String!, $new_logo_color: String!) {
  addLogoCertificate(
    certificate_id: $certificate_id
    new_logo: $new_logo
    new_logo_color: $new_logo_color
  )
}
    `;
export type AddLogoCertificateMutationFn = Apollo.MutationFunction<AddLogoCertificateMutation, AddLogoCertificateMutationVariables>;

/**
 * __useAddLogoCertificateMutation__
 *
 * To run a mutation, you first call `useAddLogoCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLogoCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLogoCertificateMutation, { data, loading, error }] = useAddLogoCertificateMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      new_logo: // value for 'new_logo'
 *      new_logo_color: // value for 'new_logo_color'
 *   },
 * });
 */
export function useAddLogoCertificateMutation(baseOptions?: Apollo.MutationHookOptions<AddLogoCertificateMutation, AddLogoCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLogoCertificateMutation, AddLogoCertificateMutationVariables>(AddLogoCertificateDocument, options);
      }
export type AddLogoCertificateMutationHookResult = ReturnType<typeof useAddLogoCertificateMutation>;
export type AddLogoCertificateMutationResult = Apollo.MutationResult<AddLogoCertificateMutation>;
export type AddLogoCertificateMutationOptions = Apollo.BaseMutationOptions<AddLogoCertificateMutation, AddLogoCertificateMutationVariables>;
export const CertDraftStatusDocument = gql`
    mutation certDraftStatus($certificate_id: Int!, $cert_is_draft: Boolean!) {
  certDraftStatus(certificate_id: $certificate_id, cert_is_draft: $cert_is_draft)
}
    `;
export type CertDraftStatusMutationFn = Apollo.MutationFunction<CertDraftStatusMutation, CertDraftStatusMutationVariables>;

/**
 * __useCertDraftStatusMutation__
 *
 * To run a mutation, you first call `useCertDraftStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCertDraftStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [certDraftStatusMutation, { data, loading, error }] = useCertDraftStatusMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      cert_is_draft: // value for 'cert_is_draft'
 *   },
 * });
 */
export function useCertDraftStatusMutation(baseOptions?: Apollo.MutationHookOptions<CertDraftStatusMutation, CertDraftStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CertDraftStatusMutation, CertDraftStatusMutationVariables>(CertDraftStatusDocument, options);
      }
export type CertDraftStatusMutationHookResult = ReturnType<typeof useCertDraftStatusMutation>;
export type CertDraftStatusMutationResult = Apollo.MutationResult<CertDraftStatusMutation>;
export type CertDraftStatusMutationOptions = Apollo.BaseMutationOptions<CertDraftStatusMutation, CertDraftStatusMutationVariables>;
export const GetSasDocument = gql`
    query getSAS {
  getSAS
}
    `;

/**
 * __useGetSasQuery__
 *
 * To run a query within a React component, call `useGetSasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSasQuery(baseOptions?: Apollo.QueryHookOptions<GetSasQuery, GetSasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSasQuery, GetSasQueryVariables>(GetSasDocument, options);
      }
export function useGetSasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSasQuery, GetSasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSasQuery, GetSasQueryVariables>(GetSasDocument, options);
        }
export type GetSasQueryHookResult = ReturnType<typeof useGetSasQuery>;
export type GetSasLazyQueryHookResult = ReturnType<typeof useGetSasLazyQuery>;
export type GetSasQueryResult = Apollo.QueryResult<GetSasQuery, GetSasQueryVariables>;
export const CertExpiryDateDocument = gql`
    mutation certExpiryDate($certificate_id: Int!, $cert_expiry_date: DateTime!) {
  certExpiryDate(
    certificate_id: $certificate_id
    cert_expiry_date: $cert_expiry_date
  )
}
    `;
export type CertExpiryDateMutationFn = Apollo.MutationFunction<CertExpiryDateMutation, CertExpiryDateMutationVariables>;

/**
 * __useCertExpiryDateMutation__
 *
 * To run a mutation, you first call `useCertExpiryDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCertExpiryDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [certExpiryDateMutation, { data, loading, error }] = useCertExpiryDateMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      cert_expiry_date: // value for 'cert_expiry_date'
 *   },
 * });
 */
export function useCertExpiryDateMutation(baseOptions?: Apollo.MutationHookOptions<CertExpiryDateMutation, CertExpiryDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CertExpiryDateMutation, CertExpiryDateMutationVariables>(CertExpiryDateDocument, options);
      }
export type CertExpiryDateMutationHookResult = ReturnType<typeof useCertExpiryDateMutation>;
export type CertExpiryDateMutationResult = Apollo.MutationResult<CertExpiryDateMutation>;
export type CertExpiryDateMutationOptions = Apollo.BaseMutationOptions<CertExpiryDateMutation, CertExpiryDateMutationVariables>;
export const CertPhoneNumbersDocument = gql`
    mutation certPhoneNumbers($certificate_id: Int!, $cert_phone_numbers: String!) {
  certPhoneNumbers(
    certificate_id: $certificate_id
    cert_phone_numbers: $cert_phone_numbers
  )
}
    `;
export type CertPhoneNumbersMutationFn = Apollo.MutationFunction<CertPhoneNumbersMutation, CertPhoneNumbersMutationVariables>;

/**
 * __useCertPhoneNumbersMutation__
 *
 * To run a mutation, you first call `useCertPhoneNumbersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCertPhoneNumbersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [certPhoneNumbersMutation, { data, loading, error }] = useCertPhoneNumbersMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      cert_phone_numbers: // value for 'cert_phone_numbers'
 *   },
 * });
 */
export function useCertPhoneNumbersMutation(baseOptions?: Apollo.MutationHookOptions<CertPhoneNumbersMutation, CertPhoneNumbersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CertPhoneNumbersMutation, CertPhoneNumbersMutationVariables>(CertPhoneNumbersDocument, options);
      }
export type CertPhoneNumbersMutationHookResult = ReturnType<typeof useCertPhoneNumbersMutation>;
export type CertPhoneNumbersMutationResult = Apollo.MutationResult<CertPhoneNumbersMutation>;
export type CertPhoneNumbersMutationOptions = Apollo.BaseMutationOptions<CertPhoneNumbersMutation, CertPhoneNumbersMutationVariables>;
export const GetUserPointsDocument = gql`
    query GetUserPoints {
  GetUserPoints
}
    `;

/**
 * __useGetUserPointsQuery__
 *
 * To run a query within a React component, call `useGetUserPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPointsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPointsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPointsQuery, GetUserPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPointsQuery, GetUserPointsQueryVariables>(GetUserPointsDocument, options);
      }
export function useGetUserPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPointsQuery, GetUserPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPointsQuery, GetUserPointsQueryVariables>(GetUserPointsDocument, options);
        }
export type GetUserPointsQueryHookResult = ReturnType<typeof useGetUserPointsQuery>;
export type GetUserPointsLazyQueryHookResult = ReturnType<typeof useGetUserPointsLazyQuery>;
export type GetUserPointsQueryResult = Apollo.QueryResult<GetUserPointsQuery, GetUserPointsQueryVariables>;
export const GetUserPointsLeftDocument = gql`
    query GetUserPointsLeft {
  GetUserPointsLeft
}
    `;

/**
 * __useGetUserPointsLeftQuery__
 *
 * To run a query within a React component, call `useGetUserPointsLeftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPointsLeftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPointsLeftQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPointsLeftQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPointsLeftQuery, GetUserPointsLeftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPointsLeftQuery, GetUserPointsLeftQueryVariables>(GetUserPointsLeftDocument, options);
      }
export function useGetUserPointsLeftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPointsLeftQuery, GetUserPointsLeftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPointsLeftQuery, GetUserPointsLeftQueryVariables>(GetUserPointsLeftDocument, options);
        }
export type GetUserPointsLeftQueryHookResult = ReturnType<typeof useGetUserPointsLeftQuery>;
export type GetUserPointsLeftLazyQueryHookResult = ReturnType<typeof useGetUserPointsLeftLazyQuery>;
export type GetUserPointsLeftQueryResult = Apollo.QueryResult<GetUserPointsLeftQuery, GetUserPointsLeftQueryVariables>;
export const CreateCpcDocument = gql`
    mutation CreateCPC($new_cpc: CPC!) {
  CreateCPC(new_cpc: $new_cpc)
}
    `;
export type CreateCpcMutationFn = Apollo.MutationFunction<CreateCpcMutation, CreateCpcMutationVariables>;

/**
 * __useCreateCpcMutation__
 *
 * To run a mutation, you first call `useCreateCpcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCpcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCpcMutation, { data, loading, error }] = useCreateCpcMutation({
 *   variables: {
 *      new_cpc: // value for 'new_cpc'
 *   },
 * });
 */
export function useCreateCpcMutation(baseOptions?: Apollo.MutationHookOptions<CreateCpcMutation, CreateCpcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCpcMutation, CreateCpcMutationVariables>(CreateCpcDocument, options);
      }
export type CreateCpcMutationHookResult = ReturnType<typeof useCreateCpcMutation>;
export type CreateCpcMutationResult = Apollo.MutationResult<CreateCpcMutation>;
export type CreateCpcMutationOptions = Apollo.BaseMutationOptions<CreateCpcMutation, CreateCpcMutationVariables>;
export const CertPublishDocument = gql`
    mutation certPublish($certificate_id: Int!) {
  certPublish(certificate_id: $certificate_id)
}
    `;
export type CertPublishMutationFn = Apollo.MutationFunction<CertPublishMutation, CertPublishMutationVariables>;

/**
 * __useCertPublishMutation__
 *
 * To run a mutation, you first call `useCertPublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCertPublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [certPublishMutation, { data, loading, error }] = useCertPublishMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useCertPublishMutation(baseOptions?: Apollo.MutationHookOptions<CertPublishMutation, CertPublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CertPublishMutation, CertPublishMutationVariables>(CertPublishDocument, options);
      }
export type CertPublishMutationHookResult = ReturnType<typeof useCertPublishMutation>;
export type CertPublishMutationResult = Apollo.MutationResult<CertPublishMutation>;
export type CertPublishMutationOptions = Apollo.BaseMutationOptions<CertPublishMutation, CertPublishMutationVariables>;
export const DeleteCpcDocument = gql`
    mutation deleteCPC($cpc_list: [Int!]) {
  deleteCPC(cpc_list: $cpc_list)
}
    `;
export type DeleteCpcMutationFn = Apollo.MutationFunction<DeleteCpcMutation, DeleteCpcMutationVariables>;

/**
 * __useDeleteCpcMutation__
 *
 * To run a mutation, you first call `useDeleteCpcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCpcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCpcMutation, { data, loading, error }] = useDeleteCpcMutation({
 *   variables: {
 *      cpc_list: // value for 'cpc_list'
 *   },
 * });
 */
export function useDeleteCpcMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCpcMutation, DeleteCpcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCpcMutation, DeleteCpcMutationVariables>(DeleteCpcDocument, options);
      }
export type DeleteCpcMutationHookResult = ReturnType<typeof useDeleteCpcMutation>;
export type DeleteCpcMutationResult = Apollo.MutationResult<DeleteCpcMutation>;
export type DeleteCpcMutationOptions = Apollo.BaseMutationOptions<DeleteCpcMutation, DeleteCpcMutationVariables>;
export const UpdateCpcDocument = gql`
    mutation UpdateCPC($cpc_update: CPC_update!) {
  UpdateCPC(cpc_update: $cpc_update)
}
    `;
export type UpdateCpcMutationFn = Apollo.MutationFunction<UpdateCpcMutation, UpdateCpcMutationVariables>;

/**
 * __useUpdateCpcMutation__
 *
 * To run a mutation, you first call `useUpdateCpcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCpcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCpcMutation, { data, loading, error }] = useUpdateCpcMutation({
 *   variables: {
 *      cpc_update: // value for 'cpc_update'
 *   },
 * });
 */
export function useUpdateCpcMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCpcMutation, UpdateCpcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCpcMutation, UpdateCpcMutationVariables>(UpdateCpcDocument, options);
      }
export type UpdateCpcMutationHookResult = ReturnType<typeof useUpdateCpcMutation>;
export type UpdateCpcMutationResult = Apollo.MutationResult<UpdateCpcMutation>;
export type UpdateCpcMutationOptions = Apollo.BaseMutationOptions<UpdateCpcMutation, UpdateCpcMutationVariables>;
export const GetEndusersDocument = gql`
    query getEndusers($certificate_id: Int!, $tag_id: Int) {
  getEndusers(certificate_id: $certificate_id, tag_id: $tag_id)
}
    `;

/**
 * __useGetEndusersQuery__
 *
 * To run a query within a React component, call `useGetEndusersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndusersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndusersQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      tag_id: // value for 'tag_id'
 *   },
 * });
 */
export function useGetEndusersQuery(baseOptions: Apollo.QueryHookOptions<GetEndusersQuery, GetEndusersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndusersQuery, GetEndusersQueryVariables>(GetEndusersDocument, options);
      }
export function useGetEndusersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndusersQuery, GetEndusersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndusersQuery, GetEndusersQueryVariables>(GetEndusersDocument, options);
        }
export type GetEndusersQueryHookResult = ReturnType<typeof useGetEndusersQuery>;
export type GetEndusersLazyQueryHookResult = ReturnType<typeof useGetEndusersLazyQuery>;
export type GetEndusersQueryResult = Apollo.QueryResult<GetEndusersQuery, GetEndusersQueryVariables>;
export const GetCpcDropdownDocument = gql`
    query getCPCDropdown($cpc_id: ID!) {
  getCPCDropdown(cpc_id: $cpc_id)
}
    `;

/**
 * __useGetCpcDropdownQuery__
 *
 * To run a query within a React component, call `useGetCpcDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCpcDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCpcDropdownQuery({
 *   variables: {
 *      cpc_id: // value for 'cpc_id'
 *   },
 * });
 */
export function useGetCpcDropdownQuery(baseOptions: Apollo.QueryHookOptions<GetCpcDropdownQuery, GetCpcDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCpcDropdownQuery, GetCpcDropdownQueryVariables>(GetCpcDropdownDocument, options);
      }
export function useGetCpcDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCpcDropdownQuery, GetCpcDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCpcDropdownQuery, GetCpcDropdownQueryVariables>(GetCpcDropdownDocument, options);
        }
export type GetCpcDropdownQueryHookResult = ReturnType<typeof useGetCpcDropdownQuery>;
export type GetCpcDropdownLazyQueryHookResult = ReturnType<typeof useGetCpcDropdownLazyQuery>;
export type GetCpcDropdownQueryResult = Apollo.QueryResult<GetCpcDropdownQuery, GetCpcDropdownQueryVariables>;
export const ManageInvoiceDocument = gql`
    mutation manageInvoice($user_invoices: [invoiced!]) {
  manageInvoice(user_invoices: $user_invoices)
}
    `;
export type ManageInvoiceMutationFn = Apollo.MutationFunction<ManageInvoiceMutation, ManageInvoiceMutationVariables>;

/**
 * __useManageInvoiceMutation__
 *
 * To run a mutation, you first call `useManageInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageInvoiceMutation, { data, loading, error }] = useManageInvoiceMutation({
 *   variables: {
 *      user_invoices: // value for 'user_invoices'
 *   },
 * });
 */
export function useManageInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ManageInvoiceMutation, ManageInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageInvoiceMutation, ManageInvoiceMutationVariables>(ManageInvoiceDocument, options);
      }
export type ManageInvoiceMutationHookResult = ReturnType<typeof useManageInvoiceMutation>;
export type ManageInvoiceMutationResult = Apollo.MutationResult<ManageInvoiceMutation>;
export type ManageInvoiceMutationOptions = Apollo.BaseMutationOptions<ManageInvoiceMutation, ManageInvoiceMutationVariables>;
export const DeleteEnduserBatchDocument = gql`
    mutation deleteEnduserBatch($enduser_list: [endUser!]) {
  deleteEnduserBatch(enduser_list: $enduser_list)
}
    `;
export type DeleteEnduserBatchMutationFn = Apollo.MutationFunction<DeleteEnduserBatchMutation, DeleteEnduserBatchMutationVariables>;

/**
 * __useDeleteEnduserBatchMutation__
 *
 * To run a mutation, you first call `useDeleteEnduserBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnduserBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnduserBatchMutation, { data, loading, error }] = useDeleteEnduserBatchMutation({
 *   variables: {
 *      enduser_list: // value for 'enduser_list'
 *   },
 * });
 */
export function useDeleteEnduserBatchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEnduserBatchMutation, DeleteEnduserBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEnduserBatchMutation, DeleteEnduserBatchMutationVariables>(DeleteEnduserBatchDocument, options);
      }
export type DeleteEnduserBatchMutationHookResult = ReturnType<typeof useDeleteEnduserBatchMutation>;
export type DeleteEnduserBatchMutationResult = Apollo.MutationResult<DeleteEnduserBatchMutation>;
export type DeleteEnduserBatchMutationOptions = Apollo.BaseMutationOptions<DeleteEnduserBatchMutation, DeleteEnduserBatchMutationVariables>;
export const GetEndusersCpcDocument = gql`
    query getEndusersCPC($CPC_User_id: Int!) {
  getEndusersCPC(CPC_User_id: $CPC_User_id)
}
    `;

/**
 * __useGetEndusersCpcQuery__
 *
 * To run a query within a React component, call `useGetEndusersCpcQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndusersCpcQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndusersCpcQuery({
 *   variables: {
 *      CPC_User_id: // value for 'CPC_User_id'
 *   },
 * });
 */
export function useGetEndusersCpcQuery(baseOptions: Apollo.QueryHookOptions<GetEndusersCpcQuery, GetEndusersCpcQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndusersCpcQuery, GetEndusersCpcQueryVariables>(GetEndusersCpcDocument, options);
      }
export function useGetEndusersCpcLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndusersCpcQuery, GetEndusersCpcQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndusersCpcQuery, GetEndusersCpcQueryVariables>(GetEndusersCpcDocument, options);
        }
export type GetEndusersCpcQueryHookResult = ReturnType<typeof useGetEndusersCpcQuery>;
export type GetEndusersCpcLazyQueryHookResult = ReturnType<typeof useGetEndusersCpcLazyQuery>;
export type GetEndusersCpcQueryResult = Apollo.QueryResult<GetEndusersCpcQuery, GetEndusersCpcQueryVariables>;
export const GetCertificatesDocument = gql`
    query getCertificates($CPC_User_id: Int!) {
  getCertificates(CPC_User_id: $CPC_User_id)
}
    `;

/**
 * __useGetCertificatesQuery__
 *
 * To run a query within a React component, call `useGetCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificatesQuery({
 *   variables: {
 *      CPC_User_id: // value for 'CPC_User_id'
 *   },
 * });
 */
export function useGetCertificatesQuery(baseOptions: Apollo.QueryHookOptions<GetCertificatesQuery, GetCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificatesQuery, GetCertificatesQueryVariables>(GetCertificatesDocument, options);
      }
export function useGetCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificatesQuery, GetCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificatesQuery, GetCertificatesQueryVariables>(GetCertificatesDocument, options);
        }
export type GetCertificatesQueryHookResult = ReturnType<typeof useGetCertificatesQuery>;
export type GetCertificatesLazyQueryHookResult = ReturnType<typeof useGetCertificatesLazyQuery>;
export type GetCertificatesQueryResult = Apollo.QueryResult<GetCertificatesQuery, GetCertificatesQueryVariables>;
export const GetUserLogoDocument = gql`
    query getUserLogo($user_id: Int) {
  getUserLogo(user_id: $user_id)
}
    `;

/**
 * __useGetUserLogoQuery__
 *
 * To run a query within a React component, call `useGetUserLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLogoQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserLogoQuery(baseOptions?: Apollo.QueryHookOptions<GetUserLogoQuery, GetUserLogoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLogoQuery, GetUserLogoQueryVariables>(GetUserLogoDocument, options);
      }
export function useGetUserLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLogoQuery, GetUserLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLogoQuery, GetUserLogoQueryVariables>(GetUserLogoDocument, options);
        }
export type GetUserLogoQueryHookResult = ReturnType<typeof useGetUserLogoQuery>;
export type GetUserLogoLazyQueryHookResult = ReturnType<typeof useGetUserLogoLazyQuery>;
export type GetUserLogoQueryResult = Apollo.QueryResult<GetUserLogoQuery, GetUserLogoQueryVariables>;
export const GetUserLogoCertDocument = gql`
    query getUserLogoCert($certificate_id: Int!) {
  getUserLogoCert(certificate_id: $certificate_id)
}
    `;

/**
 * __useGetUserLogoCertQuery__
 *
 * To run a query within a React component, call `useGetUserLogoCertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLogoCertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLogoCertQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useGetUserLogoCertQuery(baseOptions: Apollo.QueryHookOptions<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>(GetUserLogoCertDocument, options);
      }
export function useGetUserLogoCertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>(GetUserLogoCertDocument, options);
        }
export type GetUserLogoCertQueryHookResult = ReturnType<typeof useGetUserLogoCertQuery>;
export type GetUserLogoCertLazyQueryHookResult = ReturnType<typeof useGetUserLogoCertLazyQuery>;
export type GetUserLogoCertQueryResult = Apollo.QueryResult<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>;
export const GetCertificateClientsDocument = gql`
    query getCertificateClients($certificate_id: Int!) {
  getCertificateClients(certificate_id: $certificate_id)
}
    `;

/**
 * __useGetCertificateClientsQuery__
 *
 * To run a query within a React component, call `useGetCertificateClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateClientsQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useGetCertificateClientsQuery(baseOptions: Apollo.QueryHookOptions<GetCertificateClientsQuery, GetCertificateClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateClientsQuery, GetCertificateClientsQueryVariables>(GetCertificateClientsDocument, options);
      }
export function useGetCertificateClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateClientsQuery, GetCertificateClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateClientsQuery, GetCertificateClientsQueryVariables>(GetCertificateClientsDocument, options);
        }
export type GetCertificateClientsQueryHookResult = ReturnType<typeof useGetCertificateClientsQuery>;
export type GetCertificateClientsLazyQueryHookResult = ReturnType<typeof useGetCertificateClientsLazyQuery>;
export type GetCertificateClientsQueryResult = Apollo.QueryResult<GetCertificateClientsQuery, GetCertificateClientsQueryVariables>;
export const AddCpcDocument = gql`
    mutation AddCPC($cpc_list: [certificate_cpc]!) {
  AddCPC(cpc_list: $cpc_list)
}
    `;
export type AddCpcMutationFn = Apollo.MutationFunction<AddCpcMutation, AddCpcMutationVariables>;

/**
 * __useAddCpcMutation__
 *
 * To run a mutation, you first call `useAddCpcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCpcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCpcMutation, { data, loading, error }] = useAddCpcMutation({
 *   variables: {
 *      cpc_list: // value for 'cpc_list'
 *   },
 * });
 */
export function useAddCpcMutation(baseOptions?: Apollo.MutationHookOptions<AddCpcMutation, AddCpcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCpcMutation, AddCpcMutationVariables>(AddCpcDocument, options);
      }
export type AddCpcMutationHookResult = ReturnType<typeof useAddCpcMutation>;
export type AddCpcMutationResult = Apollo.MutationResult<AddCpcMutation>;
export type AddCpcMutationOptions = Apollo.BaseMutationOptions<AddCpcMutation, AddCpcMutationVariables>;
export const DeleteCertificateClientDocument = gql`
    mutation deleteCertificateClient($certificate_id: Int!, $cpc_id: Int!) {
  deleteCertificateClient(certificate_id: $certificate_id, cpc_id: $cpc_id)
}
    `;
export type DeleteCertificateClientMutationFn = Apollo.MutationFunction<DeleteCertificateClientMutation, DeleteCertificateClientMutationVariables>;

/**
 * __useDeleteCertificateClientMutation__
 *
 * To run a mutation, you first call `useDeleteCertificateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificateClientMutation, { data, loading, error }] = useDeleteCertificateClientMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      cpc_id: // value for 'cpc_id'
 *   },
 * });
 */
export function useDeleteCertificateClientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCertificateClientMutation, DeleteCertificateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCertificateClientMutation, DeleteCertificateClientMutationVariables>(DeleteCertificateClientDocument, options);
      }
export type DeleteCertificateClientMutationHookResult = ReturnType<typeof useDeleteCertificateClientMutation>;
export type DeleteCertificateClientMutationResult = Apollo.MutationResult<DeleteCertificateClientMutation>;
export type DeleteCertificateClientMutationOptions = Apollo.BaseMutationOptions<DeleteCertificateClientMutation, DeleteCertificateClientMutationVariables>;
export const GetAllTagsDocument = gql`
    query getAllTags($cert_id: ID, $cpc_id: ID, $cp_id: ID) {
  getAllTags(cert_id: $cert_id, cpc_id: $cpc_id, cp_id: $cp_id)
}
    `;

/**
 * __useGetAllTagsQuery__
 *
 * To run a query within a React component, call `useGetAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTagsQuery({
 *   variables: {
 *      cert_id: // value for 'cert_id'
 *      cpc_id: // value for 'cpc_id'
 *      cp_id: // value for 'cp_id'
 *   },
 * });
 */
export function useGetAllTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTagsQuery, GetAllTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(GetAllTagsDocument, options);
      }
export function useGetAllTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTagsQuery, GetAllTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTagsQuery, GetAllTagsQueryVariables>(GetAllTagsDocument, options);
        }
export type GetAllTagsQueryHookResult = ReturnType<typeof useGetAllTagsQuery>;
export type GetAllTagsLazyQueryHookResult = ReturnType<typeof useGetAllTagsLazyQuery>;
export type GetAllTagsQueryResult = Apollo.QueryResult<GetAllTagsQuery, GetAllTagsQueryVariables>;
export const GetCpcTagInfoDocument = gql`
    query getCPCTagInfo($cpc_id: ID!) {
  getCPCTagInfo(cpc_id: $cpc_id)
}
    `;

/**
 * __useGetCpcTagInfoQuery__
 *
 * To run a query within a React component, call `useGetCpcTagInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCpcTagInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCpcTagInfoQuery({
 *   variables: {
 *      cpc_id: // value for 'cpc_id'
 *   },
 * });
 */
export function useGetCpcTagInfoQuery(baseOptions: Apollo.QueryHookOptions<GetCpcTagInfoQuery, GetCpcTagInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCpcTagInfoQuery, GetCpcTagInfoQueryVariables>(GetCpcTagInfoDocument, options);
      }
export function useGetCpcTagInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCpcTagInfoQuery, GetCpcTagInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCpcTagInfoQuery, GetCpcTagInfoQueryVariables>(GetCpcTagInfoDocument, options);
        }
export type GetCpcTagInfoQueryHookResult = ReturnType<typeof useGetCpcTagInfoQuery>;
export type GetCpcTagInfoLazyQueryHookResult = ReturnType<typeof useGetCpcTagInfoLazyQuery>;
export type GetCpcTagInfoQueryResult = Apollo.QueryResult<GetCpcTagInfoQuery, GetCpcTagInfoQueryVariables>;
export const GetCertTagInfoDocument = gql`
    query getCertTagInfo($cert_id: ID!) {
  getCertTagInfo(cert_id: $cert_id)
}
    `;

/**
 * __useGetCertTagInfoQuery__
 *
 * To run a query within a React component, call `useGetCertTagInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertTagInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertTagInfoQuery({
 *   variables: {
 *      cert_id: // value for 'cert_id'
 *   },
 * });
 */
export function useGetCertTagInfoQuery(baseOptions: Apollo.QueryHookOptions<GetCertTagInfoQuery, GetCertTagInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertTagInfoQuery, GetCertTagInfoQueryVariables>(GetCertTagInfoDocument, options);
      }
export function useGetCertTagInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertTagInfoQuery, GetCertTagInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertTagInfoQuery, GetCertTagInfoQueryVariables>(GetCertTagInfoDocument, options);
        }
export type GetCertTagInfoQueryHookResult = ReturnType<typeof useGetCertTagInfoQuery>;
export type GetCertTagInfoLazyQueryHookResult = ReturnType<typeof useGetCertTagInfoLazyQuery>;
export type GetCertTagInfoQueryResult = Apollo.QueryResult<GetCertTagInfoQuery, GetCertTagInfoQueryVariables>;
export const GetTagEndusersDocument = gql`
    query getTagEndusers($tag_id: ID!) {
  getTagEndusers(tag_id: $tag_id)
}
    `;

/**
 * __useGetTagEndusersQuery__
 *
 * To run a query within a React component, call `useGetTagEndusersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagEndusersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagEndusersQuery({
 *   variables: {
 *      tag_id: // value for 'tag_id'
 *   },
 * });
 */
export function useGetTagEndusersQuery(baseOptions: Apollo.QueryHookOptions<GetTagEndusersQuery, GetTagEndusersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagEndusersQuery, GetTagEndusersQueryVariables>(GetTagEndusersDocument, options);
      }
export function useGetTagEndusersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagEndusersQuery, GetTagEndusersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagEndusersQuery, GetTagEndusersQueryVariables>(GetTagEndusersDocument, options);
        }
export type GetTagEndusersQueryHookResult = ReturnType<typeof useGetTagEndusersQuery>;
export type GetTagEndusersLazyQueryHookResult = ReturnType<typeof useGetTagEndusersLazyQuery>;
export type GetTagEndusersQueryResult = Apollo.QueryResult<GetTagEndusersQuery, GetTagEndusersQueryVariables>;
export const CheckPasswordDocument = gql`
    query checkPassword($id: Int!, $password: String!) {
  checkPassword(id: $id, password: $password)
}
    `;

/**
 * __useCheckPasswordQuery__
 *
 * To run a query within a React component, call `useCheckPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPasswordQuery({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCheckPasswordQuery(baseOptions: Apollo.QueryHookOptions<CheckPasswordQuery, CheckPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPasswordQuery, CheckPasswordQueryVariables>(CheckPasswordDocument, options);
      }
export function useCheckPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPasswordQuery, CheckPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPasswordQuery, CheckPasswordQueryVariables>(CheckPasswordDocument, options);
        }
export type CheckPasswordQueryHookResult = ReturnType<typeof useCheckPasswordQuery>;
export type CheckPasswordLazyQueryHookResult = ReturnType<typeof useCheckPasswordLazyQuery>;
export type CheckPasswordQueryResult = Apollo.QueryResult<CheckPasswordQuery, CheckPasswordQueryVariables>;
export const UpdateLaterDateDocument = gql`
    mutation updateLaterDate($certificate_id: Int!, $date: DateTime!, $enduser_ids: String!) {
  updateLaterDate(
    certificate_id: $certificate_id
    date: $date
    enduser_ids: $enduser_ids
  )
}
    `;
export type UpdateLaterDateMutationFn = Apollo.MutationFunction<UpdateLaterDateMutation, UpdateLaterDateMutationVariables>;

/**
 * __useUpdateLaterDateMutation__
 *
 * To run a mutation, you first call `useUpdateLaterDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLaterDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLaterDateMutation, { data, loading, error }] = useUpdateLaterDateMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      date: // value for 'date'
 *      enduser_ids: // value for 'enduser_ids'
 *   },
 * });
 */
export function useUpdateLaterDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLaterDateMutation, UpdateLaterDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLaterDateMutation, UpdateLaterDateMutationVariables>(UpdateLaterDateDocument, options);
      }
export type UpdateLaterDateMutationHookResult = ReturnType<typeof useUpdateLaterDateMutation>;
export type UpdateLaterDateMutationResult = Apollo.MutationResult<UpdateLaterDateMutation>;
export type UpdateLaterDateMutationOptions = Apollo.BaseMutationOptions<UpdateLaterDateMutation, UpdateLaterDateMutationVariables>;
export const SendDiplomaSmsDocument = gql`
    query sendDiplomaSMS($users: [userInput], $certInfo: certInfo!) {
  sendDiplomaSMSNow(users: $users, certInfo: $certInfo)
}
    `;

/**
 * __useSendDiplomaSmsQuery__
 *
 * To run a query within a React component, call `useSendDiplomaSmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendDiplomaSmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendDiplomaSmsQuery({
 *   variables: {
 *      users: // value for 'users'
 *      certInfo: // value for 'certInfo'
 *   },
 * });
 */
export function useSendDiplomaSmsQuery(baseOptions: Apollo.QueryHookOptions<SendDiplomaSmsQuery, SendDiplomaSmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendDiplomaSmsQuery, SendDiplomaSmsQueryVariables>(SendDiplomaSmsDocument, options);
      }
export function useSendDiplomaSmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendDiplomaSmsQuery, SendDiplomaSmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendDiplomaSmsQuery, SendDiplomaSmsQueryVariables>(SendDiplomaSmsDocument, options);
        }
export type SendDiplomaSmsQueryHookResult = ReturnType<typeof useSendDiplomaSmsQuery>;
export type SendDiplomaSmsLazyQueryHookResult = ReturnType<typeof useSendDiplomaSmsLazyQuery>;
export type SendDiplomaSmsQueryResult = Apollo.QueryResult<SendDiplomaSmsQuery, SendDiplomaSmsQueryVariables>;