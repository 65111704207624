import React, { FC } from "react";
import flipPhoneIcon from "../../../../assets/flipphone-icon.gif";

export interface UserScreenFlipPhoneIconProps {
	className?: string;
}

export const UserScreenFlipPhoneIcon: FC<UserScreenFlipPhoneIconProps> = (props: UserScreenFlipPhoneIconProps) => {
	return <img src={flipPhoneIcon} alt="Icon" className={props.className} />;
};

export default UserScreenFlipPhoneIcon;
