/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { certTag, EnduserStats } from "../models";
import { MaterialReactTable, type MRT_ColumnDef, type MRT_Row } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@material-ui/core";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "./StatisticsPageNoLoginTable.scss";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation } from "react-router-dom";
import { certificateData } from "../statisticsPage/StatisticsPage";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Diploma, { DiplomaProps } from "../global/components/Diploma";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { Close } from "@material-ui/icons";
import { convertImgToBase64URL } from "../../services/utilFunctions";
import { findIndexById } from "../modelFunctions";
import { FIND_INDEX_STATUS_NOT_FOUND } from "../../constants/generalConstants";

export type IStatisticsPageNoLoginTableProps = {
	data?: EnduserStats[];
	tagChosen?: certTag;
	certificateData?: certificateData;
};

function formatDate(date) {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
}

const StatisticsPageNoLoginTable: React.FC<IStatisticsPageNoLoginTableProps> = (
	props: IStatisticsPageNoLoginTableProps,
) => {
	const location = useLocation();

	const [currentData, setcurrentData] = useState<EnduserStats[]>([]);

	//FOR DOWNLOADING THE PDF
	const handleDownloadPDF = (PDFIn: () => JSX.Element, name: string): void => {
		if (PDFIn()) {
			const PDF = pdf(PDFIn()).toBlob();
			PDF.then((data) => {
				const fileURL = URL.createObjectURL(data);
				const link = document.createElement("a");
				link.href = fileURL;
				link.download = name + ".pdf";
				link.click();
			});
		}
		//SEE DOWNLOAD PDF LINK https://react-pdf.org/advanced#on-the-fly-rendering
		//MAKE USE OF THIS PACKAGE FOR DOWNLOADING
	};

	const handleDiplomaClick = async (id?: number, data?: EnduserStats) => {
		if (data && id) {
			if (props.certificateData) {
				console.log(data);
				const diploma = () => {
					return (
						<Diploma
							contentProvider={props.certificateData!.authorName}
							diplomaTypeHeader={props.certificateData!.headerID}
							certificateName={props.certificateData!.name}
							description={props.certificateData!.description}
							clientName={""}
							studentName={data.firstName + " " + data.lastName}
							languageString={1}
							finishDate={data.timeEnded ? formatDate(new Date(data.timeEnded!)) : ""}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							uploadedLogo={props.certificateData!.logo}
							enduserID={id}
							certificateID={props.certificateData!.id}
						/>
					);
				};
				handleDownloadPDF(
					diploma,
					data.timeEnded
						? formatDate(new Date(data.timeEnded!)) +  "_" + data.firstName + "_" + data.lastName
						: "",
				);
			} else {
			}
		}
	};

	function convertDate(string){
		const dateComponents = string.split(/[-\s:]/);
		const outputString = dateComponents[2] + "/" + dateComponents[1] + "/" + dateComponents[0] + " " + dateComponents[3] + ":" + dateComponents[4] + ":" + dateComponents[5];
		return outputString;
	}

	const columns: MRT_ColumnDef<EnduserStats>[] = [
		{
			accessorKey: "id",
			header: "ID",
			size: 40,
		},
		{
			accessorKey: "firstName",
			header: "Name",
			size: 120,
		},
		{
			accessorKey: "lastName",
			header: "Last name",
			size: 120,
		},
		{
			accessorKey: "timeTaken",
			header: "Time spent",
			size: 120,
		},
		{
			accessorKey: "timeEnded",
			header: "Time finished",
			Cell: ({ cell }) => <div>{cell.getValue<any>() !== null ? convertDate(cell.getValue()) : ""}</div>,
		},
		{
			accessorKey: "attempts",
			header: "Amount of tries",
			size: 120,
		},
		{
			accessorKey: "passed",
			header: "Passed",
			size: 120,
			// eslint-disable-next-line react/prop-types
			Cell: ({ cell }) => <div>{cell.getValue<any>() === true ? <div>Passed</div> : <div>Failed</div>}</div>,
		},
		{
			accessorKey: "diplomaPassed",
			size: 100,
			header: "",
			// eslint-disable-next-line react/prop-types
			Cell: ({ cell, row }) => (
				<div>
					{cell.getValue<any>() === true ? (
						<Button onClick={() => handleDiplomaClick(row.original.id, row.original)}>
							<WorkspacePremiumIcon />
						</Button>
					) : (
						<Button disabled>
							<WorkspacePremiumIcon />
						</Button>
					)}
				</div>
			),
		},
		{
			accessorKey: "errorsInTag",
			header: "Errors in tag",
			size: 120,
		},
	];

	const handleExportRows = (rows: MRT_Row<EnduserStats>[], isDanskFørstehjælp?: boolean) => {
		console.log(rows);
		const csvExport: any[] = [];
		const csvOptions = {
			filename: isDanskFørstehjælp ? "dfr" : Number(location.pathname.split("/")[2]) + (props.tagChosen ? "-" + props.tagChosen.name : ""),
			fieldSeparator: ",",
			quoteStrings: '"',
			decimalSeparator: ".",
			showLabels: true,
			useBom: true,
			useKeysAsHeaders: false,
			headers: columns.map((c) => c.header),
		};
	
		const csvExporter = new ExportToCsv(csvOptions);
		rows.forEach((row) => {
			if (isDanskFørstehjælp) {
				csvExport.push({
					"CPR-nummer": "-XXXX",
					"Deltagernavn": row.original.firstName + " " + row.original.lastName,
					"Email": " "
				})
			} else {
				if (props.tagChosen) {
					csvExport.push({
						ID: row.original.id,
						Name: row.original.firstName,
						LastName: row.original.lastName,
						Time_spent: row.original.timeTaken ? row.original.timeTaken : " ",
						End_time: row.original.timeEnded ? row.original.timeEnded : " ",
						Max_user_tries: row.original.maxReached,
						Amount_of_tries: row.original.attempts,
						Passed: row.original.passed,
						Errors_in_tag: row.original.errorsInTag,
					});
				} else {
					csvExport.push({
						ID: row.original.id,
						Name: row.original.firstName,
						LastName: row.original.lastName,
						Time_spent: row.original.timeTaken ? row.original.timeTaken : " ",
						End_time: row.original.timeEnded ? row.original.timeEnded : " ",
						Max_user_tries: row.original.maxReached,
						Amount_of_tries: row.original.attempts,
						Passed: row.original.passed,
					});
				}
			}
		});
		csvExporter.generateCsv(csvExport);
	};

	useEffect(() => {
		if (props.data) {
			console.log("props.data is set");
			console.log(props.data);

			setcurrentData(props.data);
		}
	}, [props.data]);

	return (
		<div className="StatisticsPageNoLoginTable">
			<MaterialReactTable
				key={currentData.length}
				columns={columns}
				data={currentData}
				enableRowSelection
				enableDensityToggle={false}
				enableFullScreenToggle={false}
				enableColumnVirtualization={false}
				enableColumnFilterModes={false}
				enableColumnActions={false}
				enableColumnResizing={false}
				enableColumnFilters={false}
				enableHiding={false}
				positionToolbarAlertBanner="bottom"
				state={{
					isLoading: props.data ? false : true,
					columnVisibility: { errorsInTag: props.tagChosen ? true : false },
				}}
				enablePagination={false}
				renderTopToolbarCustomActions={({ table }) => (
					<>
						<div className="Buttons">
						<div className="exportAll">
								<Button
									disabled={table.getPrePaginationRowModel().rows.length === 0}
									//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
									startIcon={<FileDownloadIcon />}
									variant="contained"
								>
									Export all rows
								</Button>
								{/* <div className="linkTo" onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, true)}>Dansk Førstehjælp udtræk</div> */}
							</div>
							<Button
								disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
								//only export selected rows
								onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
								startIcon={<FileDownloadIcon />}
								variant="contained"
							>
								Export Selected Rows
							</Button>
						</div>
					</>
				)}
			/>
		</div>
	);
};

export { StatisticsPageNoLoginTable };
