/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react";
import { AnswerButtonType, EnduserStatement } from "../../models";
import QuestionNavButton from "./QuestionNavButton";
import QuizAnswerButton from "./QuizAnswerButton";
import "./styles/QuizImageNavigation.scss";
import { Translation } from "../../../translations/translationTypes";
import QuizTextDisplayer from "./QuizTextDisplayer";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import UserScreen6 from "../../userScreenPage/components/UserScreen6";
import { log } from "console";

interface QuizImageNavigationProps {
	isPreview?: boolean;
	hasVideoEnded: boolean;
	statements: EnduserStatement[];
	indexPlayer: HTMLAudioElement;
	soundPlayer: HTMLAudioElement;
	setStatements: (value: EnduserStatement[] | ((prevState: EnduserStatement[]) => EnduserStatement[])) => void;
	text: string;
	introQuestion: string;
	introQuestionSound: string;
	chosenLanguage: Translation;
	setIsAnswered: (value: boolean | ((prevState: boolean) => boolean)) => void;
	isAnswered: boolean;
}

/* Component responsible for displaying the bar where statements and answering are done, it is also managing the state of this */
export const QuizImageNavigation: React.FC<QuizImageNavigationProps> = React.memo((props: QuizImageNavigationProps) => {
	const languageString = props.chosenLanguage;

	const [selectedStatement, setSelectedStatement] = useState<number>(10);

	const [text, setText] = useState(props.introQuestion);

	const { width, height } = useWindowDimensions();

	const [widthNotEnough, setWidthNotEnough] = useState<boolean>(430 >= width);

	useEffect(() => {
		setWidthNotEnough(430 > width);
	}, [width, height]);

	//USEEFFECT FOR WHAT HAPPENS WHEN THE VIDEO IS OVER
	useEffect(() => {
		if (props.hasVideoEnded) {
			setSelectedStatement(10); //For triggering state update inside QuizNavButton
			setTimeout(() => setSelectedStatement(100), 1200);
			setText(props.introQuestion);
		}
		setText(props.introQuestion);
	}, [props.hasVideoEnded, props.introQuestion]);

	let isNotAnswered = true;

	useEffect(() => {
		if (props.statements[selectedStatement]) {
			setText(props.statements[selectedStatement].soundText);
		}

		props.statements.map((statement) => {
			if (statement.answer === 0) {
				isNotAnswered = false;
			}
		});
		if (isNotAnswered) {
			props.setIsAnswered(true);
		}
	});

	const getNavStateCssClass = () => {
		if (props.hasVideoEnded) {
			return "quiz-image-navigation--fade-in";
		} else if (!props.hasVideoEnded) {
			return "quiz-image-navigation--disabled";
		} else if (props.isAnswered) {
			return setTimeout(() => {
				"quiz-image-navigation--fade-out";
			}, 5000);
		} else {
			return "";
		}
	};

	const renderStatements = (): JSX.Element[] => {
		const statements: JSX.Element[] = [];
		props.statements.map((statement, index) => {
			statements.push(
				<QuestionNavButton
					key={index}
					isPreview={props.isPreview}
					id={index}
					buttonText={String(index + 1)}
					onClickFunction={() => handleQuestionClick(index)}
					indexSound={statement.indexSound}
					sound={statement.soundMedia}
					selectedStatement={selectedStatement}
					setSelectedStatement={setSelectedStatement}
					indexPlayer={props.indexPlayer}
					soundPlayer={props.soundPlayer}
					chosenLanguage={props.chosenLanguage}
				/>,
			);
		});

		return statements;
	};

	const handleHint = (index): void => {
		setText(props.introQuestion);
		setSelectedStatement(index);
	};

	const handleQuestionClick = (index): void => {
		setText(props.statements[index].soundText);
		setSelectedStatement(index);
	};

	const handleYesAnswer = (): void => {
		if (selectedStatement != undefined) {
			const statementCopy = props.statements;
			statementCopy[selectedStatement].answer = 1;
			if (selectedStatement !== props.statements.length - 1) {
				setText(props.statements[selectedStatement + 1].soundText);
				setTimeout(() => setSelectedStatement(selectedStatement + 1), 400);
			} else {
				setText(props.statements[selectedStatement].soundText + " ");
				setTimeout(() => setSelectedStatement(101), 400);
			}
		} else {
			alert("NO STATEMENT SELECTED");
		}
	};

	const handleNoAnswer = (): void => {
		//TODO ADD REAL IMPL
		if (selectedStatement != undefined) {
			const statementCopy = props.statements;
			statementCopy[selectedStatement].answer = 2;
			props.setStatements(statementCopy);
			console.log(props.statements);
			if (selectedStatement !== props.statements.length - 1) {
				setText(props.statements[selectedStatement + 1].soundText);
				setTimeout(() => setSelectedStatement(selectedStatement + 1), 400);
			} else {
				setText(props.statements[selectedStatement].soundText + " ");
				setTimeout(() => setSelectedStatement(101), 400);
			}
		} else {
			alert("NO STATEMENT SELECTED");
		}
	};

	const renderStatementText = (): JSX.Element => {
		
		return <QuizTextDisplayer text={selectedStatement === 100 && props.introQuestion.length > 150 || selectedStatement === 10 && props.introQuestion.length > 150 ? " " : text}></QuizTextDisplayer>;
	};

	const questionContentAndHintButton = (
		<>
			<div className="quiz-image-navigation__introduction-icon">
				<QuestionNavButton
					key={100}
					id={100}
					isPreview={props.isPreview}
					onClickFunction={() => handleHint(100)}
					buttonText={"?"}
					indexSound={languageString.indexSounds[1]}
					sound={props.introQuestionSound}
					selectedStatement={selectedStatement}
					setSelectedStatement={setSelectedStatement}
					indexPlayer={props.indexPlayer}
					soundPlayer={props.soundPlayer}
					chosenLanguage={props.chosenLanguage}
				/>
			</div>
			{renderStatements()}

			<div className="quiz-image-navigation__question-text">{renderStatementText()}</div>
		</>
	);

	const answerButtons = (
		<div className="quiz-image-navigation__answer-buttons">
			<QuizAnswerButton
				onButtonClicked={handleYesAnswer}
				buttonText={languageString.quizModulePage.imageNavigation.answerYes}
				buttonType={AnswerButtonType.True}
				selectedStatement={props.statements[selectedStatement]}
			/>
			<QuizAnswerButton
				onButtonClicked={handleNoAnswer}
				buttonText={languageString.quizModulePage.imageNavigation.answerNo}
				buttonType={AnswerButtonType.False}
				selectedStatement={props.statements[selectedStatement]}
			/>
		</div>
	);

	return (
		<>
			{widthNotEnough ? (
				<UserScreen6 />
			) : (
				<div className={`quiz-image-navigation ${getNavStateCssClass()}`}>
					{questionContentAndHintButton}
					{selectedStatement < 5 ? answerButtons : null}
				</div>
			)}
		</>
	);
});

export default QuizImageNavigation;
