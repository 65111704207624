import React, { FC, useEffect, useState } from "react";
import gearAnimation from "../../../assets/newLoader.gif";
import "./styles/IntermediaryLoading.scss";

export interface IntermediaryLoadingProps {
	className?: string;
	extraText?: string[];
}

export const IntermediaryLoading: FC<IntermediaryLoadingProps> = (props: IntermediaryLoadingProps) => {
	const [textIndex, setTextIndex] = useState<number>(0);
	useEffect(() => {
		let timeout; // Move on to the next text every `n` milliseconds
		if (props.extraText) {
			if (textIndex < props.extraText.length) {
				timeout = setTimeout(() => setTextIndex(textIndex + 1), 3000);
			} else {
				setTextIndex(0);
			}
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [props.extraText, textIndex]);
	return (
		<>
			<div className="IntermediaryLoading-BG">
			<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
				<p className="IntermediaryloadingText">{props.extraText ? props.extraText[textIndex] : null}</p>
			</div>
		</>
	);
};

export default IntermediaryLoading;
