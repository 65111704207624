const SLASH = "/";
const LOGIN = "login";
const DASHBOARD = "dashboard";
const CERTIFICATE_CONFIGURATION = "certificate-configuration";
const CLIENT_CREATOR = "client-creator";
const CLIENT_MANAGEMENT = "client-management";
const STATISTICS = "statistics";
const USER_GUIDE = "user-guide";
const CERTIFICATE_PREFIX = "certificate";
const USER_SCREEN = "user-screen";
const QUIZ_MODULE = "quiz-module";
const CERT_ID = "id";
const COLON = ":";

export const PATH_HOME: string = SLASH;
export const PATH_LOGIN: string = SLASH + LOGIN;
export const PATH_DASHBOARD: string = SLASH + DASHBOARD;
export const PATH_CERTIFICATE_CONFIGURATION: string = SLASH + CERTIFICATE_CONFIGURATION;
export const PATH_CLIENT_MANAGEMENT: string = SLASH + CLIENT_MANAGEMENT;
export const PATH_STATISTICS: string = SLASH + STATISTICS;
export const PATH_USER_GUIDE: string = SLASH + USER_GUIDE;
export const PATH_USER_SCREEN: string = SLASH + CERTIFICATE_PREFIX + SLASH + USER_SCREEN + SLASH + COLON + CERT_ID;
export const PATH_QUIZ_MODULE: string = SLASH + QUIZ_MODULE;
