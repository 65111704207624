import React, { FC, useEffect, useState } from "react";
import { LoadingBar } from "./LoadingBar";
import "./styles/Loading.scss";

export interface LoadingProps {
	className?: string;
	extraText?: string[];
	progress? : any;
}

export const Loading: FC<LoadingProps> = (props: LoadingProps) => {
	const [textIndex, setTextIndex] = useState<number>(0);
	useEffect(() => {
		let timeout; // Move on to the next text every `n` milliseconds
		if (props.extraText) {
			if (textIndex < props.extraText.length) {
				timeout = setTimeout(() => setTextIndex(textIndex + 1), 3000);
			} else {
				setTextIndex(0);
			}
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [props.extraText, textIndex]);


		
	return (
		<>
			<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
			<p className="loadingText">{props.extraText ? props.extraText[textIndex] : null}</p>
			{props.progress ? <LoadingBar key={props.progress.progress} progress={props.progress.progress} name={props.progress.name} total={props.progress.size}/> : null}
		</>
	);
};

export default Loading;
