import React, { useState } from 'react';
import { EnduserQuiz } from '../models';
import UserResultSlide from './UserResultSlide';
import "./UserResultView.scss";
import { Clear } from '@material-ui/icons';
import { Button } from '@material-ui/core';

interface UserResultViewProps {
    quizState: EnduserQuiz;
    closeMe: () => void;
}

const UserResultView: React.FC<UserResultViewProps> = (props: UserResultViewProps) => {

    const [currentSlide, setcurrentSlide] = useState<number>();

    const [quizState, setquizState] = useState<EnduserQuiz>(props.quizState);






    const renderQuizOverview = () => {
        if(quizState){
            return quizState.slides.map((slide) => {
                return <UserResultSlide id={slide.id} key={slide.id} introQuestion={slide.introQuestion} introQuestionSound='' media={slide.media} passed={slide.passed} statements={slide.statements}/>
            })
        }
    }
    return (
        <div className='UserResultView'>
            <div className='box'>
            <Button className="closeButton" onClick={() => props.closeMe()}><Clear/></Button>
            {renderQuizOverview()}
            </div>
        </div>
    );
};

export default UserResultView;