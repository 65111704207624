import { LanguageType, Translation } from "./translationTypes";
import { STORAGE_KEY_CHOSEN_LANGUAGE } from "../constants/browserStorageConstants";
import { localStorageGetItem, localStorageSaveItem } from "../services/browserStorageService";
import { english } from "./english";
import { polish } from "./polish";
import { danish } from "./danish";
import { englishUS } from "./englishUS";
import { finnish } from "./finnish";
import { french } from "./french";
import { german } from "./german";
import { italian } from "./italian";
import { norwegian } from "./norwegian";
import { spanish } from "./spanish";
import { swedish } from "./swedish";

/**
 * Function returns chosen language version, if parameter is null or type of it does
 * not fit to switch condition returns default value - english
 * @param languageType
 */
export const getStringsForChosenLanguage = (languageType: LanguageType | null): Translation => {
	switch (languageType) {
		case LanguageType.English: {
			return english;
		}
		case LanguageType.Danish: {
			return danish;
		}
		case LanguageType.EnglishUS: {
			return englishUS;
		}
		case LanguageType.Finnish: {
			return finnish;
		}
		case LanguageType.French: {
			return french;
		}
		case LanguageType.German: {
			return german;
		}
		case LanguageType.Italian: {
			return italian;
		}
		case LanguageType.Norwegian: {
			return norwegian;
		}
		case LanguageType.Spanish: {
			return spanish;
		}
		case LanguageType.Swedish: {
			return swedish;
		}
		case LanguageType.Polish: {
			return polish;
		}
		default: {
			return english;
		}
	}
};

export const setLanguage = (languageType: LanguageType): void => {
	localStorageSaveItem<LanguageType>(STORAGE_KEY_CHOSEN_LANGUAGE, languageType);
};

export const getChosenLanguage = (): LanguageType | null => {
	return localStorageGetItem<LanguageType>(STORAGE_KEY_CHOSEN_LANGUAGE);
};
