import React, { useEffect, useState } from 'react';
import { getStringsForChosenLanguage, getChosenLanguage } from '../../../translations/translationProvider';
import { findIndexById } from '../../modelFunctions';
import { QuizSlideSimplified, QuizSlideStatementSimplified } from '../../models';
import "./SimpleStatements.scss";

export type ISimpleStatementsProps = {
    index: number;
    slide: QuizSlideSimplified;
    statement: QuizSlideStatementSimplified;
    setcurrentStatements: React.Dispatch<React.SetStateAction<QuizSlideStatementSimplified[] | undefined>>
}

const SimpleStatements: React.FC<ISimpleStatementsProps> = (props: ISimpleStatementsProps) => {

    const [currentTextState, setcurrentTextState] = useState(props.statement.text);
    const [currentAnswerState, setcurrentAnswerState] = useState(props.statement.correctAnswer);

    const languageString = getStringsForChosenLanguage(getChosenLanguage());


    useEffect(() => {
        props.setcurrentStatements((statements) => {
            const copyStatements = statements
            if(copyStatements){
                const currentStatementIndex = findIndexById(copyStatements, props.statement.id)
                const copyStatement = copyStatements[currentStatementIndex];
                copyStatement.text = currentTextState
            }
            return copyStatements;
        })
    }, [currentTextState]);

    useEffect(() => {
        props.setcurrentStatements((statements) => {
            const copyStatements = statements
            if(copyStatements){
                const currentStatementIndex = findIndexById(copyStatements, props.statement.id)
                const copyStatement = copyStatements[currentStatementIndex];
                copyStatement.correctAnswer = currentAnswerState
            }
            return copyStatements;
        })
    }, [currentAnswerState]);

    const handleChangeStatementText = (newValue: string) => {
       setcurrentTextState(newValue)
        
    }

    const handleChangeStatementAnswer = (newValue: number) => {
        setcurrentAnswerState(newValue)
    }

    const answerOptions = (
        <>
        <option value={-1}></option>
        <option value={1}>Yes</option>
        <option value={2}>No</option>
        <option value={3}>Both</option>
        </>
    )


    return (
        <div className='SimpleStatement'>
            <div className='text'>{props.index + ". " + languageString.certificateConfigurationPage.simpleForm.slide.statement}</div>
            <div className='inputs'>
            <input value={currentTextState} onChange={(event) => handleChangeStatementText(event.target.value)}></input>
            <select value={currentAnswerState} onChange={(event) => handleChangeStatementAnswer(Number(event.target.value))}>{answerOptions}</select>
            </div>
        </div>
    );
}

export { SimpleStatements };