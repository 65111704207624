import React, { FC, useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { CertificateHeadlineType, DescriptionContentType } from "../../models";
import { LanguageType, Translation } from "../../../translations/translationTypes";
import background from "../../../assets/diploma-bg.jpg";
import EasyPiecyLogo from "../../../assets/EasyPiecy-userscreens.png";
import EasyPiecyLogo_transparent from "../../../assets/EasyPiecyLinkDiploma.png";
import blueLine from "../../../assets/blue_line.png";
import { convertCertificateHeadlineTypeToString, convertIDToLanguageString } from "../../../translations/translationEnumConverter";
import "./styles/Diploma.scss";
import { getStringsForChosenLanguage } from "../../../translations/translationProvider";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-expect-error
import font from "../../../fonts/proximanova/Proxima Nova Regular/ProximaNova_Regular.ttf";
import { convertImgToBase64URL } from "../../../services/utilFunctions";

export interface DiplomaProps {
	certificateID?: number;
	contentProvider: string;
	diplomaTypeHeader: CertificateHeadlineType;
	certificateName: string;
	description: string;
	optionalInfo?: string;
	clientName: string;
	studentName?: string;
	uploadedLogo?: string;
	logoColor?: string;
	languageString: number;
	enduserID?: number;
	finishDate?: string;
}
/* eslint-disable react/display-name */
export const Diploma: FC<DiplomaProps> = React.memo((props: DiplomaProps) => {
	console.log(props.finishDate);

	const completionDate = props.finishDate
		? new Date(props.finishDate?.replace(/(\d+[/])(\d+[/])/, "$2$1"))
		: new Date();
	console.log(props.finishDate?.replace(/(\d+[/])(\d+[/])/, "$2$1"));

	const formatDate = props.finishDate;
	const styles = StyleSheet.create({
		pageBackground: {
			position: "absolute",
			minWidth: "100%",
			minHeight: "100%",
			height: "100%",
			width: "100%",
		},
		certName: {
			fontSize: 45,
		},
		bottom: {
			flexDirection: "row",
		},
		bottomLogo: {
			maxWidth: "10pt",
			maxHeight: "10pt",
			marginTop: "20pt",
		},
	});
	const diplomaLanguageString = (): Translation => {
		return convertIDToLanguageString(props.languageString);
	};


	
	const diplomaLanguageStringForUse = diplomaLanguageString();

	const uploadedLogoDefiner =  () => {
		if (props.uploadedLogo === "") {
			return EasyPiecyLogo;
		} else {
			return props.uploadedLogo
		}
	};
	const studentNameDefiner = () => {
		if (props.studentName === undefined) {
			return "John Johnny Hansen";
		} else {
			return props.studentName;
		}
	};
	const headlineDefiner = () => {
		if (props.diplomaTypeHeader === undefined) {
			return "NO HEADER CHOSEN";
		} else {
			return convertCertificateHeadlineTypeToString(props.diplomaTypeHeader, diplomaLanguageString());
		}
	};

	const certificateNameDefiner = () => {
		if (props.certificateName === "") {
			return "NO CERTIFICATE NAME INPUT";
		} else {
			return props.certificateName;
		}
	};
	const descriptionDefiner = () => {
		if (props.description === "") {
			return "";
		} else {
			return props.description;
		}
	};
	Font.register({
		family: "ProximaNova-Regular",
		src: font,
		fontStyle: "normal",
		fontWeight: "500",
	});

	return (
		<Document>
			<Page size="A4" wrap={false} style={{ fontFamily: "ProximaNova-Regular" }}>
				<Image src={background} style={styles.pageBackground} />
				<View style={{ flexDirection: "column", height: "100vh"}}>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							maxHeight: "140pt",
							marginBottom: "30pt" ,
						}}
					>
						<Image
							style={{ width: "30%", height: "100%", padding: "4vh", objectFit: "scale-down" }}
							src={uploadedLogoDefiner()}
						/>
						<Image src={EasyPiecyLogo_transparent} style={{ width: "40%", height: "100%", objectFit: "scale-down", padding: "4vh"}} />
					</View>
					<View style={{ marginTop: "20pt", display: "flex", alignItems: "center" }}>
						<Text style={{ fontSize: 40, textTransform: "uppercase" }}>{headlineDefiner()}</Text>
						<Image src={blueLine} style={{ width: "65%" }} />
						<Text style={{ opacity: 0.25 }}> {diplomaLanguageStringForUse.diploma.SUCCESS} </Text>
					</View>
					<View style={{ textAlign: "center", marginTop: "60pt", marginBottom: "40pt" }}>
						<Text style={{ fontSize: 25 }}>{studentNameDefiner()}</Text>
						<Text style={{ fontSize: 7 }}>{props.optionalInfo ? props.optionalInfo : null}</Text>
					</View>
					<View style={{ fontSize: 15, fontWeight: 500, textAlign: "center", marginBottom: "40pt" }}>
						<Text>{certificateNameDefiner()}</Text>
					</View>
					<View style={{ width: "85vw", height: "22vh", marginLeft: "auto", marginRight: "auto" }}>
						<Text style={{ fontSize: 11 }}>{descriptionDefiner()}</Text>
					</View>
					<View style={{ width: "85vw", marginLeft: "auto", marginRight: "auto", marginTop: "auto" }}>
						<View style={{ display: "flex", flexDirection: "column" }}>
							<Text style={{ fontSize: 12, fontWeight: 3 }}>
								{diplomaLanguageStringForUse.diploma.certIssuedBy + ":"}{" "}
							</Text>
							<Text style={{ fontSize: 14 }}>{props.contentProvider}</Text>
						</View>
					</View>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							paddingLeft: 20,
							paddingRight: 20,
							marginTop: "auto",
							marginBottom: "15pt",
						}}
					>
						<Text style={{ fontSize: 14, opacity: 0.25 }}>
							the Easypiecylink® Certification Platform{" "}
							{props.certificateID ? props.certificateID : 1234} {"-"}{" "}
							{props.enduserID ? props.enduserID : 123}
						</Text>
						<Text style={{ fontSize: 14, color: "white", marginRight: "45pt" }}>{formatDate}</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
});

export default Diploma;
