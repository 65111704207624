import React, { FC } from "react";
import failIcon from "../../../../assets/fail-icon.png";

export interface UserScreenFailIconProps {
	className?: string;
}

export const UserScreenFailIcon: FC<UserScreenFailIconProps> = (props: UserScreenFailIconProps) => {
	return <img src={failIcon} alt="Icon" className={props.className} />;
};

export default UserScreenFailIcon;
