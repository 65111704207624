import React, { FC } from "react";
import { SettingsFormState } from "../../../models";
import { getChosenLanguage, getStringsForChosenLanguage } from "../../../../translations/translationProvider";
import { getNumberOrEmptyStringFromEventTarget } from "../../../../services/utilFunctions";
import CertificateConfigurationInput, { InputType } from "../CertificateConfigurationInput";
import CertificateConfigurationCheckBox from "../CertificateConfigurationCheckBox";
import CertificateConfigurationTextArea from "../CertificateConfigurationTextArea";
import "../styles/form/CertificateConfigurationSettingsForm.scss";

export interface CertificateConfigurationSettingsFormProps {
	settingsFormState: SettingsFormState;
	setSettingsFormState: (value: SettingsFormState | ((prevState: SettingsFormState) => SettingsFormState)) => void;
	formValidation: any;
}

/**
 * This is component which displays form responsible for getting data like expire date or list of phone numbers
 * It is placed in right bottom corner of form
 */
export const CertificateConfigurationSettingsForm: FC<CertificateConfigurationSettingsFormProps> = (
	props: CertificateConfigurationSettingsFormProps,
) => {
	const languageString = getStringsForChosenLanguage(getChosenLanguage());

	const handleChangeMaxUses = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setSettingsFormState((prevState) => {
			return { ...prevState, maxUses: getNumberOrEmptyStringFromEventTarget(event.target) };
		});
	};

	const handleChangeExpiryDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.formValidation.expiryDate.setData(new Date(event.target.value));
		props.formValidation.expiryDate.validate(props.settingsFormState.expireDate);
		props.setSettingsFormState((prevState) => {
			return { ...prevState, expireDate: new Date(event.target.value) };
		});
	};

	const handleChangeOnlySelectedUsers = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setSettingsFormState((prevState) => {
			return { ...prevState, isOnlySelectedUsers: event.target.checked };
		});
	};

	const handleChangeListOfMobileNumbers = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
		props.setSettingsFormState((prevState) => {
			return { ...prevState, listOfMobileNumbers: event.target.value };
		});
	};

	const handleChangeRefreshAllowed = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setSettingsFormState((prevState) => {
			return { ...prevState, refreshAllowed: event.target.checked };
		});
	};

	const handleChangeRefreshDays = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setSettingsFormState((prevState) => {
			return { ...prevState, refreshDays: Number(event.target.value) };
		});
	};

	const maxUsesInput = (
		<div>
			<CertificateConfigurationInput
				isFaded={false}
				labelText={languageString.certificateConfigurationPage.form.formSheet.settingsForm.maxUses}
				hasSemicolonAfterLabel={true}
				inputType={InputType.Number}
				placeholder={""}
				value={props.settingsFormState.maxUses}
				handleChange={handleChangeMaxUses}
			/>
		</div>
	);

	const expiryDateInput = (
		<div>
			<CertificateConfigurationInput
				isFaded={false}
				labelText={languageString.certificateConfigurationPage.form.formSheet.settingsForm.expiryDate}
				hasSemicolonAfterLabel={false}
				inputType={InputType.Date}
				placeholder={
					languageString.certificateConfigurationPage.form.formSheet.settingsForm.expiryDatePlaceholder
				}
				value={props.settingsFormState.expireDate}
				handleChange={handleChangeExpiryDate}
			/>
		</div>
	);

	const onlySelectedUsersCheckbox = (
		<div>
			<CertificateConfigurationCheckBox
				isFaded={false}
				labelText={languageString.certificateConfigurationPage.form.formSheet.settingsForm.onlySelectedUsers}
				isChecked={props.settingsFormState.isOnlySelectedUsers}
				handleChange={handleChangeOnlySelectedUsers}
			/>
		</div>
	);

	const listOfMobileNumbersTextArea = (
		<div className={props.settingsFormState.listOfMobileNumbersError ? "customError" : undefined}>
			<CertificateConfigurationTextArea
				isFaded={false}
				labelText={""}
				hasSemicolonAfterLabel={false}
				placeholder={
					languageString.certificateConfigurationPage.form.formSheet.settingsForm
						.listOfMobileNumbersPlaceholder
				}
				rows={6}
				cols={24}
				value={props.settingsFormState.listOfMobileNumbers}
				handleChange={handleChangeListOfMobileNumbers}
			/>
		</div>
	);

	const refreshAllowed = (
		<div className="refresh">
			<CertificateConfigurationCheckBox
				isFaded={false}
				labelText={languageString.certificateConfigurationPage.form.formSheet.settingsForm.refreshAllowed}
				isChecked={props.settingsFormState.refreshAllowed}
				handleChange={handleChangeRefreshAllowed}
			/>
			<CertificateConfigurationInput
				inputType={InputType.Number}
				isFaded={!props.settingsFormState.refreshAllowed}
				labelText={""}
				handleChange={handleChangeRefreshDays}
				hasSemicolonAfterLabel={false}
				placeholder={languageString.certificateConfigurationPage.form.formSheet.settingsForm.refreshAllowedPlaceholder}
				value={props.settingsFormState.refreshDays ? props.settingsFormState.refreshDays : ""}
			/>
		</div>
	);

	return (
		<div className="formDetails">
			{refreshAllowed}
			{maxUsesInput}
			{expiryDateInput}
			{/* <p className="error">{props.formValidation.expiryDate.getError()}</p> */}
			{onlySelectedUsersCheckbox}
			{listOfMobileNumbersTextArea}
		</div>
	);
};

export default CertificateConfigurationSettingsForm;
