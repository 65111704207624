import React, { useEffect, useState } from "react";
import { findIndexById } from "../../modelFunctions";
import {
	QuizSlide,
	QuizSlideSimplified,
	QuizSlideSimplifiedTags,
	QuizSlideStatementSimplified,
	QuizState,
} from "../../models";
import AddIcon from "@mui/icons-material/Add";
import { Button, Slider, Switch } from "@material-ui/core";
import { SimpleStatements } from "./SimpleStatements";
import "./SimpleSlide.scss";
import { IconInputAcceptType } from "../../global/components/MediaInputButton";
import VideoThumbnail from "react-video-thumbnail";
import { Skeleton } from "@mui/material";
import { Tag } from "../../../graphql/generated/graphql";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { getStringsForChosenLanguage, getChosenLanguage } from "../../../translations/translationProvider";
import axios from "axios";

//A method for creating the thumbnail
export const getVideoThumbnail = async (videoUrl) => {
	try {
	  if (videoUrl.includes('youtube.com')) {
		const videoId = new URL(videoUrl).searchParams.get('v');
		const apiKey = 'AIzaSyB9IIlSzu7oXK_L7dIJACCZRbTBLc5gfyk'; // Replace with your actual API key
		const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet`;
  
		const response = await axios.get(apiUrl);
		return response.data.items[0]?.snippet?.thumbnails?.medium?.url;
	  } else if (videoUrl.includes('vimeo.com')) {
		const videoId = new URL(videoUrl).pathname.split('/').pop();
		const apiUrl = `https://vimeo.com/api/v2/video/${videoId}.json`;
  
		const response = await axios.get(apiUrl);
		return response.data[0]?.thumbnail_medium;
	  } else {
		console.error('Unsupported video platform');
		return null;
	  }
	} catch (error) {
	  console.error('Error fetching video thumbnail:', error);
	  return null;
	}
  };

export type ISimpleSlideProps = {
	slide: QuizSlideSimplified;
	setquizState: React.Dispatch<React.SetStateAction<QuizSlideSimplified[] | undefined>>;
	handleDelete: () => void;
};

const SimpleSlide: React.FC<ISimpleSlideProps> = (props: ISimpleSlideProps) => {
	const languageString = getStringsForChosenLanguage(getChosenLanguage());
	const [currentIntroQuestion, setcurrentIntroQuestion] = useState(props.slide.introductionQuestion);
	const [currentTag, setcurrentTag] = useState(props.slide.tags);
	const [currentStatements, setcurrentStatements] = useState(props.slide.statements);
	const [thumbnailLink, setThumbnailLink] = useState("");

	const [videoLink, setvideoLink] = useState<string>();

	//Usestate for controlling when adding media
	const [changeMediaState, setchangeMediaState] = useState<number>(0);

	const [noQuestions, setnoQuestions] = useState(props.slide.noQuestions);

	useEffect(() => {
		if(props.slide.isVideo === true){
			getVideoThumbnail(props.slide.media).then((data) => {
				console.log(data);
				
				setThumbnailLink(data);
			})
		}
	}, [props.slide.media]);

	//THIS IS USED TO UPDATE THE HIGHER COMPONENT EVERYTIME WE UPDATE THE STATEMENT VALUES
	useEffect(() => {
		props.setquizState((quizState) => {
			if (quizState && props.slide) {
				const currentSlideIndex = findIndexById(quizState, props.slide?.id);
				const copy = quizState;
				if (copy[currentSlideIndex]) {
					copy[currentSlideIndex].statements = currentStatements;
				}
				return copy;
			}
		});
	}, [currentStatements, props.slide]);

	//THIS IS USED TO RENDER THE THUMBNAIL IF A VIDEO IS DISPLAYED

	function calculateValue(value) {
		switch (value) {
			case 1:
				return 0.25;
			case 2:
				return 0.5;
			case 3:
				return 1;
			case 4:
				return 2;
			case 5:
				return 3;
			case 6:
				return 5;
			default:
				return 0;
		}
	}

	function uncalcuateValue(value) {
		switch (value) {
			case 0.25:
				return 1;
			case 0.5:
				return 2;
			case 1:
				return 3;
			case 2:
				return 4;
			case 3:
				return 5;
			case 5:
				return 6;
			default:
				return 0;
		}
	}
	const marks = [
		{ value: 1, label: "0.25" },
		{ value: 2, label: "0.50" },
		{ value: 3, label: "1" },
		{ value: 4, label: "2" },
		{ value: 5, label: "3" },
		{ value: 6, label: "5" },
	];

	const handleDeleteSlide = () => {
		props.handleDelete();
	};

	const handleChangeNoQuestions = (event: any) => {
		console.log(event);
		props.setquizState((quizState) => {
			if (quizState && props.slide) {
				const currentSlideIndex = findIndexById(quizState, props.slide.id);
				const copy = [...quizState];
				if (copy[currentSlideIndex]) {
					copy[currentSlideIndex].noQuestions = !noQuestions;
				}
				return copy;
			}
			return quizState;
		});
		setnoQuestions(!noQuestions);
	};

	const handleChangeSlideWeight = (event: any, newValue: number | number[]) => {
		const calculatedValue = calculateValue(newValue);

		props.setquizState((quizState) => {
			if (quizState && props.slide) {
				const currentSlideIndex = findIndexById(quizState, props.slide.id);
				const copy = [...quizState];
				if (copy[currentSlideIndex]) {
					copy[currentSlideIndex].slideWeight = calculatedValue;
				}
				return copy;
			}
			return quizState;
		});
	};

	const handleChangeintroQuestion = (newValue: string) => {
		props.setquizState((quizState) => {
			if (quizState && props.slide) {
				const currentSlideIndex = findIndexById(quizState, props.slide?.id);
				const copy = quizState;
				copy[currentSlideIndex].introductionQuestion = newValue;
				setcurrentIntroQuestion(newValue);
				return copy;
			}
		});
	};

	const handleChangeSlideTag = (newValue: string) => {
		props.setquizState((quizState) => {
			if (quizState && props.slide) {
				const currentSlideIndex = findIndexById(quizState, props.slide?.id);
				const copy = quizState;
				copy[currentSlideIndex].tags = newValue;
				setcurrentTag(newValue);
				return copy;
			}
		});
	};

	const handleChangeAddStatement = () => {
		props.setquizState((quizState) => {
			if (quizState && props.slide) {
				const currentSlideIndex = findIndexById(quizState, props.slide.id);
				const copy = [...quizState]; // Create a copy of the quizState array
				if (copy[currentSlideIndex]) {
					if (currentStatements) {
						const currentStatementscopy = [...currentStatements]; // Create a copy of the currentStatements array
						currentStatementscopy.push({
							id: currentStatementscopy.length,
							correctAnswer: -1,
							text: "",
						});
						copy[currentSlideIndex].statements = currentStatementscopy; // Update the statements array in the copied quizState
						setcurrentStatements(currentStatementscopy); // Update the local currentStatements state
					} else {
						const newStatement = { id: 0, correctAnswer: -1, text: "" };
						copy[currentSlideIndex].statements = [newStatement]; // Update the statements array in the copied quizState
						setcurrentStatements([newStatement]); // Update the local currentStatements state
					}
				}
				return copy;
			}
		});
	};

	const onClickMedia = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		const element = event.target as HTMLInputElement;
		element.value = "";
	};

	const handleAddMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileData = event.target.files?.[0];

		props.setquizState((quizState) => {
			if (quizState && props.slide) {
				const currentSlideIndex = findIndexById(quizState, props.slide.id);
				const copy = [...quizState];
				if (copy[currentSlideIndex] && fileData) {
					copy[currentSlideIndex].media = URL.createObjectURL(fileData);
					copy[currentSlideIndex].actualMedia = fileData;
					copy[currentSlideIndex].isVideo = false;
					copy[currentSlideIndex].hasError = false;
				}
				return copy;
			}
			return quizState;
		});
	};

	const handleAddMediaVideo = () => {
		if(videoLink){
			props.setquizState((quizState) => {
				if (quizState && props.slide) {
					const currentSlideIndex = findIndexById(quizState, props.slide.id);
					const copy = [...quizState];
					if (copy[currentSlideIndex]) {
						copy[currentSlideIndex].media = videoLink;
						copy[currentSlideIndex].isVideo = true;
						copy[currentSlideIndex].hasError = false;
					}
					return copy;
				}
				return quizState;
			});
		}
		}

	const handleChangeMediaState = (input: number) => {
		setchangeMediaState(input);
	};

	const mediaOrNot = () => {
		if (props.slide?.media) {
			if (props.slide.isVideo) {
				if (thumbnailLink === "") {
					return <Skeleton variant="rectangular" />;
				}
				return <img src={thumbnailLink}></img>;
			} else {
				return (
					<img
						src={props.slide.actualMedia ? URL.createObjectURL(props.slide.actualMedia) : props.slide.media}
					></img>
				);
			}
		} else {
			switch (changeMediaState) {
				case 0:
					return (
						<Button onClick={() => handleChangeMediaState(1)}>
							<AddIcon />
						</Button>
					);
				case 1:
					return (
						<div className="hasMediaPressed">
							<Button onClick={() => handleChangeMediaState(2)}>VIDEO</Button>
							<Button>
								IMAGE
								<input
									type={"file"}
									accept={[IconInputAcceptType.Image, IconInputAcceptType.VideoMp4].toString()}
									onChange={handleAddMedia}
									onClick={onClickMedia}
								/>
							</Button>
						</div>
					);
				case 2:
					return(
						<div className="videoInput">
							<textarea className="videoLink" id="videoInput" value={videoLink} onChange={(value) => setvideoLink(value.target.value)} placeholder="Only Youtube or Vimeo links"></textarea>
							<Button onClick={handleAddMediaVideo}>ADD</Button>
						</div>
					)
			}
			return (
				<Button>
					<input
						type={"file"}
						accept={[IconInputAcceptType.Image].toString()}
						onChange={handleAddMedia}
						onClick={onClickMedia}
					/>
					<AddIcon />
				</Button>
			);
		}
	};

	const renderStatements = currentStatements
		? currentStatements.map((statement, index) => {
				return (
					<React.Fragment key={index}>
						<SimpleStatements
							index={index + 1}
							slide={props.slide!}
							setcurrentStatements={setcurrentStatements}
							statement={statement}
						/>
					</React.Fragment>
				);
		  })
		: null;

	const slideWeightField = (
		<div className="slideweight">
			<label>Slide importance:</label>
			<Slider
				aria-label="Restricted values"
				value={uncalcuateValue(props.slide?.slideWeight ? props.slide.slideWeight : 1)}
				min={1}
				max={6}
				step={null}
				scale={calculateValue}
				valueLabelDisplay="auto"
				onChangeCommitted={handleChangeSlideWeight}
				marks={marks}
			/>
		</div>
	);

	if (props.slide) {
		return (
			<div className={props.slide.hasError ? "SimpleSlide error" : "SimpleSlide"}>
				<div style={{ display: "none" }}>
					{props.slide.actualMedia && props.slide.isVideo ? (
						<img src={thumbnailLink}></img>
					) : null}
				</div>
				<div className="picture">{mediaOrNot()}</div>
				<div className="content">
					<div className="rowNoquestions">
						{languageString.certificateConfigurationPage.simpleForm.slide.noQuestions}
						<Switch
							color={"primary"}
							checked={noQuestions}
							onClick={handleChangeNoQuestions}
							inputProps={{ "aria-label": "controlled" }}
						/>
					</div>
					{noQuestions ? null : (
						<>
							<div className="row">
								<div className="rowText">
									<div>
										{languageString.certificateConfigurationPage.simpleForm.slide.subjectInWord}
									</div>
									<input
										value={currentTag}
										onChange={(event) => handleChangeSlideTag(event.target.value)}
									></input>
								</div>
								{slideWeightField}
							</div>
							<div className="intro row">
								<div className="rowText">
									<div>
										{languageString.certificateConfigurationPage.simpleForm.slide.introQuestion}
									</div>
									<input
										value={currentIntroQuestion}
										onChange={(event) => handleChangeintroQuestion(event.target.value)}
									></input>
								</div>
							</div>
							{renderStatements}
							{currentStatements ? (
								currentStatements.length < 4 ? (
									<Button onClick={handleChangeAddStatement}>
										<AddIcon />
									</Button>
								) : null
							) : (
								<Button onClick={handleChangeAddStatement}>
									<AddIcon />
								</Button>
							)}
						</>
					)}
				</div>
				<div className="deleteButton">
					<Button onClick={handleDeleteSlide}>
						<ClearRoundedIcon />
					</Button>
				</div>
			</div>
		);
	} else {
		return <div></div>;
	}
};

export { SimpleSlide };
