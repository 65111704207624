export enum LanguageType {
	English = "English",
	Danish = "Dansk",
	Polish = "Polski",
	EnglishUS = "EnglishUS",
	Finnish = "Suomen kieli",
	French = "Français",
	German = "Deutsch",
	Italian = "Italiano",
	Norwegian = "Norsk",
	Spanish = "Español",
	Swedish = "Svensk",
}

/**
 * If new page is added, create new interface and add this type here
 */
export interface Translation {
	diploma: DiplomaPage;
	global: Global;
	enums: Enums;
	loginPage: LoginPage;
	dashboardPage: DashboardPage;
	certificateConfigurationPage: CertificateConfigurationPage;
	clientCreatorPage: ClientCreatorPage;
	clientManagementPage: ClientManagementPage;
	statisticsPage: StatisticsPage;
	userGuidePage: UserGuidePage;
	userScreenPage: UserScreenPage;
	quizModulePage: QuizModulePage;
	errorPage: ErrorPage;
	formvalidationMessages: FormValidation;
	termsConditions: termsConditions;
	termsGDPR: termsGDPR;
	indexSounds: indexSounds;
}

interface Enums {
	certificateStatusType: {
		Active: string;
		EditDraft: string;
		Expired: string;
	};
	activeInactiveType: {
		On: string;
		Off: string;
	};
	dateFormatType: {
		UK: string;
		US: string;
		EU: string;
	};
	certificateHeadlineType: {
		OnlineCourse: string;
		Certificate: string;
		Diploma: string;
		ProofOfCompletion: string;
	};
	certificateLanguageType: {
		English: string;
		EnglishUS: string;
		Danish: string;
		Polish: string;
		Swedish: string;
		German: string;
		Norwegian: string;
		Finnish: string;
		French: string;
		Italian: string;
		Spanish: string;
	};
	questionAnswerType: {
		Yes: string;
		No: string;
		Both: string;
	};
}

interface Global {
	changeLanguage: string;
	alertBox: {
		description: string;
		yes: string;
		no: string;
	};
	easyPiecyLogo: {
		description: string;
	};
	footer: {
		description: string;
		moreInformation: string;
		contact: string;
	};
	logOutButton: {
		text: string;
	};
	searchBox: {
		placeholder: string;
	};
}

interface termsConditions {
	header1: string;
	paragraph1: string;
	header2: string;
	paragraph2: string;
}

interface termsGDPR {
	header1: string;
	paragraph1: string;
	header2: string;
	paragraph2: string;
}

interface indexSounds {
	1: string;
	2: string;
	3: string;
	4: string;
}
interface DiplomaPage {
	SUCCESS: string;
	Optional: string;
	certIssuedBy: string;
	inCooperatinWith: string;
}

interface LoginPage {
	optimizedChrome: string;
	error: string;
	loginForm: {
		emailInputPlaceholder: string;
		passwordInputPlaceholder: string;
		loginButton: string;
		checkboxDescription: string;
	};
}

interface FormValidation {
	required: string;
	atleastOne: string;
	atleastOneTag: string;
	noDuplicateTags: string;
	cannotBeOlder: string;
	errorCannotBeZero: string;
}

interface DashboardPage {
	points: string;
	noCertificates: string;
	phoneNumberInput: {
		confirmButton: string;
		introText: string;
	};

	quickClone:{
		header: string;
		headerSub: string;
		publish: string;
		saveDraft: string;
		cancel: string;
	},
	addMoreCPCs: {
		confirmButton: string;
		introText: string;
	}
	contactButton: {
		text: string;
	};
	iconButton: {
		createNewCertificate: string;
		clientManagement: string;
		userGuide: string;
	};
	dashboardTable: {
		headerTitles: {
			date: string;
			name: string;
			internalRef: string;
			attachedTo: string;
			language: string;
			expireDate: string;
			optional: string;
			status: string;
			addPhone: string;
			statistics: string;
			link: string;
			start: string;
			clone: string;
			delete: string;
			advancedSettings: string;
		};
		contentDetails: {
			certificateDetails: string;
			shuffleQuestions: string;
			shuffleSlides: string;
			maxSlides: string;
			maxAttempts: string;
			maxCertifications: string;
			dateTimeFormat: string;
			showDiploma: string;
			language: string;
			optional: string;
			certificateLink: string;
			addPhoneNumber: string,
			changeExpireDate: string,
		};
	};
}

interface CertificateConfigurationPage {
	simpleForm: {
		name: string;
		language: string;
		internalRef: string;
		maxNumber: string;
		advanced: string;
		slide: {
			noQuestions: string;
			subjectInWord: string;
			slideImportance: string;
			introQuestion: string;
			statement: string;
		}
	},

	quickGuide: {
		next: string;
		Step1:{
			name: string;
			title: string
			titleSub: string;
			internalName: string;
			internalSub: string;
			collab: string;
			collabSub: string;
		}
		Step2:{
			name: string;
			language:string;
			languageSub: string;
			headline: string;
			headlineSub: string;
			logo: string;
			logoSub: string;
			color: string;
			colorSub: string;
			enduserPreview: string;
		}
		Step3: {
			name: string;
			description: string;
			descriptionSub: string;
			downloadDiploma: string; 
			downloadDiplomaSub: string;
			diplomapreview: string;
		}
		Step4: {
			name: string;
			maxAllowedTries: string;
			maxAllowedTriesSub: string;
			maxAllowedErrors: string;
			maxAllowedErrorsSub: string;
			tags: string;
			tagsSub: string;
			onlySelected: string;
			onlySelectedSub: string;
		}
	}

	form: {
		topBar: {
			goBackToDashboard: string;
			userGuide: string;
			title: string;
		};
		publishNow: string;
		formSheet: {
			basicForm: {
				certificateName: string;
				certificateNameInputPlaceholder: string;
				certificateInternalName: string;
				certificateInternalNameInputPlaceholder: string;
				clientAttached: string;
				clientSelectDefaultValue: string;
				descriptionOfContents: string;
				plainTextToggleSwitch: string;
				htmlMarkupToggleSwitch: string;
				descriptionOfContentsTextAreaPlaceholder: string;
				previewDiploma: string;
			};
			quizDetailsForm: {
				shuffleSlides: string;
				shuffleStatements: string;
				fixedAmountSlides: string;
				allowDownload: string;
				maxAllowedTries: string;
				individualMaxSlideErrors: string;
				timeInputLabel: string;
				tagsInputPlaceholder: string;
				autogen: string;
			};
			certificateDetailsForm: {
				optionalValueName: string;
				optionalValueNamePlaceholder: string;
				amountOfCharactersInOptionalValue: string;
				amountOfCharactersInOptionalValueMinPlaceholder: string;
				amountOfCharactersInOptionalValueMaxPlaceholder: string;
				certificationDateFormat: string;
				certificationDateFormatPlaceholder: string;
				certificationHeadline: string;
				certificationHeadlinePlaceholder: string;
				certificationLanguage: string;
				certificationLanguagePlaceholder: string;
				uploadLogo: string;
				uploadLogoButtonText: string;
			};
			settingsForm: {
				maxUses: string;
				expiryDate: string;
				expiryDatePlaceholder: string;
				onlySelectedUsers: string;
				listOfMobileNumbersPlaceholder: string;
				customMessage: string;
				refreshAllowed: string;
				refreshAllowedPlaceholder: string;
			};
		};
	};
	content: {
		contentSettings: {
			tags: {
				slideNumber: string;
				tagsSelectBoxPlaceholder: string;
			};
			questionBox: {
				answerSelectBoxPlaceholder: string;
				notePlaceholder: string;
				searchNarration: string;
				uploadMp3File: string;
				searchResults: string;
				filePath: string;
				questionsStatementsPlaceholder: string;
				addNarrationButtonText: string;
			};
			saveDraftButtonText: string;
			publishButtonText: string;
		};
		itemsArea: {
			page: string;
			of: string;
			swapSlide: string;
			withSlide: string;
			swapNow: string;
		};
	};
	toCertificateContent: string;
}

interface ClientCreatorPage {
	companyNameInputPlaceholder: string;
	emailInputPlaceholder: string;
	createClientButton: string;
	optionalValueInputPlaceholder: string;
	updateButton: string;
}

interface ClientManagementPage {
	topBar: {
		goBackToDashboard: string;
		newClient: string;
		userGuide: string;
		title: string;
	};
	clientManagementTable: {
		headerTitles: {
			allNoneCheckBox: string;
			businessName: string;
			note: string;
			resendLogin: string;
			edit: string;
		};
		footer: {
			deleteSelected: string;
			page: string;
			of: string;
		};
	};
}

interface StatisticsPage {
	actionSelectBox: {
		tagStatistics: string;
		exportCSV: string;
		markasInvoiced: string;
		deleteEntry: string;
		setDateToSend: string;
	};
	iconButton: {
		backToDashboard: string;
		userGuide: string;
	};
	topBar: {
		actionSelect: string;
		CPSelectPlaceholder: string;
		ClientAccountSelectPlaceholder: string;
		CertificateSelectPlaceholder: string;
	};
	statisticsTable: {
		headerTitles: {
			allorNone: string;
			dateToSend: string;
			date: string;
			firstName: string;
			lastName: string;
			attempts: string;
			optional: string;
			status: string;
			time: string;
			diploma: string;
			invoiced: string;
			passed: string;
			failed: string;
		};
		footer: {
			exportAllRows: string;
			exportChosenRows: string;
			markAllChosen: string;
			showOnlyNotInvoiced: string;
			showAll: string;
		};
	};
}
interface UserGuidePage {}

interface UserScreenPage {
	loadingMessagesIntro: string[];
	loadingMessagesOutro: string[];
	userScreenIntro: {
		youAreAboutTo: string;
		buttonText: string;
		notReady: string;
		faulty: string;
	};
	userScreen1: {
		startDescription: string;
		expiredDescription: string;
		invalidDescription: string;
	};
	userScreen2: {
		activateByPhone: string;
		mobileNumberPlaceholder: string;
		buttonTextStartValidation: string;
		mobileNotApprovedDescription: string;
	};
	userScreen3: {
		sendSmsCodeDescription: string;
		resendSmsCodeDescription: string;
	};
	userScreen4: {
		checkYourSms: string;
		clickAndRetry: string;
		noSMS: string;
	};
	userScreen5: {
		typeyourInfo: string;
		firstNamePlaceholder: string;
		lastNamePlaceholder: string;
		termsConditions: string;
		termsConditionsGDPR: string;
		startButtonText: string;
	};
	userScreen7: {
		passedCertificateInformation: string;
		congratulations: string;
		downloadDiploma: string;
		orderPrint: string;
		applyToLinkedIn: string;
		tryAgain: string;
		failedCertificateInformationMoreTries: string;
		failedCertificateInformationNoMoreTries: string;
		failedCertificateInformationNoMoreTriesStart: string;
		timeIsUp: string;
	};
}

interface QuizModulePage {
	imageNavigation: {
		answerYes: string;
		answerNo: string;
	};
	soundError: {
		descriptionText: string;
		allow: string;
		deny: string;
	};
	timeIsUpQuiz: string;
}

interface ErrorPage {
	error404: string;
	description: string;
	redirectInformation: string;
	redirectButtonText: string;
}
