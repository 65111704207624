import React, { FC } from "react";
import {
	BasicFormState,
	CertificateDetailsFormState,
	ClientAccount,
	QuizDetailsFormState,
	SettingsFormState,
} from "../../../models";
import CertificateConfigurationBasicForm from "./CertificateConfigurationBasicForm";
import CertificateConfigurationQuizDetailsForm from "./CertificateConfigurationQuizDetailsForm";
import CertificateConfigurationCertificateDetailsForm from "./CertificateConfigurationCertificateDetailsForm";
import CertificateConfigurationSettingsForm from "./CertificateConfigurationSettingsForm";
import "../styles/form/CertificateConfigurationFormSheet.scss";

export interface CertificateConfigurationFormSheetProps {
	clientAccounts: ClientAccount[];
	basicFormState: BasicFormState;
	setBasicFormState: (value: BasicFormState | ((prevState: BasicFormState) => BasicFormState)) => void;
	handlePreviewDiploma: () => void;
	quizDetailsFormState: QuizDetailsFormState;
	setQuizDetailsFormState: (
		value: QuizDetailsFormState | ((prevState: QuizDetailsFormState) => QuizDetailsFormState),
	) => void;
	certificateDetailsFormState: CertificateDetailsFormState;
	setCertificateDetailsFormState: (
		value: CertificateDetailsFormState | ((prevState: CertificateDetailsFormState) => CertificateDetailsFormState),
	) => void;
	settingsFormState: SettingsFormState;
	setSettingsFormState: (value: SettingsFormState | ((prevState: SettingsFormState) => SettingsFormState)) => void;
	isMobileView: boolean;
	formValidation: any;
	isSimple?: boolean;
}

/**
 * This component is responsible for displaying whole form in Certificate Configuration Page
 */
export const CertificateConfigurationFormSheet: FC<CertificateConfigurationFormSheetProps> = (
	props: CertificateConfigurationFormSheetProps,
) => {
	return (
		<>
			<div className="middle">
				<CertificateConfigurationBasicForm
					clientAccounts={props.clientAccounts}
					basicFormState={props.basicFormState}
					setBasicFormState={props.setBasicFormState}
					handlePreviewDiploma={props.handlePreviewDiploma}
					isMobileView={props.isMobileView}
					formValidation={props.formValidation}
					isSimple={props.isSimple}
				/>
				<CertificateConfigurationQuizDetailsForm
					quizDetailsFormState={props.quizDetailsFormState}
					setQuizDetailsFormState={props.setQuizDetailsFormState}
					formValidation={props.formValidation}
					isSimple={props.isSimple}
				/>
			</div>
			<div className="bottom">
				<CertificateConfigurationCertificateDetailsForm
					formValidation={props.formValidation}
					certificateDetailsFormState={props.certificateDetailsFormState}
					setCertificateDetailsFormState={props.setCertificateDetailsFormState}
				/>
				<CertificateConfigurationSettingsForm
					settingsFormState={props.settingsFormState}
					setSettingsFormState={props.setSettingsFormState}
					formValidation={props.formValidation}
				/>
			</div>
		</>
	);
};

export default CertificateConfigurationFormSheet;
