import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, Observable } from "@apollo/client";
import { onError } from "@apollo/link-error";
import React, { FC, useContext, useEffect, useState } from "react";
import { BrowserRouter, matchPath, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { fetchAccessToken } from "./apollo/apolloProviderOperations";
import AppStateContext from "./apollo/CustomApolloProvider";
import { APOLLO_CLIENT_URI } from "./constants/apolloConstants";
import { azureEnduserApi } from "./constants/azureConstants";
import { PATH_USER_SCREEN } from "./constants/pathConstants";
import Loading from "./pages/global/components/Loading";
import StatisticsPage from "./pages/statisticsPage/StatisticsPage";
import { StatisticsPageNoLogin } from "./pages/statisticsPageNoLogin/StatisticsPageNoLogin";
import EndUserRoutes from "./routes/EndUserRoutes";
import Routes from "./routes/Routes";
import { isEndUser } from "./services/utilFunctions";
import UserDiplomaPage from "./pages/userDiplomaPage/UserDiplomaPage";

export interface AppProps {}

const App: FC<AppProps> = () => {
	const location = useLocation();
	const [isEnd, setIsEnd] = useState<boolean>(isEndUser(location.pathname));

	//TODO IMPLEMENT SOME LOGIC THAT DECIDES IF IT IS THE ENDUSER

	console.log(isEnd);
	/*This is what happens if the end-user reaches our app */
	/* isEndUser evaluates if the path contains "/certificate" if this is true it goes down this road  */
	const cache = new InMemoryCache({});
	const requestLink = new ApolloLink(
		(operation, forward) =>
			new Observable((observer) => {
				let handle: any;
				Promise.resolve(operation)
					.then(() => {
						handle = forward(operation).subscribe({
							next: observer.next.bind(observer),
							error: observer.error.bind(observer),
							complete: observer.complete.bind(observer),
						});
					})
					.catch(observer.error.bind(observer));
				return () => {
					if (handle) handle.unsubscribe();
				};
			}),
	);

	const requestLinkSTATS = new ApolloLink(
		(operation, forward) =>
			new Observable((observer) => {
				let handle: any;
				Promise.resolve(operation)
					.then((operation) => {
						operation.setContext({
							headers: { authorization: null },
						});
					})
					.then(() => {
						handle = forward(operation).subscribe({
							next: observer.next.bind(observer),
							error: observer.error.bind(observer),
							complete: observer.complete.bind(observer),
						});
					})
					.catch(observer.error.bind(observer));
				return () => {
					if (handle) {
						handle.unsubscribe();
					}
				};
			}),
	);

	const EndUserClient = new ApolloClient({
		link: ApolloLink.from([
			onError(({ graphQLErrors, networkError }) => {
				if (graphQLErrors === undefined || graphQLErrors[0].path === undefined) return;
				if (graphQLErrors[0].path[0] === "refresh") return;
				const err = graphQLErrors[0].message;
				console.log(err);
			}),
			requestLink,
			new HttpLink({
				uri: azureEnduserApi,
			}),
		]),
		cache,
	});

	const NoLoginStatsClient = new ApolloClient({
		link: ApolloLink.from([
			onError(({ graphQLErrors, networkError }) => {
				if (graphQLErrors === undefined || graphQLErrors[0].path === undefined) return;
				if (graphQLErrors[0].path[0] === "refresh") return;
				const err = graphQLErrors[0].message;
				console.log(err);
			}),
			requestLinkSTATS,
			new HttpLink({
				uri: APOLLO_CLIENT_URI,
			}),
		]),
		cache,
	});

	return (
		<BrowserRouter>
			<Switch>
				<Route
					path="/:id([0-9]+)"
					render={() => (
						<ApolloProvider client={EndUserClient}>
							<EndUserRoutes />
						</ApolloProvider>
					)}
				/>
				<Route
					key="statisticsQuick"
					exact
					path={"/stat/:id([0-9]+)"}
					render={() => (
						<ApolloProvider client={NoLoginStatsClient}>
							<StatisticsPageNoLogin />
						</ApolloProvider>
					)}
				/>
				<Route
					key="diplomaDownload"
					exact
					path={"/diploma/:id([0-9]+)"}
					render={() => (
						<ApolloProvider client={NoLoginStatsClient}>
							<UserDiplomaPage />
						</ApolloProvider>
					)}
				/>
				<Route
					path="/"
					render={() => (
						<AppStateContext>
							<Routes />
						</AppStateContext>
					)}
				/>
			</Switch>
		</BrowserRouter>
	);
};

export default App;
