import {
	APOLLO_CLIENT_URI,
	APPLICATION_JSON,
	APPLICATION_JSON_CHARSET_UTF8,
	HTTP_METHOD_POST,
} from "../constants/apolloConstants";

export const fetchAccessToken = async (): Promise<Response> => {
	const payload = {
		variables: { old_token: localStorage.getItem("refreshToken") },
		query: `mutation($old_token: String!) {
		RefreshUser(old_token: $old_token) {
			accessToken
			refreshToken
		}
	}`,
	};
	return fetch(APOLLO_CLIENT_URI, {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			authorization: "null",
			"Content-Type": "application/json; charset=utf-8",
			Accept: "application/json",
		},
	}).then(async (res) => {
		const response = await res.json();
		console.log(payload);
		console.log(response);
		console.log(response.data.RefreshUser);
		return response.data.RefreshUser;
	});
};
