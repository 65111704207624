import React, { FC } from "react";
import "./styles/CertificateConfigurationSelectBox.scss";

export interface CertificateConfigurationSelectBoxProps {
	name?: string;
	labelText?: string;
	hasSemicolonAfterLabel?: boolean;
	placeholder: string | number;
	optionsItems: JSX.Element | JSX.Element[];
	value: number;
	handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const CertificateConfigurationSelectBox: FC<CertificateConfigurationSelectBoxProps> = (
	props: CertificateConfigurationSelectBoxProps,
) => {
	const label = props.labelText ? (
		<label>
			{props.labelText}
			{props.hasSemicolonAfterLabel ? ": " : ""}
		</label>
	) : null;

	return (
		<>
			{label}
			<select name={props.name} value={props.value} onChange={props.handleChange}>
				<option value="0">{props.placeholder}</option>
				{props.optionsItems}
			</select>
		</>
	);
};

export default CertificateConfigurationSelectBox;
