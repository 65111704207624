import { Button, Checkbox } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
	BasicFormState,
	CertificateDetailsFormState,
	DashboardTableRowWithDetails,
	DescriptionContentType,
	EnduserQuiz,
	EnduserStatement,
	QuizDetailsFormState,
	QuizSlideSimplified,
	QuizSlideSimplifiedTags,
	QuizSlideStatementSimplified,
	QuizState,
	SettingsFormState,
} from "../models";
import AddIcon from "@mui/icons-material/Add";
import { SimpleSlide } from "./simpleComponents/SimpleSlide";
import "./simpleComponents/CertificateConfigurationSimple.scss";
import {
	Slide,
	Statement,
	Tag,
	useAddLogoCertificateMutation,
	useCertPublishMutation,
	useGetAvailableTagsLazyQuery,
	useGetCertificateQuery,
	useGetContentCertificateQuery,
	useGetLanguagesQuery,
	useManageCertificateMutation,
	useManageContentMutation,
} from "../../graphql/generated/graphql";
import { convertStringToValue } from "../../services/utilFunctions";
import { BlobServiceClient } from "@azure/storage-blob";
import { AppStateContext } from "../../apollo/CustomApolloProvider";
import CertificateConfigurationFormSheet from "./components/form/CertificateConfigurationFormSheet";
import usePowerform from "powerform-react";
import { getStringsForChosenLanguage, getChosenLanguage } from "../../translations/translationProvider";
import EasyPiecyLogo from "../global/components/EasyPiecyLogo";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useHistory, useLocation } from "react-router-dom";
import { PATH_DASHBOARD } from "../../constants/pathConstants";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { findIndexById } from "../modelFunctions";
import Loading from "../global/components/Loading";
import QuizModulePage from "../quizModulePage/QuizModulePage";
import { Clear } from "@material-ui/icons";

interface stateType {
	from: { pathname: string };
	shouldLoad?: boolean;
	isEdit: boolean;
	dashboardTableRow: DashboardTableRowWithDetails;
}

export interface ICertificateConfigurationSimpleProps {}

const CertificateConfigurationSimple: React.FC<ICertificateConfigurationSimpleProps> = ({}) => {
	const location = useLocation<stateType>();
	const languageString = getStringsForChosenLanguage(getChosenLanguage());
	const { appGetUserID } = useContext(AppStateContext);

	const history = useHistory();

	const [quizState, setquizState] = useState<QuizSlideSimplified[]>();

	const [firstTime, setfirstTime] = useState(location.state ? false : true);

	const [previewActive, setpreviewActive] = useState(false);

	//USESTATE FOR CONTROLLING IF ADVANCED SETTINGS IS ON OR OFF
	const [isAdvanced, setisAdvanced] = useState(false);

	const [originalOwner, setoriginalOwner] = useState(
		location.state
			? location.state.dashboardTableRow.original_owner
				? location.state.dashboardTableRow.original_owner
				: undefined
			: undefined,
	);

	const [shouldLoadContent, setshouldLoadContent] = useState(
		location.state ? (location.state.shouldLoad !== undefined ? location.state.shouldLoad : true) : true
	);

	const [certID, setcertID] = useState(
		location.state ? (location.state.dashboardTableRow.id ? location.state.dashboardTableRow.id : 0) : 0,
	);

	//THIS USESTATE IS USED FOR STORING ALREADY EXISITNG TAGS ON A CERTIFICATE
	const [existingTags, setexistingTags] = useState<Tag[]>();

	const [userID, setuserID] = useState<number>();

	//We create a usestate that conations error message if it happens
	const [error, seterror] = useState<string>();

	//USESTATE FOR tracking loading progress
	const [loadingProgress, setloadingProgress] = useState<any>();

	//useState for tracking if we are uploading the cert
	const [isLoadingFinish, setisLoadingFinish] = useState(false);

	//SECTION WITH FORM VALIDATION
	//THIS RESPONSIBLE FOR CHECKING THE VALUES WE ARE STORING AND IF THEY ARE CORRECT

	const checkForErrors = () => {
		let errorCheck = false;
		if (quizState) {
			quizState.forEach((slide) => {
				//WE START WITH CHECKING IF THE SLIDE HAS THE NOQUESTIONS, THEN WE DONT CHECK STATEMENTS ALSO CHECKS IF MEDIA IS HERE
				if (slide.noQuestions && slide.media) {
				} else if (slide.media === undefined) {
					//INSIDE HERE WE GO IF THE MEDIA IS UNDEFINED. BECAUSE THEN THE SLIDE IS JUST MADE, BUT IT DOESNT CONTAIN MEDIA, THATS WHY IT IS NOT VALID
					setquizState((quizState) => {
						if (quizState && slide) {
							const currentSlideIndex = findIndexById(quizState, slide.id);
							const copy = [...quizState];
							if (copy[currentSlideIndex]) {
								copy[currentSlideIndex].hasError = true;
							}
							return copy;
						}
						return quizState;
					});
					errorCheck = true;
				} else {
					//INSIDE HERE THE SLIDE IS SUPPOSED TO HAVE STATEMENTS AND WE CHECK THE STATEMENTS
					slide.statements?.forEach((statement) => {
						//IF THE ANSWER HAS NOT BEEN SET AND THE STATEMENT STRING HAS A LENGTH UNDER 0
						if (statement.correctAnswer === -1 || statement.text.length < 0) {
							setquizState((quizState) => {
								if (quizState && slide) {
									const currentSlideIndex = findIndexById(quizState, slide.id);
									const copy = [...quizState];
									if (copy[currentSlideIndex]) {
										copy[currentSlideIndex].hasError = true;
									}
									return copy;
								}
								return quizState;
							});
							errorCheck = true;
						}
					});
				}
			});
		}
		return errorCheck;
	};

	//SECTION WITH FORM VALIDATION  --- END

	const [manageCertificate] = useManageCertificateMutation();
	const [manageContent] = useManageContentMutation();
	const [certPublish] = useCertPublishMutation();
	const [addLogo] = useAddLogoCertificateMutation();

	const { data: languageData } = useGetLanguagesQuery();
	const [getTags, { data: tagsData, loading: tagsLoading }] = useGetAvailableTagsLazyQuery({});

	const { data: certificate } = useGetCertificateQuery({ variables: { certificate_id: certID }, skip: firstTime });
	const { data: content } = useGetContentCertificateQuery({ variables: { certificate_id: certID }, skip: firstTime });

	const [firstLoad, setfirstLoad] = useState(true);

	const [languageOptions, setlanguageOptions] = useState<JSX.Element[]>([
		<option value={-1} key={-1}>
			Loading
		</option>,
	]);

	const handleOnDragEnd = (result: DropResult) => {
		const { source, destination } = result;

		// Check if the draggable item was dropped outside a valid drop target
		if (!destination) {
			return;
		}

		// Reorder the items in the quizState based on the drag and drop result
		if (quizState) {
			const updatedSlides = Array.from(quizState);
			const [draggedSlide] = updatedSlides.splice(source.index, 1);
			updatedSlides.splice(destination.index, 0, draggedSlide);
			// Update the quizState with the reordered slides
			setquizState(updatedSlides);
		}
	};

	//THIS IS USED FOR RENDERING THE ADVANCED EDIT OF FORM
	function required(value) {
		if (!value) {
			return languageString.formvalidationMessages.required;
		}
	}
	function atleastOne(value) {
		if (value.length < 2) {
			return languageString.formvalidationMessages.atleastOne;
		}
	}
	function cannotBeOlder(value) {
		const valueDate = new Date(value);
		const dateToCompare = new Date();

		if (valueDate <= dateToCompare) {
			return languageString.formvalidationMessages.cannotBeOlder;
		}
	}
	function atleastOneTag(value) {
		const array = value.split(",");
		if (array.length < 1) {
			return languageString.formvalidationMessages.atleastOneTag;
		} else if (value === null) {
			return languageString.formvalidationMessages.atleastOneTag;
		}
	}

	function tagsNotDuplicate(value) {
		const array = value.split(",");
		const set = new Set(array);
		console.log(set);
		if (set.size < array.length) {
			return languageString.formvalidationMessages.noDuplicateTags;
		}
	}

	function maxErrorsNotZero(value) {
		const error = value;
		if (error === " " || error < "0") {
			return languageString.formvalidationMessages.errorCannotBeZero;
		}
	}

	const schema = {
		basicNameInput: [required, atleastOne],
		individualMaxSlideErrors: [required, maxErrorsNotZero],
		tags: [required, atleastOneTag, tagsNotDuplicate],
		expiryDate: [cannotBeOlder],
	};
	const form = usePowerform(schema);
	//END OF ADVANCED FORM CHECK

	useEffect(() => {
		if (languageData?.getLanguages) {
			const certificationLanguageOptionsTemp = languageData.getLanguages.map((language) => {
				return (
					<option key={language.language_id} value={language.language_id}>
						{language.language_name}
					</option>
				);
			});
			setlanguageOptions(certificationLanguageOptionsTemp);
		}
	}, [languageData]);

	//USE EFFECT USED FOR IF WE ARE EDITING THE CERTIFICATE
	useEffect(() => {
		if (certificate?.getCertificate) {
			const certData = certificate.getCertificate;
			setuserID(certData.user_id);
			setBasicFormState({
				certificateName: certData.cert_name,
				certificateInternalRef: certData.cert_internal_name,
				content: certData.cert_content_desc,
				contentType: DescriptionContentType.PlainText,
				chosenClientAccount: { id: 0, name: "" },
			});
			setQuizDetailsFormState({
				isShufflingSlides: certData.cert_shuffle_slides,
				isShufflingStatements: certData.cert_shuffle_statements,
				amountOfFixedSlides: certData.cert_fixed_slides === -1 ? "" : certData.cert_fixed_slides,
				isAllowedToDownloadDiploma: certData.cert_download_allowed,
				maxAllowedTries: certData.cert_max_tries,
				isIndividualMaxSlideErrors: certData.cert_individual_slide_max_error,
				minutesAndSeconds: certData.cert_time_to_complete,
				tags: "",
				shouldAutoGen: true,
			});
			setCertificateDetailsFormState({
				optionalValueName: certData.cert_optional_value,
				minAmountOfCharacterInOptionalValueName:
					certData.cert_optional_min === -1 ? "" : certData.cert_optional_min,
				maxAmountOfCharacterInOptionalValueName:
					certData.cert_optional_max === -1 ? "" : certData.cert_optional_max,
				chosenDateFormat: 3,
				chosenCertificateHeadline: certData.cert_headline_id,
				chosenCertificateLanguage: certData.ep_cert_language_id,
				uploadedLogo: certData.cert_custom_logo,
				logoColor: certData.cert_custom_logo_color ? certData.cert_custom_logo_color : "#010000",
				uploadedLogoFile: undefined,
				customMessage: certData.cert_sms_text ? certData.cert_sms_text : "",
				skipSMS: certData.cert_sms_skip,
			});
			setSettingsFormState({
				maxUses: String(certData.cert_max_uses),
				expireDate: certData.cert_expiry_date ? new Date(certData.cert_expiry_date) : new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
				isOnlySelectedUsers: certData.cert_select_users,
				listOfMobileNumbers: "",
				refreshAllowed: certData.cert_refresh,
				refreshDays: certData.cert_refresh_days ? certData.cert_refresh_days : ""
			});
			setexistingTags(certData.cert_tags);

			getLogo(certData.cert_custom_logo, certData.original_owner !== 0 ? certData.original_owner : certData.user_id).then((data) => {
				if(data){
					setCertificateDetailsFormState((prevState) => {
						return { ...prevState, uploadedLogoFile: new File([data], "logo") };
					});
				}
			})
		}
	}, [certificate]);


	//Method for getting logo
	const getLogo = async (blobName: string, userID: number) => {
		try{

			const blobServiceClient = new BlobServiceClient(
				`https://easypiecystorageaccountp.blob.core.windows.net?sv=2021-04-10&ss=btqf&srt=sco&st=2023-06-07T12%3A06%3A36Z&se=2035-01-08T14%3A00%3A00Z&sp=rwl&sig=XdFJC6WLZCq6NIKMsINP%2B4BSMWBUakq3zYHVJZVJwMU%3D`,
		);
		const containerClient = blobServiceClient.getContainerClient(
			`user${userID}`,
			);
			const blobClient = containerClient.getBlobClient(blobName);
			const blobResponse = await blobClient.download(undefined, undefined, {
			});
			return blobResponse.blobBody;
		}catch(e){
			
		}
	};

	//FOR EDITING WE ALSO NEED TO GET ALL THE CONTENT THAT BELONGS TO THE CERTIFICATE
	useEffect(() => {
		if (shouldLoadContent) {
			const setContent = async () => {
				try {
					if (content?.getContentCertificate && userID) {
						console.log("do i run in here");

						const getBlob = async (blobName: string, index: number, total: number) => {
							const blobServiceClient = new BlobServiceClient(
								`https://easypiecystorageaccountp.blob.core.windows.net?sv=2021-04-10&ss=btqf&srt=sco&st=2023-06-07T12%3A06%3A36Z&se=2035-01-08T14%3A00%3A00Z&sp=rwl&sig=XdFJC6WLZCq6NIKMsINP%2B4BSMWBUakq3zYHVJZVJwMU%3D`,
							);
							const containerClient = blobServiceClient.getContainerClient(
								`user${originalOwner ? originalOwner : userID}`,
							);
							const blobClient = containerClient.getBlobClient(blobName);
							const size = await blobClient.getProperties().then((data) => {
								return data.contentLength;
							});
							const blobResponse = await blobClient.download(undefined, undefined, {
								onProgress: (data) => {
									if (size) {
										const name = "Slide " + index + " " + "of " + total;
										const progress = (data.loadedBytes / size) * 100;
										setloadingProgress({ progress: progress, name: name });
									}
								},
							});
							return blobResponse.blobBody;
						};

						const defineVideo = (imgBlob: Blob): boolean => {
							if (imgBlob?.type.includes("video")) {
								return true;
							} else {
								return false;
							}
						};

						const contentSlides = content.getContentCertificate[0].slides;
						const tempSlides: QuizSlideSimplified[] = [];

						//We need to call .entries() as we have to destructure the array so we can get the index to the loader
						for (const [i, slide] of contentSlides.entries()) {
							console.log(slide.media_location.slice(4));
							
							const tempStatements: QuizSlideStatementSimplified[] = [];
							const imgBlob = slide.media_location.slice(0,4) === "LINK" ? slide.media_location :  await getBlob(slide.media_location, i + 1, contentSlides.length);
							slide.statements.forEach((statement, index) => {
								tempStatements.push({
									API_ID: statement.statement_id,
									API_SOUND_ID: statement.statement_Sound[0].statement_sound_id,
									id: index,
									correctAnswer: statement.statement_answer_id,
									text: statement.statement_Sound[0].statement_narration,
								});
							});

							tempSlides.push({
								id: slide.slide_id,
								introductionQuestion: slide.intro_question,
								slideWeight: slide.slide_weight,
								media: slide.media_location,
								statements: tempStatements,
								actualMedia: imgBlob
									? new File([imgBlob], slide.media_location, { type: imgBlob.type })
									: undefined,
								isVideo: slide.media_location.slice(0,4) === "LINK"  ? true : false,
								tags: slide.tags[0] ? slide.tags[0].tag_name : undefined,
								noQuestions: slide.statements.length === 0,
							});
						}

						setquizState(tempSlides);
					}
				} catch (e) {
					seterror(String(e));
				}
			};

			setContent();
			
		}
	}, [content, userID, shouldLoadContent]);

	//UseEffect for updating the maxErrors when a new slide is added
	useEffect(() => {
		if (quizState) {
			if(firstLoad){
				setfirstLoad(false)
			}else{

				console.log(Math.round(0.2 * quizState.length));
				if(Math.round(0.2 * quizState.length) < 1){
					setQuizDetailsFormState((prevState) => {
					return { ...prevState, isIndividualMaxSlideErrors: 1 };
				});
			}else{
				setQuizDetailsFormState((prevState) => {
					return { ...prevState, isIndividualMaxSlideErrors: Math.round(0.2 * quizState.length) };
				});
			}
		}
		}
	}, [quizState?.length]);

	useEffect(() => {
		if (firstTime) {
			handleAddSlide();
		}
	}, []);

	const [basicFormState, setBasicFormState] = useState<BasicFormState>({
		certificateName: "",
		certificateInternalRef: "",
		content: "",
		contentType: DescriptionContentType.PlainText,
		chosenClientAccount: { id: 0, name: "" },
	});

	const [quizDetailsFormState, setQuizDetailsFormState] = useState<QuizDetailsFormState>({
		isShufflingSlides: false,
		isShufflingStatements: false,
		amountOfFixedSlides: "",
		isAllowedToDownloadDiploma: false,
		maxAllowedTries: "10",
		isIndividualMaxSlideErrors: "5",
		minutesAndSeconds: "01:00:00",
		tags: "",
		shouldAutoGen: true,
	});

	const [certificateDetailsFormState, setCertificateDetailsFormState] = useState<CertificateDetailsFormState>({
		optionalValueName: "",
		minAmountOfCharacterInOptionalValueName: "",
		maxAmountOfCharacterInOptionalValueName: "",
		chosenDateFormat: 3,
		chosenCertificateHeadline: 5,
		chosenCertificateLanguage: 0,
		uploadedLogo: "",
		logoColor: "#010000",
		uploadedLogoFile: undefined,
		customMessage: "",
		skipSMS: true,
	});

	const [settingsFormState, setSettingsFormState] = useState<SettingsFormState>({
		maxUses: "",
		expireDate: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
		isOnlySelectedUsers: false,
		listOfMobileNumbers: "",
		refreshAllowed: false,
	});

	const handleChangeCertname = (newValue: string) => {
		setBasicFormState((prevState) => ({ ...prevState, certificateName: newValue }));
	};

	const handleChangeInternalCertname = (newValue: string) => {
		setBasicFormState((prevState) => ({ ...prevState, certificateInternalRef: newValue }));
	};

	const handleChangeLanguage = (newValue: number) => {
		setCertificateDetailsFormState((prevState) => ({ ...prevState, chosenCertificateLanguage: newValue }));
	};

	const handleChangeMaxuses = (newValue: number) => {
		setSettingsFormState((prevState) => ({ ...prevState, maxUses: String(newValue) }));
	};

	const handleAddSlide = () => {
		const copy = quizState ? [...quizState] : [];
		const newSlide = { id: copy.length };
		copy.push(newSlide);
		setquizState(copy);
	};

	const handleDeleteSlide = (index: number) => {
		if (quizState) {
			const copy = quizState ? [...quizState] : [];
			const [removed] = copy.splice(index, 1);
			setquizState(copy);
		}
	};

	const addSlideButton = (
		<div className="addButton">
			<Button onClick={handleAddSlide}>
				<AddIcon />
			</Button>
		</div>
	);

	const handleUserGuide = (): void => {
		const win = window.open("https://b2b.easypiecy.com/USERGUIDE.pdf", "_blank")!;
		win.focus();
	};

	//METHOD FOR CONVERTING CURRENT QUIZSTATE TO A STATE THAT CAN BE USED IN THE PREVIEW
	const convertToPreview = () => {
		const tempPreviewState: EnduserQuiz = { indexPlayer: new Audio(), soundPlayer: new Audio(), slides: [] };
		quizState?.forEach((slide) => {
			const tempPreviewStatement: EnduserStatement[] = [];
			if (slide.noQuestions) {
			} else {
				slide.statements?.forEach((statement) => {
					tempPreviewStatement.push({
						soundMedia: "",
						soundText: statement.text,
						correctAnswer: statement.correctAnswer,
						answer: 0,
						indexSound: "",
						id: statement.id,
					});
				});
			}
			if (slide.actualMedia) {
				tempPreviewState.slides.push({
					introQuestion: slide.introductionQuestion ? slide.introductionQuestion : "",
					introQuestionSound: "",
					media: slide.isVideo
						? slide.media ? slide.media : ""
						: slide.actualMedia,
					statements: tempPreviewStatement,
					id: slide.id,
				});
			}
		});

		return tempPreviewState;
	};

	const handleFinishSetup = (publishNow?: boolean) => {
		if (!checkForErrors()) {
			setloadingProgress(undefined);
			setisLoadingFinish(true);
			//THE FOLLOWING TWO METHODS ARE FOR WHEN WE UPLOAD A SLIDES. THIS IS NEEDED IF WE HAVE TO UPLOAD THE SLIDES TO THE STORAGE CONTAINER
			const uploadSlideMedia = async (
				slideMedia: File,
				certificateID: number,
				identifier: number,
				alreadyExists: boolean,
				totalSize: number,
			) => {
				const blobServiceClient = new BlobServiceClient(
					`https://easypiecystorageaccountp.blob.core.windows.net?sv=2021-04-10&ss=btqf&srt=sco&st=2023-06-07T12%3A06%3A36Z&se=2035-01-08T14%3A00%3A00Z&sp=rwl&sig=XdFJC6WLZCq6NIKMsINP%2B4BSMWBUakq3zYHVJZVJwMU%3D`,
				);
				const containerClient = blobServiceClient.getContainerClient(`user${appGetUserID()}`);
				const content = slideMedia;
				let totalLoadedBytes = 0;
				const blobName = alreadyExists
					? slideMedia.name
					: `certificate${certificateID}/slide_${identifier}_` + slideMedia.name;
				const blockBlobClient = containerClient.getBlockBlobClient(blobName);
				await blockBlobClient.upload(content, content.size, {
					blobHTTPHeaders: { blobContentType: slideMedia.type },
					onProgress: (data) => {
						const name = "Uploading Slides";
						totalLoadedBytes = totalLoadedBytes + data.loadedBytes;
						console.log("this is total loaded bytes " + totalLoadedBytes);

						const progress = (data.loadedBytes / totalSize) * 100;
						setloadingProgress({ progress: progress, name: name });
					},
				});

				return blobName;
			};

			const uploadCertificateLogo = async (
				logo: File,
				certificateID: number,
			) => {
				const blobServiceClient = new BlobServiceClient(
					`https://easypiecystorageaccountp.blob.core.windows.net?sv=2021-04-10&ss=btqf&srt=sco&st=2023-06-07T12%3A06%3A36Z&se=2035-01-08T14%3A00%3A00Z&sp=rwl&sig=XdFJC6WLZCq6NIKMsINP%2B4BSMWBUakq3zYHVJZVJwMU%3D`,
				);
				const containerClient = blobServiceClient.getContainerClient(`user${appGetUserID()}`);
				const content = logo;
				const blobName = `certificate${certificateID}/custom_logo`;
				const blockBlobClient = containerClient.getBlockBlobClient(blobName);
				await blockBlobClient.upload(content, content.size, {
					blobHTTPHeaders: { blobContentType: logo.type },
				});

				return blobName;
			};

			const tempTags: QuizSlideSimplifiedTags[] = [];
			const tempTagsAPI: Tag[] = [];
			if (quizState) {
				quizState.forEach((slide, index) => {
					if (slide.tags) {
						tempTags.push({ ownedBy: index, name: slide.tags });

						const match = tempTagsAPI.find(
							(item) => item.tag_name!.toLowerCase() === slide.tags!.toLowerCase(),
						);
						if (match === undefined) {
							if (existingTags) {
								const matchToExisting = existingTags.find(
									(item) => item.tag_name!.toLowerCase() === slide.tags!.toLowerCase(),
								);
								if (matchToExisting === undefined) {
									tempTagsAPI.push({ tag_name: slide.tags });
								}
							} else {
								tempTagsAPI.push({ tag_name: slide.tags });
							}
						}
					}
				});
			}
			console.log(tempTags);

			manageCertificate({
				variables: {
					certificate_id: certID,
					cert_name: basicFormState.certificateName,
					cert_internal_name: basicFormState.certificateInternalRef,
					cert_content_desc: basicFormState.content,
					cert_optional_value: certificateDetailsFormState.optionalValueName,
					cert_date_format: "EU", //TODO ADD ENUM FROM DB
					cert_creation_date: new Date(),
					cert_is_draft: true, //TODO ADD REAL IMPL
					cert_time_to_complete: quizDetailsFormState.minutesAndSeconds,
					cert_expiry_date: settingsFormState.expireDate,
					cert_headline_id: certificateDetailsFormState.chosenCertificateHeadline,
					ep_cert_language_id: certificateDetailsFormState.chosenCertificateLanguage,
					cert_shuffle_slides: quizDetailsFormState.isShufflingSlides,
					cert_shuffle_statements: quizDetailsFormState.isShufflingStatements,
					cert_fixed_slides: convertStringToValue(quizDetailsFormState.amountOfFixedSlides),
					cert_download_allowed: quizDetailsFormState.isAllowedToDownloadDiploma,
					cert_max_tries: convertStringToValue(quizDetailsFormState.maxAllowedTries),
					cert_max_uses: convertStringToValue(settingsFormState.maxUses),
					cert_select_users: settingsFormState.isOnlySelectedUsers,
					cert_optional_min: convertStringToValue(
						certificateDetailsFormState.minAmountOfCharacterInOptionalValueName,
					),
					cert_optional_max: convertStringToValue(
						certificateDetailsFormState.maxAmountOfCharacterInOptionalValueName,
					),
					cert_cpc: basicFormState.chosenClientAccount.id === 0 ? -1 : basicFormState.chosenClientAccount.id,
					cert_phone_numbers: settingsFormState.listOfMobileNumbers,
					cert_individual_slide_max_error: convertStringToValue(
						quizDetailsFormState.isIndividualMaxSlideErrors,
					),
					cert_tags: tempTagsAPI,
					cert_sms_text: certificateDetailsFormState.skipSMS
						? null
						: certificateDetailsFormState.customMessage.length > 3
						? certificateDetailsFormState.customMessage
						: null,
					cert_sms_skip: certificateDetailsFormState.skipSMS,
					cert_refresh: settingsFormState.refreshAllowed,
					cert_refresh_days: settingsFormState.refreshAllowed ? settingsFormState.refreshDays : -1,
				},
			}).then((data) => {
				const certificateID = Number(data.data?.manageCertificate);
				if (shouldLoadContent) {
					getTags({ variables: { certificate_id: certificateID } }).then(async (data) => {
						const actualData = data.data?.getAvailableTags;
						if (actualData) {
							tempTags.forEach((tag) => {
								const match = actualData.find(
									(item) => item.tag_name.toLowerCase() === tag.name.toLowerCase(),
								);
								const getIndex = actualData.findIndex(
									(item) => item.tag_name.toLowerCase() === tag.name.toLowerCase(),
								);

								if (match && getIndex !== -1) {
									tag.id = match.tag_id;
								}
							});

							const slideArray: Slide[] = [];
							let totalSize = 0;
							await Promise.all(
								quizState!.map(async (slide, index) => {
									const statementArray: Statement[] = [];
									if (slide.noQuestions) {
									} else {
										if (slide.statements) {
											slide.statements.forEach((statement) => {
												statementArray.push({
													statement_answer_id: statement.correctAnswer,
													statement_sound: [
														{
															statement_narration: statement.text,
															statement_sound_id: statement.API_SOUND_ID,
														},
													],
													statement_id: statement.API_ID,
												});
											});
										}
									}
									totalSize = totalSize + (slide.actualMedia ? slide.actualMedia?.size : 0);
									console.log("this is the total size" + totalSize);

									const match = tempTags.find((item) => item.ownedBy === index);
									const slideMedia = slide.actualMedia
										? await uploadSlideMedia(
												slide.actualMedia,
												certificateID,
												index,
												slide.id < 21 ? false : true,
												totalSize,
										  )
										: null;
									if (slide.media) {
										slideArray[index] = {
											statements: statementArray,
											slide_weight: slide.slideWeight ? slide.slideWeight : 1,
											media_location: slide.isVideo ? slide.media.slice(0,4) === "LINK" ? slide.media : "LINK" + slide.media : slideMedia ? slideMedia : slide.media,
											intro_question: slide.introductionQuestion,
											tags: match ? [{ tag_id: match?.id }] : undefined,
										};
									}
								}),
							)
								.then(() => {
									if(certificateDetailsFormState.uploadedLogoFile){
										uploadCertificateLogo(certificateDetailsFormState.uploadedLogoFile, certificateID).then((data) => {
											addLogo({variables:{certificate_id : certificateID, new_logo_color: certificateDetailsFormState.logoColor, new_logo: data}})

										} )
									}else if(certificateDetailsFormState.logoColor && !certificateDetailsFormState.uploadedLogoFile){
										addLogo({variables:{certificate_id : certificateID, new_logo_color: certificateDetailsFormState.logoColor, new_logo: `certificate${certificateID}/custom_logo`}})
									}
									manageContent({
										variables: {
											new_content: {
												slides: slideArray,
												should_auto_gen: true,
												certificate_id: certificateID,
											},
										},
									});
								})
								.then(() => {
									if (publishNow) {
										certPublish({ variables: { certificate_id: certificateID } }).then(() => {
											history.push(PATH_DASHBOARD);
										});
									} else {
										history.push(PATH_DASHBOARD);
									}
								});
						}
					});
				} else {
					if (publishNow) {
						certPublish({ variables: { certificate_id: certificateID } }).then(() => {
							history.push(PATH_DASHBOARD);
						});
					} else {
						history.push(PATH_DASHBOARD);
					}
				}
			});
		}
	};

	const simpleViewSlides = quizState
		? quizState.map((slide, index) => {
				return (
					<Draggable key={slide.id} draggableId={String(slide.id)} index={index}>
						{(provided) => (
							<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
								<SimpleSlide
									key={findIndexById(quizState, slide.id)}
									setquizState={setquizState}
									slide={quizState[findIndexById(quizState, slide.id)]}
									handleDelete={() => handleDeleteSlide(findIndexById(quizState, slide.id))}
								/>
							</div>
						)}
					</Draggable>
				);
		  })
		: addSlideButton;

	const certificateformInput = (
		<div className="formInput">
			<div className="row">
				<div>
					<div>{languageString.certificateConfigurationPage.simpleForm.name}</div>
					<div className="input">
						<input
							value={basicFormState.certificateName}
							onChange={(event) => handleChangeCertname(event.target.value)}
						></input>
					</div>
				</div>
				<div>
					<div>{languageString.certificateConfigurationPage.simpleForm.internalRef}</div>
					<div className="input">
						<input
							value={basicFormState.certificateInternalRef}
							onChange={(event) => handleChangeInternalCertname(event.target.value)}
						></input>
					</div>
				</div>
			</div>
			<div className="row">
				<div>
					<div>{languageString.certificateConfigurationPage.simpleForm.language}</div>
					<div>
						<select
							value={certificateDetailsFormState.chosenCertificateLanguage}
							onChange={(event) => handleChangeLanguage(Number(event.target.value))}
						>
							<option value={0}></option>
							{languageOptions}
						</select>
					</div>
				</div>
				<div>
					<div>{languageString.certificateConfigurationPage.simpleForm.maxNumber}</div>
					<Checkbox checked={settingsFormState.maxUses === "-1" ? false : true} onChange={(event) => handleChangeMaxuses(settingsFormState.maxUses === "-1" ? Number(1) : Number(-1))}/>
					<div className="input">
						<input
							disabled={settingsFormState.maxUses === "-1"}
							value={settingsFormState.maxUses === "-1" ? " " : settingsFormState.maxUses}
							onChange={(event) => handleChangeMaxuses(Number(event.target.value))}
						></input>
					</div>
				</div>
			</div>
		</div>
	);

	const actionButtons = (
		<div className="actionButtons">
			<Button onClick={() => setpreviewActive(!previewActive)} disabled={quizState === undefined}>PREVIEW</Button>
			<Button onClick={() => handleFinishSetup() } disabled={shouldLoadContent === true && quizState === undefined}>SAVE DRAFT</Button>
			<Button onClick={() => handleFinishSetup(true) } disabled={shouldLoadContent === true && quizState === undefined}>PUBLISH NOW</Button>
		</div>
	);

	const advancedSettings = (
		<div className="advancedForm">
			<CertificateConfigurationFormSheet
				clientAccounts={[{ name: "", id: 0 }]}
				basicFormState={basicFormState}
				setBasicFormState={setBasicFormState}
				handlePreviewDiploma={() => {}}
				quizDetailsFormState={quizDetailsFormState}
				setQuizDetailsFormState={setQuizDetailsFormState}
				certificateDetailsFormState={certificateDetailsFormState}
				setCertificateDetailsFormState={setCertificateDetailsFormState}
				settingsFormState={settingsFormState}
				setSettingsFormState={setSettingsFormState}
				isMobileView={false}
				formValidation={form}
				isSimple
			/>
		</div>
	);

	const upperSection = (
		<div className="upperSection">
			<div className="left">
				<Button onClick={() => history.push(PATH_DASHBOARD)}>BACK TO DASHBOARD</Button>
			</div>
			<div className="right">
				<div className="advancedSettingButton" onClick={() => setisAdvanced(!isAdvanced)}>
					{languageString.certificateConfigurationPage.simpleForm.advanced}
				</div>
			</div>
		</div>
	);

	const logoandUserGuide = (
		<div className="logoAndUserGuide">
			<EasyPiecyLogo />
			<Button onClick={handleUserGuide} className="newTopButton">
				<QuestionMarkIcon />
				<div>{languageString.dashboardPage.iconButton.userGuide}</div>
			</Button>
		</div>
	);

	return (
		<div className="CertificateConfigurationSimple">
			{isLoadingFinish ? (
				<div className="certLoadingFinish">
					<Loading progress={loadingProgress} />
				</div>
			) : null}
			{previewActive ? (
				<div className="preview">
					<div className="previewClose">
						<Button onClick={() => setpreviewActive(false)}>
							<Clear />
						</Button>
					</div>
					<QuizModulePage isPreview passedQuizState={convertToPreview()} />
				</div>
			) : null}
			{logoandUserGuide}

			{upperSection}
			{certificateformInput}
			{isAdvanced ? advancedSettings : null}
			{error ? (
				<div className="editError">{error}</div>
			) : !shouldLoadContent ? <Button variant="contained" onClick={() => setshouldLoadContent(true)} className="shouldLoadButton">Load Content</Button> : !firstTime && !quizState ? (
				<div className="certLoading">
					<Loading progress={loadingProgress} />
				</div>
			) : quizState ? (
				<DragDropContext onDragEnd={handleOnDragEnd}>
					<Droppable droppableId="actualSlides">
						{(provided) => (
							<ul className="actualSlides" ref={provided.innerRef}>
								{simpleViewSlides}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
				</DragDropContext>
			) : null}
			{quizState ? (quizState.length < 20 ? addSlideButton : null) : null}
			{actionButtons}
		</div>
	);
};

export { CertificateConfigurationSimple };
