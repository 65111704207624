import { BaseType, DescriptionContentType } from "./models";

/**
 * Function return item based on passed ID
 */
export function findById<T1 extends BaseType>(items: T1[], itemId: number | null): T1 | undefined {
	return items.find((tag) => tag.id === itemId);
}

/**
 * Function return index of item based on passed ID
 */
export function findIndexById<T1 extends BaseType>(items: T1[], itemId: number | null): number {
	return items.findIndex((tag) => tag.id === itemId);
}

/**
 * Function removes item whose ID was given
 */
export function filterById<T1 extends BaseType>(items: T1[], itemIdToDelete: number): T1[] {
	return items.filter((item) => item.id !== itemIdToDelete);
}

/**
 * Function do numeric sort of array
 */
export function sortById<T1 extends BaseType>(items: T1[]): T1[] {
	return items.sort((first, second) => first.id - second.id);
}

/**
 * Function removes item whose ID was given and then do numeric sort of array
 */
export function filterAndSortById<T1 extends BaseType>(items: T1[], itemIdToDelete: number): T1[] {
	return sortById(filterById(items, itemIdToDelete));
}

export const descriptionContentTypeToBooleanConverter = (descriptionContentType: DescriptionContentType): boolean => {
	switch (descriptionContentType) {
		case DescriptionContentType.HTML: {
			return true;
		}
		case DescriptionContentType.PlainText: {
			return false;
		}
	}
};

export const booleanToDescriptionContentTypeConverter = (flag: boolean): DescriptionContentType => {
	if (flag) {
		return DescriptionContentType.HTML;
	}
	return DescriptionContentType.PlainText;
};
