import React, { FC } from "react";
import { getChosenLanguage, getStringsForChosenLanguage } from "../../../../translations/translationProvider";
import { BasicFormState, ClientAccount } from "../../../models";
import {
	booleanToDescriptionContentTypeConverter,
	descriptionContentTypeToBooleanConverter,
	findById,
} from "../../../modelFunctions";
import ToggleSwitch from "../../../global/components/ToggleSwitch";
import CertificateConfigurationSelectBox from "../CertificateConfigurationSelectBox";
import CertificateConfigurationInput, { InputType } from "../CertificateConfigurationInput";
import CertificateConfigurationTextArea from "../CertificateConfigurationTextArea";
import "../styles/form/CertificateConfigurationBasicForm.scss";

export interface CertificateConfigurationBasicFormProps {
	clientAccounts: ClientAccount[];
	basicFormState: BasicFormState;
	setBasicFormState: (value: BasicFormState | ((prevState: BasicFormState) => BasicFormState)) => void;
	handlePreviewDiploma: () => void;
	isMobileView: boolean;
	formValidation: any;
	isSimple?: boolean;
}

/**
 * This is component which displays form responsible for getting data like certificateName or description
 * It is placed in left top corner of form
 */
export const CertificateConfigurationBasicForm: FC<CertificateConfigurationBasicFormProps> = (
	props: CertificateConfigurationBasicFormProps,
) => {
	const languageString = getStringsForChosenLanguage(getChosenLanguage());

	const handleChangeCertificateName = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.formValidation.basicNameInput.setData(event.target.value);
		props.formValidation.basicNameInput.validate(props.basicFormState.certificateName);
		props.setBasicFormState((prevState) => {
			return { ...prevState, certificateName: event.target.value };
		});
	};

	const handleChangeCertificateInternalName = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setBasicFormState((prevState) => {
			return { ...prevState, certificateInternalRef: event.target.value };
		});
	};

	const handleChangeClientSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const chosenId: number = parseInt(event.target.value);
		const chosenClient = findById(props.clientAccounts, chosenId);
		if (chosenClient) {
			props.setBasicFormState((prevState) => {
				return { ...prevState, chosenClientAccount: chosenClient };
			});
		}
	};

	const handleChangeDescriptionContentType = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setBasicFormState((prevState) => {
			return { ...prevState, contentType: booleanToDescriptionContentTypeConverter(event.target.checked) };
		});
	};

	const handleChangeDescriptionOfContents = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
		props.setBasicFormState((prevState) => {
			return { ...prevState, content: event.target.value };
		});
	};

	const certificateNameInput = (
		<>
			<div className="basicInput">
				<CertificateConfigurationInput
					name="basicNameInput"
					isFaded={false}
					labelText={languageString.certificateConfigurationPage.form.formSheet.basicForm.certificateName}
					hasSemicolonAfterLabel={true}
					inputType={InputType.Text}
					placeholder={
						languageString.certificateConfigurationPage.form.formSheet.basicForm
							.certificateNameInputPlaceholder
					}
					value={props.basicFormState.certificateName}
					handleChange={handleChangeCertificateName}
					isRequired={true}
				/>
			</div>
			<div className="basicInput">
				<CertificateConfigurationInput
					name="basicNameInput"
					isFaded={false}
					labelText={
						languageString.certificateConfigurationPage.form.formSheet.basicForm.certificateInternalName
					}
					hasSemicolonAfterLabel={true}
					inputType={InputType.Text}
					placeholder={
						languageString.certificateConfigurationPage.form.formSheet.basicForm
							.certificateInternalNameInputPlaceholder
					}
					value={props.basicFormState.certificateInternalRef}
					handleChange={handleChangeCertificateInternalName}
					isRequired={false}
				/>
			</div>
		</>
	);

	const clientSelect = (): JSX.Element => {
		const clientAccountsOptions = props.clientAccounts.map((account, index) => {
			return (
				<option key={index} value={account.id}>
					{account.name}
				</option>
			);
		});

		return (
			<>
				<CertificateConfigurationSelectBox
					name="basicSelect"
					labelText={languageString.certificateConfigurationPage.form.formSheet.basicForm.clientAttached}
					hasSemicolonAfterLabel={true}
					placeholder={
						languageString.certificateConfigurationPage.form.formSheet.basicForm.clientSelectDefaultValue
					}
					optionsItems={clientAccountsOptions}
					value={props.basicFormState.chosenClientAccount.id}
					handleChange={handleChangeClientSelect}
				/>
			</>
		);
	};

	const descriptionOfContentsTextArea = (
		<>
			<div>
				<label>
					{`${languageString.certificateConfigurationPage.form.formSheet.basicForm.descriptionOfContents}:`}
				</label>
				{/* <div className="HTMLorText"> //TODO NOT FULLY IMPLEMENTED AS IT REQUIRES QUITE A COMPLEX OPERATION
					<ToggleSwitch
						textBefore={
							languageString.certificateConfigurationPage.form.formSheet.basicForm.plainTextToggleSwitch
						}
						textAfter={
							languageString.certificateConfigurationPage.form.formSheet.basicForm.htmlMarkupToggleSwitch
						}
						isChecked={descriptionContentTypeToBooleanConverter(props.basicFormState.contentType)}
						handleChange={handleChangeDescriptionContentType}
					/>
				</div> */}
			</div>
			<CertificateConfigurationTextArea
				isFaded={false}
				placeholder={
					languageString.certificateConfigurationPage.form.formSheet.basicForm
						.descriptionOfContentsTextAreaPlaceholder
				}
				rows={10}
				cols={100}
				value={props.basicFormState.content}
				handleChange={handleChangeDescriptionOfContents}
				characterLimit={2000}
			/>
		</>
	);

	const previewDiploma = (
		<div onClick={props.handlePreviewDiploma} className="clickable">
			{languageString.certificateConfigurationPage.form.formSheet.basicForm.previewDiploma}
		</div>
	);

	return (
		<div className="basicForm">
			{props.isSimple ? null : <><div className="inputNames">{certificateNameInput}</div>
			<p className="error title">{props.formValidation.basicNameInput.getError()}</p></>}
			{/* <div className="basicSelect">{clientSelect()}</div> */}
			<div className="basicDescription">{descriptionOfContentsTextArea}</div>
			{/* <div className="basicPreview">{previewDiploma}</div> */}
		</div>
	);
};

export default CertificateConfigurationBasicForm;
