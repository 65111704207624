import React, { FC, useEffect, useState } from "react";
import {
	CertificateDetailsFormState,
	CertificateHeadlineType,
	CertificateLanguageType,
	DateFormatType,
} from "../../../models";
import {
	convertCertificateHeadlineTypeToString,
	convertCertificateLanguageTypeToString,
	convertDateFormatTypeToString,
} from "../../../../translations/translationEnumConverter";
import {
	getIntegerValuesFromEnum,
	getNumberOrEmptyStringFromEventTarget,
	parseHtmlSelectValueToEnum,
} from "../../../../services/utilFunctions";
import { getChosenLanguage, getStringsForChosenLanguage } from "../../../../translations/translationProvider";
import CertificateConfigurationInput, { InputType } from "../CertificateConfigurationInput";
import { useGetHeadlinesQuery, useGetLanguagesQuery } from "../../../../graphql/generated/graphql";
import CertificateConfigurationSelectBox from "../CertificateConfigurationSelectBox";
import "../styles/form/CertificateConfigurationCertificateDetailsForm.scss";
import CertificateConfigurationTextArea from "../CertificateConfigurationTextArea";
import CertificateConfigurationCheckBox from "../CertificateConfigurationCheckBox";
import { CertificateConfigurationColorPicker } from "./CertificateConfigurationColorPicker";
import { PopoverPicker } from "./PopoverPicker";

export interface CertificateConfigurationCertificateDetailsFormProps {
	certificateDetailsFormState: CertificateDetailsFormState;
	setCertificateDetailsFormState: (
		value: CertificateDetailsFormState | ((prevState: CertificateDetailsFormState) => CertificateDetailsFormState),
	) => void;
	formValidation: any;
}

/**
 * This is component which displays form responsible for getting data like logo, language, data format
 * It is placed in left bottom corner of form
 */
export const CertificateConfigurationCertificateDetailsForm: FC<CertificateConfigurationCertificateDetailsFormProps> = (
	props: CertificateConfigurationCertificateDetailsFormProps,
) => {
	const languageString = getStringsForChosenLanguage(getChosenLanguage());
	const languagesQuery = useGetLanguagesQuery();
	const headlinesQuery = useGetHeadlinesQuery({
		variables: { id: props.certificateDetailsFormState.chosenCertificateLanguage },
	});
	const [certificationHeadlineOptions, setCertificationHeadlineOptions] = useState<JSX.Element | JSX.Element[]>(
		<option> ERROR </option>,
	);
	const [certificationLanguageOptions, setCertificationLanguageOptions] = useState<JSX.Element | JSX.Element[]>(
		<option> ERROR </option>,
	);

	const handleChangeOptionalValueName = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setCertificateDetailsFormState((prevState) => {
			return { ...prevState, optionalValueName: event.target.value };
		});
	};

	const handleChangeMinAmountOfCharacters = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setCertificateDetailsFormState((prevState) => {
			return {
				...prevState,
				minAmountOfCharacterInOptionalValueName: getNumberOrEmptyStringFromEventTarget(event.target),
			};
		});
	};

	const handleChangeMaxAmountOfCharacters = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setCertificateDetailsFormState((prevState) => {
			return {
				...prevState,
				maxAmountOfCharacterInOptionalValueName: getNumberOrEmptyStringFromEventTarget(event.target),
			};
		});
	};

	const handleChangeDateFormat = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		props.setCertificateDetailsFormState((prevState) => {
			return { ...prevState, chosenDateFormat: parseHtmlSelectValueToEnum(event.target) };
		});
	};

	const handleChangeCertificationHeadline = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		props.setCertificateDetailsFormState((prevState) => {
			return { ...prevState, chosenCertificateHeadline: parseHtmlSelectValueToEnum(event.target) };
		});
	};

	const handleChangeCertificationLanguage = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		props.setCertificateDetailsFormState((prevState) => {
			return { ...prevState, chosenCertificateLanguage: parseHtmlSelectValueToEnum(event.target) };
		});
	};
	const handleChangeCustomMessage = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
		props.setCertificateDetailsFormState((prevState) => {
			return { ...prevState, customMessage: event.target.value };
		});
	};

	useEffect(() => {
		const certificationLanguageOptionsTemp = languagesQuery.data?.getLanguages.map((language) => {
			return (
				<option key={language.language_id} value={language.language_id}>
					{language.language_name}
				</option>
			);
		});
		const certificationHeadlineOptionsTemp = headlinesQuery.data?.getHeadlines.map((headline, index) => {
			return (
				<option key={headline.headline_id} value={index + 1}>
					{headline.headline_name}
				</option>
			);
		});
		setCertificationHeadlineOptions(certificationHeadlineOptionsTemp);
		setCertificationLanguageOptions(certificationLanguageOptionsTemp);
	}, [languagesQuery.data, headlinesQuery.data]);

	const handleChangeUploadLogo = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const fileData = event.target.files?.[0];
		
		if (fileData) {
			props.setCertificateDetailsFormState((prevState) => {
				return { ...prevState, uploadedLogo: URL.createObjectURL(fileData), uploadedLogoFile: fileData };
			});
		}
	};

	const handleChangeLogoColor = (string): void => {
		
		props.setCertificateDetailsFormState((prevState) => {
			return { ...prevState, logoColor: string };
		});
	};

	const handleChangeSkipSMS = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setCertificateDetailsFormState((prevState) => {
			return { ...prevState, skipSMS: event.target.checked };
		});
	};

	const optionalValueNameInput = (
		<div className="basicInput">
			<CertificateConfigurationInput
				isFaded={false}
				labelText={
					languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm.optionalValueName
				}
				hasSemicolonAfterLabel={true}
				inputType={InputType.Text}
				placeholder={
					languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
						.optionalValueNamePlaceholder
				}
				value={props.certificateDetailsFormState.optionalValueName}
				handleChange={handleChangeOptionalValueName}
			/>
		</div>
	);

	const amountOfCharactersInOptionalValueNameInputs = (
		<div className="basicInput">
			<CertificateConfigurationInput
				isFaded={false}
				labelText={
					languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
						.amountOfCharactersInOptionalValue
				}
				hasSemicolonAfterLabel={false}
				inputType={InputType.Number}
				placeholder={
					languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
						.amountOfCharactersInOptionalValueMinPlaceholder
				}
				value={props.certificateDetailsFormState.minAmountOfCharacterInOptionalValueName}
				handleChange={handleChangeMinAmountOfCharacters}
			/>

			<CertificateConfigurationInput
				isFaded={false}
				labelText={""}
				hasSemicolonAfterLabel={false}
				inputType={InputType.Number}
				placeholder={
					languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
						.amountOfCharactersInOptionalValueMaxPlaceholder
				}
				value={props.certificateDetailsFormState.maxAmountOfCharacterInOptionalValueName}
				handleChange={handleChangeMaxAmountOfCharacters}
			/>
		</div>
	);

	const dateFormatSelectBox = (): JSX.Element => {
		const dateFormatOptions = getIntegerValuesFromEnum(DateFormatType).map((date, index) => {
			return (
				<option key={index} value={date}>
					{convertDateFormatTypeToString(date, languageString)}
				</option>
			);
		});
		//THIS IS A TEMPORARY FOR ONLY DISPLAYING EU
		const dateFormatOptionsTemp = <option value={3}>{convertDateFormatTypeToString(3, languageString)}</option>;

		return (
			<div className="basicSelect">
				<CertificateConfigurationSelectBox
					labelText={
						languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
							.certificationDateFormat
					}
					hasSemicolonAfterLabel={true}
					placeholder={
						languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
							.certificationDateFormatPlaceholder
					}
					optionsItems={dateFormatOptionsTemp}
					value={props.certificateDetailsFormState.chosenDateFormat}
					handleChange={handleChangeDateFormat}
				/>
			</div>
		);
	};

	const certificationHeadlineSelectBox = (): JSX.Element => {
		return (
			<div
				className={
					props.certificateDetailsFormState.chosenCertificateHeadlineError
						? "basicSelect customError"
						: "basicSelect"
				}
			>
				<CertificateConfigurationSelectBox
					labelText={
						languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
							.certificationHeadline
					}
					hasSemicolonAfterLabel={true}
					placeholder={
						languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
							.certificationHeadlinePlaceholder
					}
					optionsItems={certificationHeadlineOptions}
					value={props.certificateDetailsFormState.chosenCertificateHeadline}
					handleChange={handleChangeCertificationHeadline}
				/>
			</div>
		);
	};

	const certificationLanguageSelectBox = (): JSX.Element => {
		return (
			<div
				className={
					props.certificateDetailsFormState.chosenCertificateLanguageError
						? "basicSelect customError"
						: "basicSelect"
				}
			>
				<CertificateConfigurationSelectBox
					labelText={
						languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
							.certificationLanguage
					}
					hasSemicolonAfterLabel={true}
					placeholder={
						languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
							.certificationLanguagePlaceholder
					}
					optionsItems={certificationLanguageOptions}
					value={props.certificateDetailsFormState.chosenCertificateLanguage}
					handleChange={handleChangeCertificationLanguage}
				/>
			</div>
		);
	};

	const uploadLogoInput = (
		<div className="logoUpload">
			<label>
				{languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm.uploadLogo}
				{": "}
			</label>
			<label htmlFor="logoInput">
				<span className="logoInputButton">
					{
						languageString.certificateConfigurationPage.form.formSheet.certificateDetailsForm
							.uploadLogoButtonText
					}
				</span>
				<input
					id="logoInput"
					className="fileInputHidden"
					type="file"
					accept="image/*"
					onChange={handleChangeUploadLogo}
				/>
			</label>
			<PopoverPicker color={props.certificateDetailsFormState.logoColor} onChange={handleChangeLogoColor}/>
		</div>
	);

	const customMessage = (
		<div className={props.certificateDetailsFormState.skipSMS ? "customMessage disabled" : "customMessage"}>
			<CertificateConfigurationTextArea
				isFaded={false}
				labelText={""}
				hasSemicolonAfterLabel={false}
				placeholder={languageString.certificateConfigurationPage.form.formSheet.settingsForm.customMessage}
				rows={3}
				cols={5}
				value={props.certificateDetailsFormState.customMessage}
				handleChange={handleChangeCustomMessage}
				characterLimit={160}
			/>
		</div>
	);
	const skipSMS = (
		<div className="skipSMS">
			<CertificateConfigurationCheckBox
				isFaded={false}
				labelText={"Skip SMS? "}
				isChecked={props.certificateDetailsFormState.skipSMS}
				handleChange={handleChangeSkipSMS}
			/>
		</div>
	);

	return (
		<div className="basicForm">
			{/* {optionalValueNameInput}
			{amountOfCharactersInOptionalValueNameInputs} */}
			{/* {dateFormatSelectBox()} */}
			{certificationLanguageSelectBox()}
			{certificationHeadlineSelectBox()}
			{uploadLogoInput}
			{/* <div className="SMS">
				{customMessage}
				{skipSMS}
			</div> */}
		</div>
	);
};

export default CertificateConfigurationCertificateDetailsForm;
