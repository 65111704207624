import React, { FC } from "react";
import UserScreenFlipPhoneIcon from "./icons/UserScreenFlipPhoneIcon";
import "./styles/UserScreen6.scss";

export interface UserScreen6Props {}

/**
 * UserScreenRotateDevice
 * This user screen prompt the user to rotate the screen
 */
export const UserScreen6: FC<UserScreen6Props> = (props: UserScreen6Props) => {
	return (
		<div className="userScreen6">
			<UserScreenFlipPhoneIcon />
		</div>
	);
};

export default UserScreen6;
