import React, { FC } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDeleteCertificateMutation } from "../../../graphql/generated/graphql";
import { getChosenLanguage, getStringsForChosenLanguage, setLanguage } from "../../../translations/translationProvider";
import { LanguageType, Translation } from "../../../translations/translationTypes";
import { findIndexById } from "../../modelFunctions";
import { DashboardTableRowWithDetails } from "../../models";
import "./styles/TimeIsUpBox.scss";

export interface TimeIsUpBoxProps {
	endQuiz: any;
	languageString: Translation;
}

export const TimeIsUpBox: FC<TimeIsUpBoxProps> = (props: TimeIsUpBoxProps) => {
	const languageStrings = props.languageString;

	const okayClick = async () => {
		props.endQuiz();
	};

	const buttons = (
		<div className="TimeIsUpBox">
			<div className="description">{languageStrings.quizModulePage.timeIsUpQuiz}</div>
			<div className="buttonDiv">
				<button onClick={okayClick}>{"OK"}</button>
			</div>
		</div>
	);
	return <>{<div className="TimeIsUpBox-BG">{buttons}</div>}</>;
};

export default TimeIsUpBox;
