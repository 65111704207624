import { Checkbox } from "@material-ui/core";
import React, { FC } from "react";
import "./styles/CertificateConfigurationCheckBox.scss";

export interface CertificateConfigurationCheckBoxProps {
	isFaded: boolean;
	labelText: string;
	isChecked: boolean;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CertificateConfigurationCheckBox: FC<CertificateConfigurationCheckBoxProps> = (
	props: CertificateConfigurationCheckBoxProps,
) => {
	return (
		<div className={props.isFaded ? "fadedStyle CHECKBOX" : "CHECKBOX"}>
			<div className="label">{props.labelText}</div>
			<Checkbox checked={props.isChecked} onChange={props.handleChange} className={"checkboxInput"}/>
		</div>
	);
};

export default CertificateConfigurationCheckBox;
