import { Tag } from "../graphql/generated/graphql";

export interface BaseType {
	id: number;
}

/* Dashboard Page */
export enum CertificateStatusType {
	Active = 1,
	EditDraft = 2,
	Expired = 3,
}

export enum ActiveInactiveType {
	On = 1,
	Off = 2,
}
export enum TestStatus {
	"Pending" = 2,
	"Passed" = 1,
	"Failed" = 0,
}

export enum VideoType {
    YOUTUBE = "Youtube",
    STREAM = "Stream",
    LOCALVIDEO = "LocalVideo",
    AZURE = "Azure"
}

export interface DashboardTableRow extends BaseType {
	date: Date;
	name: string;
	internalRef: string;
	attachedTo: {
		name: string;
		id: number;
	};
	language: string;
	expireDate: Date;
	optional: string;
	status: boolean;
	downloadAllowed: boolean;
}

export interface DashboardTableRowWithDetails extends DashboardTableRow {
	isExpanded: boolean;
	certificationDetailsText: string;
	shuffleQuestions: boolean;
	shuffleSlides: boolean;
	maxSlides: number | string;
	maxAttempts: number | string;
	maxCertifications: number;
	dateTimeFormat: string;
	showDiploma: ActiveInactiveType;
	certificateLink: string;
	fixedSlides: number | string;
	timeToComplete: string;
	optionalMax: number | string;
	optionalMin: number | string;
	headLineID: number;
	languageID: number;
	logoPath: string;
	logoColor: string;
	maxUses: number | string;
	onlySelected: boolean;
	mobileNumbers: string;
	individualMaxSlidesErrors: number;
	tags: SlideTag[];
	SMStext?: string;
	SkipSMS?: boolean;
	refreshAllowed?: boolean;
	refreshDays?: number;
	original_owner?: number;
	currentUses?: number;
}

export interface DashboardTableRowSlidePreviewProps {
	id: string; // The ID of the certificate for which we want to fetch the slides.
}

/* Statistics Page */
export interface StatisticsTableRowData extends BaseType {
	isMarked: boolean;
	date: string;
	firstName: string;
	lastName: string;
	attempts: number;
	optional: string;
	status: TestStatus;
	time: string;
	diploma: string;
	invoiced: boolean;
	failedTags: string;
	dateToSend?: string;
}
/* Statistics page noLogin */
export interface EnduserStats extends BaseType {
	firstName: string;
	lastName: string;
	timeTaken?: string;
	timeEnded?: Date;
	maxReached: boolean;
	attempts: number;
	passed: boolean;
	diplomaPassed: boolean;
	errorsInTag?: number;
}

export interface certTag extends BaseType{
    name: string;
    tag_fails: number;
}

export interface EnduserStatsExtended extends EnduserStats {
	dateToSend?: Date;
	invoiced: boolean;
	phoneNumber: string;
}

export interface StatisticsToCSV extends BaseType {
	date: string;
	firstName: string;
	lastName: string;
	attempts: number;
	optional: string;
	status: string;
	time: string;
	invoiced: string;
	failedTags: string;
}

/* Certificate Configuration Page */
export interface ClientAccount extends BaseType {
	name: string;
}

export interface ContentProvider extends BaseType {
	name: string;
}
export interface CertificateOptions extends BaseType {
	name: string;
}

export interface ClientFormState {
	companyName: string;
	email: string;
	optionalValue: string;
}

export enum DescriptionContentType {
	PlainText,
	HTML,
}

export interface BasicFormState {
	certificateName: string;
	certificateInternalRef: string;
	content: string;
	contentType: DescriptionContentType;
	chosenClientAccount: ClientAccount;
}

export interface QuizDetailsFormState {
	isShufflingSlides: boolean;
	isShufflingStatements: boolean;
	amountOfFixedSlides: number | string;
	isAllowedToDownloadDiploma: boolean;
	maxAllowedTries: number | string;
	isIndividualMaxSlideErrors: number | string;
	minutesAndSeconds: string;
	tags: string;
	shouldAutoGen: boolean;
}

export enum DateFormatType {
	UK = 1,
	US = 2,
	EU = 3,
}

export enum CertificateHeadlineType {
	OnlineCourse = 1,
	Certificate = 2,
	Diploma = 3 && 5,
	ProofOfCompletion = 4,
}

export enum CertificateLanguageType {
	Danish = 1,
	English = 2,
	Polish = 3,
}

export interface CertificateDetailsFormState {
	optionalValueName: string;
	minAmountOfCharacterInOptionalValueName: number | string;
	maxAmountOfCharacterInOptionalValueName: number | string;
	chosenDateFormat: DateFormatType | number;
	chosenCertificateHeadline: CertificateHeadlineType | number;
	chosenCertificateHeadlineError?: boolean;
	chosenCertificateLanguage: CertificateLanguageType | number;
	chosenCertificateLanguageError?: boolean;
	uploadedLogo: string;
	uploadedLogoPath?: string;
	uploadedLogoFile?: File;
	logoColor: string;
	customMessage: string;
	skipSMS: boolean;
}

export interface SettingsFormState {
	maxUses: number | string;
	expireDate: Date;
	isOnlySelectedUsers: boolean;
	listOfMobileNumbers: string;
	listOfMobileNumbersError?: boolean;
	refreshAllowed: boolean;
	refreshDays?: number;
}

export interface QuizState {
	slides: QuizSlide[];
}

export interface QuizSlide extends BaseType {
	selectedTags: SlideTag[];
	introductionQuestion: string | undefined;
	introMedia: string | File | undefined;
	questions: SlideStatement[];
	thumbnail: string;
	media: string;
	isVideo: boolean;
	mediaFile: File | string;
	hasError?: boolean;
	hasIntroError?: boolean;
	slideWeight?: number;
}

export interface QuizSlideSimplified extends BaseType {
	tags?: string;
	introductionQuestion?: string | undefined;
	statements?: QuizSlideStatementSimplified[];
	media?: string;
	actualMedia?: File;
	isVideo?: boolean;
	slideWeight?: number;
	noQuestions?: boolean;
	//THIS VARIABLE IS USED IF TO MARK IF THE SLIDE HAS A ERROR
	hasError?: boolean;
}

export interface QuizSlideStatementSimplified extends BaseType {
	API_ID?: number;
	API_SOUND_ID?: number;
	correctAnswer: number;
	text: string;
}

export interface QuizSlideSimplifiedTags{
	ownedBy: number; //THIS VALUE WILL BE THE INDEX OF THE SLIDE THAT THE TAG BELONGS TO
	id?: number;
	name: string;
}

export interface SlideStatement extends BaseType {
	correctAnswer: QuestionAnswerType | number;
	note: string;
	narrationMediaElement: NarrationMediaElement;
	hasError?: boolean;
}

export interface SlideTag extends BaseType {
	name: string;
}
export interface slideTags {
	tag_name: string;
}

export enum QuestionAnswerType {
	Yes = 1,
	No = 2,
	Both = 3,
}

export interface NarrationMediaElement extends BaseType {
	mp3File: string;
	mp3FileName?: string;
	statement: string;
	file?: File;
}

export enum SoundSelectionType {
	None = 1,
	Add = 2,
	Search = 3,
}

export interface QuestionBoxState extends BaseType {
	soundSelection: SoundSelectionType;
	isBoxFolded: boolean;
	filteredNarrationMediaElements: NarrationMediaElement[];
	searchBoxContent: string;
}

/* User Screen */
export enum UserScreenCertificationStep {
	Intro = 0,
	First = 1,
	Second = 2,
	Third = 3,
	Fourth = 4,
	Fifth = 5,
	Sixth = 6,
	Seventh = 7,
}

export interface UserScreen5State {
	userFirstName: string;
	userLastName: string;
	userOptionalValue: string;
	isTermsAccepted: boolean;
	isGDPRTermsAccepted: boolean;
}

/* Quiz Module Page */
export enum AnswerButtonType {
	True = 1,
	False = 2,
	Video = 3,
	VideoPlaying = 4,
	TenBack = 5,
	VideoEnd = 6,
}

/* Client Management Page */
export interface ClientManagementTableRowState extends BaseType {
	isMarked: boolean;
	businessName: string;
	note: string;
}

//end user parts
export interface RandomSlide {
	slide_Id: number;
	slide_Definition: string;
	content_Id: number;
	intro_Question: string;
	certificate_Id: number;
	quiz_Slide_No: number;
	process_status: boolean;
}

export interface StatementQuestion {
	statementDefinition: string;
	statementNarration: string;
	answer: string;
	intro_Question: string;
}
export interface EnduserQuiz {
	isReadOnly?: boolean;
	slides: EnduserSlide[];
	indexPlayer?: HTMLAudioElement;
	soundPlayer?: HTMLAudioElement;
}
export interface EnduserSlide extends BaseType {
	introQuestion: string;
	introQuestionSound: string;
	media: Blob | string;
	passed?: boolean;
	statements: EnduserStatement[];
}
export interface EnduserStatement extends BaseType {
	soundMedia: string;
	soundText: string;
	passed?: boolean;
	correctAnswer: number;
	answer: number;
	indexSound: string;
}

export interface EnduserCertificate extends BaseType {
	name: string;
	logo: string;
	expiryDate: Date;
	individualMaxErrors: number;
	timeToComplete: string;
	optionalValue: string;
	optionalMax: number;
	optionalMin: number;
	languageID: number;
	headlineID: number;
	bgColor: string;
}
