import React, { FC } from "react";
import usePowerform from "powerform-react";
import { getChosenLanguage, getStringsForChosenLanguage } from "../../../../translations/translationProvider";
import { QuizDetailsFormState } from "../../../models";
import { getNumberOrEmptyStringFromEventTarget } from "../../../../services/utilFunctions";
import CertificateConfigurationInput, { InputType } from "../CertificateConfigurationInput";
import CertificateConfigurationCheckBox from "../CertificateConfigurationCheckBox";
import "../styles/form/CertificateConfigurationQuizDetailsForm.scss";
import { Switch } from "@mui/material";

export interface CertificateConfigurationQuizDetailsFormProps {
	quizDetailsFormState: QuizDetailsFormState;
	setQuizDetailsFormState: (
		value: QuizDetailsFormState | ((prevState: QuizDetailsFormState) => QuizDetailsFormState),
	) => void;
	formValidation: any;
	isSimple?: boolean;
}

/**
 * This is component which displays form responsible for getting quiz configuration like maxAllowedTries
 * It is placed in right top corner of form
 */
export const CertificateConfigurationQuizDetailsForm: FC<CertificateConfigurationQuizDetailsFormProps> = (
	props: CertificateConfigurationQuizDetailsFormProps,
) => {
	const languageString = getStringsForChosenLanguage(getChosenLanguage());

	const handleChangeShuffleSlides = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setQuizDetailsFormState((prevState) => {
			return { ...prevState, isShufflingSlides: event.target.checked };
		});
	};

	const handleChangeShuffleStatements = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setQuizDetailsFormState((prevState) => {
			return { ...prevState, isShufflingStatements: event.target.checked };
		});
	};

	const handleChangeAutoGen = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setQuizDetailsFormState((prevState) => {
			return { ...prevState, shouldAutoGen: event.target.checked };
		});
	}

	/**
	 * Function before assigning value check if value in input can be parsed to number. If so then assign
	 * number if not assign empty string because in input type="number" can be passed number of empty
	 * string when input is empty
	 */
	const handleChangeFixedAmountOfSlides = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setQuizDetailsFormState((prevState) => {
			return { ...prevState, amountOfFixedSlides: getNumberOrEmptyStringFromEventTarget(event.target) };
		});
	};

	const handleChangeAllowDownloadDiploma = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setQuizDetailsFormState((prevState) => {
			return { ...prevState, isAllowedToDownloadDiploma: event.target.checked };
		});
	};

	/**
	 * Function before assigning value check if value in input can be parsed to number. If so then assign
	 * number if not assign empty string because in input type="number" can be passed number of empty
	 * string when input is empty
	 */
	const handleChangeMaxAllowedTries = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setQuizDetailsFormState((prevState) => {
			return { ...prevState, maxAllowedTries: getNumberOrEmptyStringFromEventTarget(event.target) };
		});
	};

	const handleChangeIndividualMaxSlideErrors = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.formValidation.individualMaxSlideErrors.setData(event.target.value);
		props.formValidation.individualMaxSlideErrors.validate(props.quizDetailsFormState.isIndividualMaxSlideErrors);
		props.setQuizDetailsFormState((prevState) => {
			return { ...prevState, isIndividualMaxSlideErrors: event.target.value };
		});
	};

	const handleChangeTime = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.setQuizDetailsFormState((prevState) => {
			return { ...prevState, minutesAndSeconds: event.target.value };
		});
	};

	const handleChangeTags = (event: React.ChangeEvent<HTMLInputElement>): void => {
		props.formValidation.tags.setData(event.target.value);
		props.formValidation.tags.validate(props.quizDetailsFormState.isIndividualMaxSlideErrors);
		props.setQuizDetailsFormState((prevState) => {
			return { ...prevState, tags: event.target.value };
		});
	};

	const shuffleSlidesCheckBox = (
		<div>
			<CertificateConfigurationCheckBox
				isFaded={false}
				labelText={languageString.certificateConfigurationPage.form.formSheet.quizDetailsForm.shuffleSlides}
				isChecked={props.quizDetailsFormState.isShufflingSlides}
				handleChange={handleChangeShuffleSlides}
			/>
		</div>
	);

	const shuffleStatementsCheckBox = (
		<div>
			<CertificateConfigurationCheckBox
				isFaded={false}
				labelText={languageString.certificateConfigurationPage.form.formSheet.quizDetailsForm.shuffleStatements}
				isChecked={props.quizDetailsFormState.isShufflingStatements}
				handleChange={handleChangeShuffleStatements}
			/>
		</div>
	);

	const fixedAmountOfSlidesInput = (
		<div>
			<CertificateConfigurationInput
				isFaded={false}
				labelText={languageString.certificateConfigurationPage.form.formSheet.quizDetailsForm.fixedAmountSlides}
				hasSemicolonAfterLabel={false}
				inputType={InputType.Number}
				placeholder={""}
				value={props.quizDetailsFormState.amountOfFixedSlides}
				handleChange={handleChangeFixedAmountOfSlides}
			/>
		</div>
	);

	const allowDownloadDiplomaCheckBox = (
		<div>
			<CertificateConfigurationCheckBox
				isFaded={false}
				labelText={languageString.certificateConfigurationPage.form.formSheet.quizDetailsForm.allowDownload}
				isChecked={props.quizDetailsFormState.isAllowedToDownloadDiploma}
				handleChange={handleChangeAllowDownloadDiploma}
			/>
		</div>
	);

	const maxAllowedTriesInput = (
		<div>
			<CertificateConfigurationInput
				isFaded={false}
				labelText={languageString.certificateConfigurationPage.form.formSheet.quizDetailsForm.maxAllowedTries}
				hasSemicolonAfterLabel={false}
				inputType={InputType.Number}
				placeholder={""}
				value={props.quizDetailsFormState.maxAllowedTries}
				handleChange={handleChangeMaxAllowedTries}
			/>
		</div>
	);

	const individualMaxSlideErrorsCheckBox = (
		<div>
			<CertificateConfigurationInput
				isFaded={false}
				labelText={
					languageString.certificateConfigurationPage.form.formSheet.quizDetailsForm.individualMaxSlideErrors
				}
				hasSemicolonAfterLabel={false}
				inputType={InputType.Number}
				placeholder={""}
				value={props.quizDetailsFormState.isIndividualMaxSlideErrors}
				handleChange={handleChangeIndividualMaxSlideErrors}
			/>
		</div>
	);
	const timeInput = (
		<div>
			<CertificateConfigurationInput
				isFaded={false}
				step={"1"}
				labelText={languageString.certificateConfigurationPage.form.formSheet.quizDetailsForm.timeInputLabel}
				hasSemicolonAfterLabel={false}
				inputType={InputType.Time}
				placeholder={""}
				value={props.quizDetailsFormState.minutesAndSeconds}
				handleChange={handleChangeTime}
			/>
		</div>
	);

	const shouldAutoGenInput = (
		<div>
			<div className="inputConfiguration">

			<label>{languageString.certificateConfigurationPage.form.formSheet.quizDetailsForm.autogen}</label>
			  <Switch
      checked={props.quizDetailsFormState.shouldAutoGen}
      onChange={handleChangeAutoGen}
	  />
	  </div>
	
		</div>
	)

	const tagsInput = (
		<div className="tagsInput">
			<CertificateConfigurationInput
				isFaded={false}
				labelText={""}
				hasSemicolonAfterLabel={false}
				inputType={InputType.Text}
				placeholder={
					languageString.certificateConfigurationPage.form.formSheet.quizDetailsForm.tagsInputPlaceholder
				}
				value={props.quizDetailsFormState.tags}
				handleChange={handleChangeTags}
			/>
		</div>
	);

	return (
		<div className="formDetails">
			{shuffleSlidesCheckBox}
			{shuffleStatementsCheckBox}
			{fixedAmountOfSlidesInput}
			{allowDownloadDiplomaCheckBox}
			{maxAllowedTriesInput}
			{individualMaxSlideErrorsCheckBox}
			{/* <p className="error">{props.formValidation.individualMaxSlideErrors.getError()}</p> */}
			{timeInput}
			{props.isSimple ? null : shouldAutoGenInput}
			{props.isSimple ? null : tagsInput}
			{/* <p className="error">{props.formValidation.tags.getError()}</p> */}
		</div>
	);
};

export default CertificateConfigurationQuizDetailsForm;
