import { LinearProgress } from '@material-ui/core';
import React from 'react';

export type ILoadingBarProps = {
    key: number
    progress: number;
    total: number;
    name: number;
}

const LoadingBar: React.FC<ILoadingBarProps> = (props: ILoadingBarProps) => {
    return (
        <div className="loadingBar">
            <LinearProgress variant="determinate" value={props.progress} />
           <span>{props.name}</span>
        </div>
    );
}

export { LoadingBar };