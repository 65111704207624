/* eslint-disable react/display-name */
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import "./styles/QuestionNavButton.scss";
import miniAudio from "../../../assets/miniAudio.mp3";
import { Translation } from "../../../translations/translationTypes";

interface QuestionNavButtonProps {
	id: number;
	isPreview?: boolean;
	buttonText: string;
	indexSound: string;
	sound: string;
	onClickFunction: () => void;
	selectedStatement: number | undefined;
	setSelectedStatement: (value: number | ((prevState: number) => number)) => void;
	indexPlayer: HTMLAudioElement;
	soundPlayer: HTMLAudioElement;
	chosenLanguage: Translation;
}

export const QuestionNavButton: React.FC<QuestionNavButtonProps> = (props: QuestionNavButtonProps) => {
	const { buttonText, onClickFunction } = props;
	const [isActive, setIsActive] = useState<boolean>(false);
	const sound = props.indexPlayer;
	const indexSound = props.soundPlayer;
	const [isSoundError, setIsSoundError] = useState(false);

	const handleSoundErrorClick = (): void => {
		sound.play();
		sound.onended = () => {
			if (props.selectedStatement) {
				if (props.selectedStatement === 100) {
					props.setSelectedStatement(0);
				}
			}
		};
		indexSound.src = miniAudio;
		indexSound.play();
		props.setSelectedStatement(100);
		setIsSoundError(false);
	};

	useEffect(() => {
		if (props.selectedStatement !== props.id) {
			if (props.selectedStatement !== 100) {
				sound.pause();
			}
			indexSound.currentTime = 0;

			sound.currentTime = 0;
			setIsActive(false);
		}
		return () => {
			sound.remove();
			indexSound.remove();
			indexSound.pause();
		};
	}, [props.selectedStatement]);

	const handleClick = (e: MouseEvent): void => {
		e.preventDefault();
		onClickFunction();
	};

	useEffect(() => {
		if (props.selectedStatement === props.id) {
			setIsActive(true);
			if (props.selectedStatement === 100) {
				sound.src = props.sound;
				const soundPromise = sound.play();
				soundPromise.catch((_error) => {
					console.log("autoplay not allowed");
					console.log(_error);

					setIsSoundError(true);
				});
			} else {
				indexSound.src = props.indexSound;
				indexSound.play();
				indexSound.onended = () => {
					sound.src = props.sound;
					sound.play();
				};
			}
			sound.onended = () => {
				if (props.selectedStatement === 100) {
					props.setSelectedStatement(0);
				}
			};
		}
	}, [props.selectedStatement]);

	const soundError = (
		<div className="soundError">
			<div className="soundErrorBox">
				<div className="text">
					<span>{props.chosenLanguage.quizModulePage.soundError.descriptionText}</span>
				</div>

				<div className="buttons">
					<button className="soundErrorNO">{props.chosenLanguage.quizModulePage.soundError.deny}</button>
					<button className="soundErrorYES" onClick={handleSoundErrorClick}>
						{props.chosenLanguage.quizModulePage.soundError.allow}
					</button>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<div
				onClick={handleClick}
				className={`question-nav-button ${isActive ? "question-nav-button--active" : ""}`}
			>
				<span className="question-nav-button__text">{buttonText}</span>
			</div>
			{props.isPreview ? null : isSoundError ? soundError : null}
		</>
	);
};

export default QuestionNavButton;
