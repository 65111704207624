import React, { createContext, ReactNode, useState } from "react";
import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	fromPromise,
	HttpLink,
	InMemoryCache,
	Observable,
} from "@apollo/client";
import { onError } from "@apollo/link-error";
import { APOLLO_CLIENT_URI, BEARER } from "../constants/apolloConstants";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import JwtDecode, { JwtPayload } from "jwt-decode";
import jwtDecode from "jwt-decode";
import { fetchAccessToken } from "./apolloProviderOperations";

interface AppState {
	appState: {
		loggedIn: boolean;
	};
	gqlError: {
		msg: string;
	};
	appSetLogin: (token: string, token2: string) => void;
	appSetLogout: () => void;
	appSetAccessToken: (token: string, token2: string) => void;
	appClearAccessToken: () => void;
	appGetUserID: () => string;
	appGetUserRole: () => string;
}
let accessToken = "";
let refreshToken = "";
let userID = "";
let userRole = "";

const initialAppState: AppState = {
	appState: { loggedIn: false },
	gqlError: { msg: "" },
	appSetLogin: (token: string, token2: string) => {},
	appSetLogout: () => {},
	appSetAccessToken: (token: string) => {},
	appClearAccessToken: () => {},
	appGetUserID: () => {
		return userID;
	},
	appGetUserRole: () => {
		return userRole;
	},
};

export const AppStateContext = createContext<AppState>(initialAppState);

export interface AppStateProviderProps {
	children: ReactNode;
}

function AppStateProvider(props: AppStateProviderProps): JSX.Element {
	const [appState, setAppState] = useState({ loggedIn: false });
	const [gqlError, setGQLError] = useState({ msg: "" });

	const appSetLogin = (token: string, token2: string) => {
		accessToken = token;
		refreshToken = token2;
		localStorage.setItem("refreshToken", refreshToken);
		setAppState({ ...appState, loggedIn: true });
	};

	const appSetLogout = () => {
		localStorage.setItem("refreshToken", "");
		accessToken = "";
		setAppState({ ...appState, loggedIn: false });
	};

	const appSetAccessToken = (token: string, token2: string) => {
		console.log(token);
		accessToken = token;
		refreshToken = token2;
	};
	const appClearAccessToken = () => {
		accessToken = "";
	};
	const appGetAccessToken = (): string => {
		return accessToken;
	};
	const appGetUserID = (): string => {
		const decodedToken: any = JwtDecode(appGetAccessToken());

		userID = decodedToken.token_data.user_id;
		return userID;
	};

	const appGetUserRole = (): string => {
		const decodedToken: any = JwtDecode(appGetAccessToken());

		userRole = decodedToken.token_data.role_id;
		return userRole;
	};

	// apollo client
	const cache = new InMemoryCache({});
	const requestLink = new ApolloLink(
		(operation, forward) =>
			new Observable((observer) => {
				let handle: any;
				Promise.resolve(operation)
					.then((operation) => {
						operation.setContext({
							headers: { authorization: accessToken ? appGetAccessToken() : null },
						});
					})
					.then(() => {
						handle = forward(operation).subscribe({
							next: observer.next.bind(observer),
							error: observer.error.bind(observer),
							complete: observer.complete.bind(observer),
						});
					})
					.catch(observer.error.bind(observer));
				return () => {
					if (handle) {
						handle.unsubscribe();
					}
				};
			}),
	);

	const client = new ApolloClient({
		link: ApolloLink.from([
			new TokenRefreshLink<{ accessToken; refreshToken }>({
				accessTokenField: "accessToken",
				isTokenValidOrUndefined: () => {
					const token = appGetAccessToken();
					if (token?.length === 0) {
						return true;
					}

					try {
						//TODO Find a better solution to this
						const interMediaryFix: JwtPayload = JwtDecode(token);
						const exp = interMediaryFix?.exp;
						if (exp === undefined) {
							return false;
						}
						console.log(Date.now() < exp * 1000);

						return Date.now() < exp * 1000;
					} catch {
						return false;
					}
				},
				fetchAccessToken,
				handleFetch: (newTokens) => {
					console.log(`handleFetch: ${newTokens}`);
					appSetAccessToken(newTokens.accessToken, newTokens.refreshToken);
				},
				handleResponse: (operation: any, accessTokenField) => {
					console.log(`handleResponse: ${accessTokenField}`);
					console.log(operation);
				},
				handleError: (err) => {
					console.log(`handleError: ${err}`);
					appSetLogout();
				},
			}),
			onError(({ graphQLErrors, networkError }) => {
				if (graphQLErrors === undefined || graphQLErrors[0].path === undefined) {
					console.log(graphQLErrors);

					return;
				}
				if (graphQLErrors[0].path[0] === "refresh") {
					console.log(networkError);

					return;
				}
				const err = graphQLErrors[0].message;
				setGQLError({ msg: err });
			}),
			requestLink,
			new HttpLink({
				uri: APOLLO_CLIENT_URI,
			}),
		]),
		cache,
	});

	return (
		<AppStateContext.Provider
			value={{
				appState,
				gqlError,
				appSetLogin,
				appSetLogout,
				appSetAccessToken,
				appClearAccessToken,
				appGetUserID,
				appGetUserRole,
			}}
		>
			<ApolloProvider client={client}>{props.children}</ApolloProvider>
		</AppStateContext.Provider>
	);
}

export default AppStateProvider;
