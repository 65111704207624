/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./styles/QuizModulePage.scss";
import {
	EnduserCertificate,
	EnduserQuiz,
	EnduserSlide,
	EnduserStatement,
	QuizState,
	RandomSlide,
	UserScreenCertificationStep,
} from "../models";
import Loading from "../global/components/Loading";
import QuizModule from "./QuizModule";
import {
	useGetContentCertificateLazyQuery,
	useGetSasLazyQuery,
	useGetSasQuery,
	useQuiz_GetQuizContentLazyQuery,
	useQuiz_InitialCallCreateOnlineQuizMutation,
	useQuiz_StartContentMutation,
} from "../../graphql/generated/endusergraphql";
import { getStringsForChosenLanguage } from "../../translations/translationProvider";
import { LanguageType, Translation } from "../../translations/translationTypes";
import Countdown from "react-countdown";
import { hmsToSecondsOnly } from "../../services/utilFunctions";
import { BlobServiceClient } from "@azure/storage-blob";
import { storageAccount } from "../../constants/azureConstants";
import { ResultStatusType } from "../userScreenPage/components/UserScreen7";
import Counter from "./components/Counter";
import { convertIDToLanguageString } from "../../translations/translationEnumConverter";

interface stateType {
	from: { pathname: string };
	cpuserid: number;
	certificate: EnduserCertificate;
	enduser: {
		phonenumber: string;
		name: string;
		optional: string;
	};
}

interface QuizModulePageProps {
	isPreview?: boolean;
	passedQuizState?: EnduserQuiz;
}

/* This is the module, that gets the data and starts the quiz. It also manages where in the quiz it is by "currentCount" */
export const QuizModulePage: React.FC<QuizModulePageProps> = (props: QuizModulePageProps) => {
	const [currentCount, setCurrentCount] = useState(0);
	const [tempLoader, setTempLoader] = useState(props.isPreview ? false : true);
	const location = useLocation<stateType>();
	const [SASToken, setSASToken] = useState("");

	const history = useHistory();


	const certificateID = props.isPreview ? 0 : location.state.certificate.id;

	const EnduserPhone = props.isPreview ? "0" : location.state.enduser.phonenumber;

	const timeToComplete = props.isPreview ? "01:00:00" : location.state.certificate.timeToComplete;

	const languageID = props.isPreview ? 1 : location.state.certificate.languageID;

	/* For blob service to reach the files */

	const account = storageAccount;

	const sas = SASToken;
	const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?`);

	const getBlob = async (blobName: string) => {
		try {
			if (blobName.includes("certificate")) {
				const containerClient = blobServiceClient.getContainerClient(`user${location.state.cpuserid}`);
				const blobClient = containerClient.getBlobClient(blobName);
				const blobResponse = await blobClient.download();
				return await blobResponse.blobBody!;
			} else {
				return blobName;
			}
		} catch (e) {
			console.log(e);
			return blobName;
		}
	};
	const [quizToken, setQuizToken] = useState(localStorage.getItem("quizToken") || "");
	const [quizID, setQuizID] = useState<number>();
	const [quizState, setQuizState] = useState<EnduserQuiz>({
		slides: [],
		indexPlayer: new Audio(),
		soundPlayer: new Audio(),
	});

	const languageString = (): Translation => {
		return convertIDToLanguageString(languageID);
	};
	const languageStringForUse = languageString();

	const [getQuizContent, { called }] = useQuiz_GetQuizContentLazyQuery({
		variables: { online_quiz_id: quizID! },
		fetchPolicy: "network-only",
		onCompleted: async (data) => {
			console.log(data);
			const slides = await data.quiz_GetQuizContent.quiz_slides;
			const tempSlideArray: EnduserSlide[] = [];
			Promise.all(
				slides.map(async (slide, slideIndex) => {
					const tempStatementArray: EnduserStatement[] = [];
					slide.slide_statements.map(async (statement, statementIndex) => {
						tempStatementArray[statementIndex] = {
							id: statement.quiz_statement_id,
							soundText: statement.statement_narration,
							correctAnswer: statement.statement_answer_id,
							/* @ts-ignore */
							soundMedia: URL.createObjectURL(await getBlob(await statement.statement_sound_location)),
							indexSound: languageStringForUse.indexSounds[statementIndex + 1],
							answer: 0,
						};
					});
					tempSlideArray[slideIndex] = {
						id: slide.quiz_slide_id,
						introQuestionSound: slide.intro_question_media_location
							? /* @ts-ignore */
							  URL.createObjectURL(await getBlob(await slide.intro_question_media_location))
							: "",
						introQuestion: slide.intro_question,
						media:  slide.media_location.slice(0,4) === "LINK" ? slide.media_location : await getBlob(await slide.media_location),
						statements: tempStatementArray,
					};
				}),
			).then(() => {
				setQuizState({ slides: tempSlideArray, indexPlayer: new Audio(), soundPlayer: new Audio() });
				setTempLoader(false);
			});
		},
	});
	const [startQuiz] = useQuiz_StartContentMutation({
		variables: { online_quiz_id: quizID!, quiz_token_id: quizToken! },
		onCompleted: (data) => {
			getQuizContent();
		},
	});

	const [initQuiz] = useQuiz_InitialCallCreateOnlineQuizMutation({
		onCompleted: async (data) => {
			if (data.quiz_InitialCallCreateOnlineQuiz === "max_tries") {
				history.replace({
					pathname: `/${location.state.certificate.id}`,
					state: {
						userScreen: UserScreenCertificationStep.Seventh,
						resultStatus: ResultStatusType.FailedNoMoreTriesStart,
						certificate: location.state.certificate,
						isEnd: true,
						enduser: location.state.enduser,
					},
				});
			} else if (data.quiz_InitialCallCreateOnlineQuiz === "no_points_left") {
				history.replace({
					pathname: `/${location.state.cpuserid}`,
					state: {
						userScreen: UserScreenCertificationStep.First,
						isValid: false,
					},
				});
			} else {
				const setItems = () =>
					new Promise<void>(async (resolve, reject) => {
						localStorage.setItem("quizToken", data.quiz_InitialCallCreateOnlineQuiz.quiz_token_id);
						setQuizToken(await data.quiz_InitialCallCreateOnlineQuiz.quiz_token_id);
						setQuizID(Number(await data.quiz_InitialCallCreateOnlineQuiz.online_quiz_id));
						await startQuiz({
							variables: {
								quiz_token_id: await data.quiz_InitialCallCreateOnlineQuiz.quiz_token_id,
								online_quiz_id: await data.quiz_InitialCallCreateOnlineQuiz.online_quiz_id,
							},
						});
						resolve();
					});

				setItems().then(async () => {});
			}
		},
	});

	//THIS USEEFFECT WILL BE USED FOR WHEN THE QUIZ IS A PREVIEW
	useEffect(() => {
		if (props.isPreview && props.passedQuizState) {
			setQuizState(props.passedQuizState);
			console.log(props.passedQuizState);
		}
	}, []);

	const getSASToken = useGetSasQuery({
		onCompleted: (data) => {
			setSASToken(data.getSAS);
		},
		skip: props.isPreview,
	});

	useEffect(() => {
		try{
			if(props.isPreview){

			}else{

				initQuiz({
					variables: { certificate_id: certificateID, end_user_phone_number: EnduserPhone },
				});
			}
		}catch(e){
			window.location.reload();
		}
	}, []);

	return (
		<div className="quiz-module-page">
			{tempLoader ? (
				<Loading
					extraText={
						quizState.slides.length > 0
							? languageStringForUse.userScreenPage.loadingMessagesOutro
							: languageStringForUse.userScreenPage.loadingMessagesIntro
					}
				/>
			) : quizState.slides.length > 0 ? (
				<>
					<QuizModule
						isPreview={props.isPreview}
						timeToComplete={timeToComplete}
						certificate={location.state.certificate}
						setTempLoader={setTempLoader}
						enduser={location.state.enduser}
						cpuserID={location.state.cpuserid}
						quizID={quizID}
						quizState={quizState}
						slide={quizState.slides[currentCount]}
						currentCount={currentCount}
						setCurrentCount={setCurrentCount}
						slideArrayLength={quizState.slides.length}
						chosenLanguage={languageStringForUse}
					/>
				</>
			) : (
				<Loading />
			)}
		</div>
	);
};
export default QuizModulePage;
