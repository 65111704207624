import React, { FC, useEffect } from "react";
import { getChosenLanguage, getStringsForChosenLanguage } from "../../../translations/translationProvider";
import UserScreenFailIcon from "./icons/UserScreenFailIcon";
import UserScreenRetryIcon from "./icons/UserScreenRetryIcon";
import UserScreenDiplomaIcon from "./icons/UserScreenDiplomaIcon";
import UserScreenFrameIcon from "./icons/UserScreenFrameIcon";
import UserScreenLinkedInIcon from "./icons/UserScreenLinkedInIcon";
import "./styles/UserScreen7.scss";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { CertificateHeadlineType, EnduserCertificate, EnduserQuiz } from "../../models";
import { PATH_QUIZ_MODULE } from "../../../constants/pathConstants";
import Diploma from "../../global/components/Diploma";
import { BlobProvider, pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import {
	convertCertificateHeadlineTypeToString,
	convertIDToHeadlineType,
	convertIDToLanguageString,
} from "../../../translations/translationEnumConverter";
import { Translation } from "../../../translations/translationTypes";
import { azureEnduserCertificates } from "../../../constants/azureConstants";
import { BlobServiceClient } from "@azure/storage-blob";
import { useGetSaspdfQuery, useGetUserLogoCertLazyQuery } from "../../../graphql/generated/endusergraphql";
import { useState } from "react";
import { makeid } from "../../../services/utilFunctions";
import { Button } from "@mui/material";
import UserResultView from "../../userResultView/UserResultView";


export enum ResultStatusType {
	Passed = 1,
	FailedMoreTries = 2,
	FailedNoMoreTries = 3,
	FailedNoMoreTriesStart = 4,
}

export interface UserScreen7Props {
	resultStatus: ResultStatusType;
	certificateData: any;
	logo: string;
	languageString: Translation;
	diplomaLogo: string;
}

interface stateType {
	from: { pathname: string };
	endedPrematurely: boolean;
	cpuserid: number;
	certificate: EnduserCertificate;
	enduser: {
		phonenumber: string;
		name: string;
		optional: string;
	};
	isReadOnly?: boolean;
	finishDate?: Date;
	quizStatus: any,
	quizState?: EnduserQuiz;
}

const convertImgToBase64URL = (url) =>
		new Promise((resolve, reject) => {
			const img = new Image();
			img.crossOrigin = "Anonymous";
			img.onerror = (e) => {
				reject(e);
			};
			img.onload = function () {
				let canvas = document.createElement("CANVAS");
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				const ctx = canvas.getContext("2d");
				let dataURL;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				canvas.height = img.height;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				canvas.width = img.width;
				ctx.drawImage(img, 0, 0, img.width, img.height);
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				// eslint-disable-next-line prefer-const
				dataURL = canvas.toDataURL();
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				canvas = null;
				resolve({ dataURL });
			};
			img.src = url;
		});

/**
 * UserScreenResult
 * This user screen display result to user
 */
export const UserScreen7: FC<UserScreen7Props> = (props: UserScreen7Props) => {
	const languageStrings = props.languageString;
	const location = useLocation<stateType>();

	const [actualDiploma, setactualDiploma] = useState<string>();

	const [isResultShown, setisResultShown] = useState(false);

	useEffect(() => {
		if(props.diplomaLogo){
			convertImgToBase64URL(props.diplomaLogo).then((data) => {
				if (data) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					setactualDiploma(data.dataURL);
				}
			});
		}
	}, [props.diplomaLogo]);

	const [isReady, setIsReady] = useState(false);
	useEffect(() => {
		setIsReady(true);
	}, []);

	const { url } = useRouteMatch();

	const [blobForLinkedIn, setBlobForLinkedIn] = useState<Blob>();

	/* history to manage route. redirect to quiz module */
	const history = useHistory();

	const id = location.pathname.substr(1);

	const account = azureEnduserCertificates;
	const sas = useGetSaspdfQuery().data?.getSASPDF;
	const blobServiceClient = new BlobServiceClient(`https://${account}.blob.core.windows.net?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2025-11-01T19:18:05Z&st=2024-01-04T11:18:05Z&spr=https&sig=EVrmYUMpA1FpiaikAWTB9k6sHQL6XR4Kjb%2Ft4CtmCZU%3D`);

	const createContainer = async (id: string) => {
		const containerName = `certificate${id}`;
		try {
			const containerClient = blobServiceClient.getContainerClient(containerName);
			await containerClient.createIfNotExists({ access: "blob" });
		} catch (error) {}

		return containerName;
	};
	const uploadDiploma = async (logoFile: Blob) => {
		const containerClient = blobServiceClient.getContainerClient(`certificate${id}`);
		const content = logoFile;
		const blobName = `${id}_${props.certificateData.enduser_id}${makeid(7)}`;
		const blockBlobClient = containerClient.getBlockBlobClient(blobName);
		await blockBlobClient.upload(content, content.size, {
			blobHTTPHeaders: { blobContentType: logoFile.type },
		});
		return blockBlobClient.url.replace(sas!, "");
	};

	const diploma = () => {
		return (
			<Diploma
				studentName={location.state.enduser.name}
				contentProvider={props.certificateData.content_provider}
				diplomaTypeHeader={convertIDToHeadlineType(props.certificateData.cert_headline_id)}
				certificateName={props.certificateData.cert_name}
				description={props.certificateData.cert_content_desc}
				optionalInfo={props.certificateData.cert_optional_value}
				clientName={props.certificateData.client_name}
				uploadedLogo={actualDiploma}
				languageString={props.certificateData.ep_cert_language_id}
				certificateID={Number(id)}
				enduserID={props.certificateData.enduser_id}
				finishDate={location.state.finishDate?.toDateString()}
			/>
		);
	};

	const handleDownloadDiploma = (): void => {
		const PDF = pdf(diploma()).toBlob();
		PDF.then((data) => {
			const fileURL = URL.createObjectURL(data);
			const link = document.createElement("a");
			link.href = fileURL;
			link.download =
				convertCertificateHeadlineTypeToString(
					convertIDToHeadlineType(props.certificateData.cert_headline_id),
					languageStrings,
				) + ".pdf";
			link.click();
		});

		//SEE DOWNLOAD PDF LINK https://react-pdf.org/advanced#on-the-fly-rendering
		//MAKE USE OF THIS PACKAGE FOR DOWNLOADING
	};

	//Method for displaying how many slides passed
	const displaySlidesPassed = () => {
		if(location.state.quizStatus){
			return (<><div className="descriptionText">Du bestod {location.state.quizStatus.correctAmount} ud af {location.state.quizStatus.totalAmount} slides</div>{location.state.quizState ? <div className="descriptionText clickable" onClick={() => setisResultShown(true)}>Se dit resultat her</div> : null}</>)
		}
	}

	const handleApplyToLinkedIn = async (): Promise<void> => {
		const blob = pdf(diploma()).toBlob();

		try {
			createContainer(id).then(async () => {
				await uploadDiploma(await blob).then((data) => {
					const newWindow = window.open(
						`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${
							props.certificateData.cert_name
						}&organizationName=${
							props.certificateData.content_provider
						}&issueYear=${new Date().getFullYear()}&issueMonth=${
							new Date().getMonth() + 1
						}&expirationYear=${new Date().getFullYear() + 5}&expirationMonth=${
							new Date().getMonth() + 1
						}&certUrl=${data.substring(0, data.length - 1)}&certId=${
							id + " - " + props.certificateData.enduser_id
						}`,
					);
					newWindow?.focus;
				});
			});
		} catch (e) {
			alert("Something went wrong, reload and try again");
		}
	};

	const handleRetry = (): void => {
		history.push({
			pathname: `${url}/quiz-module`,
			state: {
				cpuserid: location.state.cpuserid,
				certificate: location.state.certificate,
				enduser: location.state.enduser,
			},
		});
	};

	const passedCertificateInfo = (
		<div className="passed">
			{!location.state.isReadOnly ? (
				<>
					<div className="descriptionText">
						<div className="firstText">{languageStrings.userScreenPage.userScreen7.congratulations} </div>
						<div className="secondText">
							{languageStrings.userScreenPage.userScreen7.passedCertificateInformation}
						</div>
					</div>
					<div className="icons">
						{props.certificateData.cert_download_allowed ? (
							<div className="iconBox">
								<UserScreenDiplomaIcon handleClick={handleDownloadDiploma} />
								{languageStrings.userScreenPage.userScreen7.downloadDiploma} <br></br>{" "}
								{convertCertificateHeadlineTypeToString(
									convertIDToHeadlineType(props.certificateData.cert_headline_id),
									languageStrings,
								) + " PDF"}
							</div>
						) : null}
						{/* <div className="iconBox">
					<UserScreenFrameIcon handleClick={handleFrameOrderPrint} />
					{languageStrings.userScreenPage.userScreen7.orderPrint}
				</div> */}
						{props.certificateData.cert_download_allowed ? (
							<div className="iconBox">
								<UserScreenLinkedInIcon handleClick={handleApplyToLinkedIn} />
								{languageStrings.userScreenPage.userScreen7.applyToLinkedIn}
							</div>
						): null
						}
					</div>
					{displaySlidesPassed()}
				</>
			) : (
				<>
				<div className="descriptionText">
					<div className="secondText">
						{languageStrings.userScreenPage.userScreen7.passedCertificateInformation}
					</div>
				</div>
				<div className="failButton">
				<Button onClick={handleRetry}>Prøv igen</Button>
			</div>
				</>
			)}
		</div>
	);

	const failedMoreTriesCertificateInfo = (
		<>
			<div className="descriptionText">
				{languageStrings.userScreenPage.userScreen7.failedCertificateInformationMoreTries}
			</div>
			{displaySlidesPassed()}
			<div className="failButton">
				<Button onClick={handleRetry}>Prøv igen</Button>
			</div>
		</>
	);

	const failedNoMoreTriesCertificateInfo = (
		<>
			<div className="failIcon">
				<UserScreenFailIcon />
			</div>
			{displaySlidesPassed()}
			<div className="descriptionText">
				{languageStrings.userScreenPage.userScreen7.failedCertificateInformationNoMoreTries}
			</div>
		</>
	);

	const failedNoMoreTriesStart = (
		<>
			<div className="failIcon">
				<UserScreenFailIcon />
			</div>
			{displaySlidesPassed()}
			<div className="descriptionText">
				{languageStrings.userScreenPage.userScreen7.failedCertificateInformationNoMoreTriesStart}
			</div>
		</>
	);

	const renderCertificationResult = (): JSX.Element => {
		switch (props.resultStatus) {
			case ResultStatusType.Passed: {
				return passedCertificateInfo;
			}
			case ResultStatusType.FailedMoreTries: {
				return failedMoreTriesCertificateInfo;
			}
			case ResultStatusType.FailedNoMoreTries: {
				return failedNoMoreTriesCertificateInfo;
			}
			case ResultStatusType.FailedNoMoreTriesStart: {
				return failedNoMoreTriesStart;
			}
		}
	};

	const closeResult = () => {
		setisResultShown(false);
	}

	return (<><div className="userScreen7">{renderCertificationResult()}</div>{isResultShown && location.state.quizState ? <UserResultView quizState={location.state.quizState} closeMe={closeResult} /> : null}</>);
};

export default UserScreen7;
