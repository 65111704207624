import React, { FC } from "react";

export enum IconInputType {
	File = "file",
}

export enum IconInputAcceptType {
	AudioMp3 = "audio/mp3",
	Audio = "audio/*",
	AudioWav = "audio/wav",
	VideoMp4 = "video/mp4",
	Image = "image/*",
}

export interface MediaInputButtonProps {
	inputType: IconInputType;
	acceptType: IconInputAcceptType[];
	handleButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	image?: string;
	name?: string;
	buttonText?: string;
	isImageOnRightSide?: boolean;
	classname?: string;
	hasActionOnlyOnIcon?: boolean;
}

export const MediaInputButton: FC<MediaInputButtonProps> = (props: MediaInputButtonProps) => {
	const icon = props.image ? <img src={props.image} alt="icon" /> : null;

	const onClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		const element = event.target as HTMLInputElement;
		element.value = "";
	};

	return (
		<div className={props.classname}>
			<label>
				{!props.isImageOnRightSide ? icon : null}
				<span>{props.buttonText}</span>
				<input
					type={props.inputType}
					accept={props.acceptType.toString()}
					onChange={props.handleButtonClick}
					onClick={onClick}
					name={props.name ? props.name : undefined}
				/>
				{props.isImageOnRightSide ? icon : null}
			</label>
		</div>
	);
};

export default MediaInputButton;
