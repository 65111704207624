import React, { FC } from "react";
import linkedInIcon from "../../../../assets/linkedin-icon.png";

export interface UserScreenLinkedInIconProps {
	className?: string;
	handleClick: () => void;
}

export const UserScreenLinkedInIcon: FC<UserScreenLinkedInIconProps> = (props: UserScreenLinkedInIconProps) => {
	return <img src={linkedInIcon} alt="Icon" onClick={props.handleClick} className={props.className} />;
};

export default UserScreenLinkedInIcon;
