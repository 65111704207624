import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";

import useClickOutside from "./useClickOutside";

export type IPopoverPickerProps = {
    color: string;
    onChange: any
}

const PopoverPicker: React.FC<IPopoverPickerProps> = (props: IPopoverPickerProps) => {
    const popover = useRef<any>();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <div className="picker">
      <div
        className="swatch"
        style={{ backgroundColor: props.color }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div className="popover" ref={popover}>
          <HexColorPicker color={props.color} onChange={props.onChange} />
          <HexColorInput color={props.color} onChange={props.onChange} />
        </div>
      )}
    </div>
  );
}

export { PopoverPicker };