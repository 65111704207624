import React, { useEffect, useState } from "react";
import { EnduserSlide } from "../models";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Skeleton } from "@mui/material";
import { getVideoThumbnail } from "../certificateConfigurationPage/simpleComponents/SimpleSlide";
import checkYes from "../../assets/checkmark_success_icon.png";
import checkNo from "../../assets/checkmark_failed_icon.png";
interface UserResultSlideProps extends EnduserSlide {}

const UserResultSlide: React.FC<UserResultSlideProps> = (props: UserResultSlideProps) => {
	const [previewImage, setpreviewImage] = useState<string>();

    
    const [isPassed, setisPassed] = useState<boolean>();


    useEffect(() => {
        if(props.media.slice(0,4) === "LINK"){
            const stringToputin = props.media.slice(4);
            getVideoThumbnail(stringToputin).then((data) => {
                setpreviewImage(data);
            })
        }else{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            setpreviewImage(URL.createObjectURL(props.media));
        }

        //For getting if a slide is passed
        let counter = true;
        props.statements.forEach(async (statement, statementIndex) => {
            if (statement.answer === statement.correctAnswer) {
            } else {
                counter = false;
            }
        })
        setisPassed(counter);
    }, []);

	const renderStatements = () => {
		if (props.statements) {
			return props.statements.map((statement, index) => {
				return (
					<div className="singleStatement" key={index}>
						<div className="NoCheck">
							{statement.correctAnswer === statement.answer ? <div></div> : <img src={checkNo}></img>}
						</div>
						<div className="text">{statement.soundText}</div>
					</div>
				);
			});
		}
	};
	return (
		<>
			{previewImage ? (
				<div className="UserResultSlide">
					<div className="image">
                        <div className="passStatus">{isPassed !== undefined ? <img src={isPassed ? checkYes : checkNo}></img> : null}</div>
						<img src={previewImage}></img>
					</div>
                    <div className="statements">
                    <div className="singleStatement first" key={-1}>
						<div className="text">{props.introQuestion}</div>
					</div>
                        {renderStatements()}
                    </div>
				</div>
			) : (
				<Skeleton />
			)}
		</>
	);
};

export default UserResultSlide;
