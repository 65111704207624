import React, { FC, lazy, Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Loading from "../pages/global/components/Loading";
import {
	PATH_CERTIFICATE_CONFIGURATION,
	PATH_CLIENT_MANAGEMENT,
	PATH_DASHBOARD,
	PATH_HOME,
	PATH_LOGIN,
	PATH_STATISTICS,
	PATH_USER_GUIDE,
	PATH_USER_SCREEN,
} from "../constants/pathConstants";
import { AppStateContext } from "../apollo/CustomApolloProvider";
import { fetchAccessToken } from "../apollo/apolloProviderOperations";
import { CertificateConfigurationSimple } from "../pages/certificateConfigurationPage/CertificateConfigurationSimple";

export interface RoutesProps {}

let initialized = false;

export const Routes: FC<RoutesProps> = () => {
	const { appState, appSetLogin, appSetLogout } = useContext(AppStateContext);
	const isAuthenticated = appState.loggedIn;

	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (initialized) return;
		console.log(initialized);
		initialized = true;
		fetchAccessToken()
			.then((data: any) => {
				console.log(data);
				const failed = data === undefined || data?.accessToken === undefined;
				failed ? appSetLogout() : appSetLogin(data?.accessToken, data?.refreshToken);
			})
			.catch((e) => {
				appSetLogout();
			})
			.finally(() => {
				setLoading(false);
			});
	});
	if (loading) {
		return <Loading />;
	}

	const LoginPage = lazy(() => import("../pages/loginPage/LoginPage"));
	const DashboardPage = lazy(() => import("../pages/dashboardPage/DashboardPage"));
	const ClientManagementPage = lazy(() => import("../pages/clientManagementPage/ClientManagementPage"));
	const StatisticsPage = lazy(() => import("../pages/statisticsPage/StatisticsPage"));
	const UserGuidePage = lazy(() => import("../pages/userGuidePage/UserGuidePage"));
	const UserScreenPage = lazy(() => import("../pages/userScreenPage/UserScreenPage"));
	const ErrorPage = lazy(() => import("../pages/errorPage/ErrorPage"));

	const routes = [
		<Route key="slashRedirect" exact path={PATH_HOME} render={() => <Redirect to={PATH_LOGIN} />} />,
		<Route
			key="loginPage"
			exact
			path={PATH_LOGIN}
			render={() => (!isAuthenticated ? <LoginPage /> : <Redirect to={PATH_DASHBOARD} />)}
		/>,
		<Route
			key="dashboardPage"
			exact
			path={PATH_DASHBOARD}
			render={() => (isAuthenticated ? <DashboardPage /> : <Redirect to={PATH_LOGIN} />)}
		/>,
		<Route
			key="certificateConfigurationPage"
			exact
			path={PATH_CERTIFICATE_CONFIGURATION}
			render={() => (isAuthenticated ? <CertificateConfigurationSimple/> : <Redirect to={PATH_LOGIN} />)}
		/>,
		<Route
			key="clientManagementPage"
			exact
			path={PATH_CLIENT_MANAGEMENT}
			render={() => (isAuthenticated ? <ClientManagementPage /> : <Redirect to={PATH_LOGIN} />)}
		/>,
		<Route
			key="statisticsPage"
			exact
			path={PATH_STATISTICS}
			render={() => (isAuthenticated ? <StatisticsPage /> : <Redirect to={PATH_LOGIN} />)}
		/>,
		<Route
			key="userGuidePage"
			exact
			path={PATH_USER_GUIDE}
			render={() => (isAuthenticated ? <UserGuidePage /> : <Redirect to={PATH_LOGIN} />)}
		/>,
		<Route
			key="userScreenPage"
			exact
			path={PATH_USER_SCREEN}
			render={() => (isAuthenticated ? <UserScreenPage /> : <Redirect to={PATH_LOGIN} />)}
		/>,
		<Route key="errorPage" component={ErrorPage} />,
	];

	return (
		<Suspense fallback={<Loading />}>
			<Switch>{routes}</Switch>
		</Suspense>
	);
};

export default Routes;
