import React, { useEffect, useRef, useState } from "react";
import "./styles/QuizTextDisplayer.scss";

interface QuizTextDisplayerProps {
	text: string;
}

export const QuizTextDisplayer: React.FC<QuizTextDisplayerProps> = (props: QuizTextDisplayerProps) => {
	const [currentText, setCurrentText] = useState(props.text);

	const getTextSize = () => {
		if (props.text.length > 120) {
			return "over120";
		} else if (props.text.length < 120 && props.text.length > 45) {
			return "under120";
		} else if (props.text.length < 45) {
			return "under45";
		}
	};

	useEffect(() => {
		setCurrentText(props.text);
	}, [props.text]);
	return <p className={`${getTextSize()}`}>{currentText}</p>;
};

export default QuizTextDisplayer;
