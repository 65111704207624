import React, { FC, useState } from "react";
import "../styles/QuizModule.scss";
import arrow from "../../../assets/right-arrow-icon.png";
import { QuizInstantFeedback } from "./QuizInstantFeedback";
import IntermediaryLoading from "../../global/components/IntermediaryLoading";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export interface QuizArrowProps {
	key: number;
	isAnswered: boolean;
	currentCount: number;
	slideArrayLength: number;
	onClick: () => void;
	slide: any
}
/* Component responsible for displaying the arrow to next slide */
export const QuizArrow: FC<QuizArrowProps> = (props: QuizArrowProps) => {

	const [loading, setLoading] = useState(false);
	const getArrowState = () => {
		if (props.isAnswered) {
				return "arrows";
		} else {
			return "arrows-hidden";
		}
	};
	const handleClick = () => {
		if(props.currentCount === props.slideArrayLength - 1) {
			setLoading(true);
		}
		props.onClick();
	};

	// const sideArrows = () => {
	// 	if (isAnswered) {
	// 		while (props.currentCount < props.slideArrayLength - 1) {
	// 			//Intermediary fix for disabling going back in slides
	// 			return (
	// 				<div className={getArrowState()}>
	// 					<div className="rightArrow">
	// 						<img src={arrow} onClick={incrementClick} />
	// 					</div>
	// 				</div>
	// 			);
	// 			// } else if (props.currentCount === props.slideArrayLength - 1) {
	// 			// 	return (
	// 			// 		<div className="arrows">
	// 			// 			<div className="leftArrow">
	// 			// 				<img src={arrow} className="leftArrow" onClick={decrementClick} />
	// 			// 			</div>
	// 			// 		</div>
	// 			// 	);
	// 			// } else {
	// 			// 	return (
	// 			// 		<div className="arrows">
	// 			// 			<div className="leftArrow">
	// 			// 				<img src={arrow} className="leftArrow" onClick={decrementClick} />
	// 			// 			</div>

	// 			// 			<div className="rightArrow">
	// 			// 				<img src={arrow} onClick={incrementClick} />
	// 			// 			</div>
	// 			// 		</div>
	// 			// 	);
	// 			// }
	// 		}
	// 	}
	// };

	return (
		<div className={getArrowState()}>
			{loading ? <IntermediaryLoading/> : null}
		<QuizInstantFeedback isAnswered={props.isAnswered} slide={props.slide}/>
			<div className="rightArrow">
				<ArrowCircleRightIcon onClick={handleClick} />
			</div>
		</div>
	);
};

export default QuizArrow;
