import React from "react";
import { EnduserSlide } from "../../models";
import failed from "../../../assets/checkmark_failed_icon.png";
import success from "../../../assets/checkmark_success_icon.png";
import "./styles/QuizInstantFeedback.scss";

export type IQuizInstantFeedbackProps = {
	isAnswered: boolean;
	slide: EnduserSlide;
};

const QuizInstantFeedback: React.FC<IQuizInstantFeedbackProps> = (props: IQuizInstantFeedbackProps) => {
	const defineCSSState = () => {
		if (props.isAnswered) {
			return "shown";
		} else {
			return "hidden";
		}
	};

	const passedOrNot = () => {
		let passed = true;
		props.slide.statements.forEach((statement) => {
			if (statement.correctAnswer !== 3 && statement.correctAnswer !== statement.answer) {
				console.log("i set passed to false");
					passed = false;
			}
		});
		return passed;
	};

	return (
		<div className={defineCSSState()}>
			<div className="feedbacksign">
				<div className="rightBox">{passedOrNot() ? <img src={success}></img> : <img src={failed}></img>}</div>
			</div>
		</div>
	);
};

export { QuizInstantFeedback };
