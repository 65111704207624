import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type Mutation = {
  __typename?: 'Mutation';
  /** Enduser Object management */
  verifyMobileNo: Scalars['JSON'];
  createEnduser?: Maybe<Scalars['String']>;
  updateEnduser?: Maybe<Scalars['String']>;
  deleteEnduser: Scalars['String'];
  /** Online Quiz management */
  quiz_InitialCallCreateOnlineQuiz: Scalars['JSON'];
  quiz_StartContent: Scalars['JSON'];
  quiz_gradestatement: Scalars['String'];
  quiz_gradeslide: Scalars['String'];
  quiz_grading: Scalars['JSON'];
  quiz_finalizetocreateonlinequizresult: Scalars['JSON'];
};


export type MutationVerifyMobileNoArgs = {
  certificate_id?: Maybe<Scalars['Int']>;
  cert_phone_text: Scalars['String'];
};


export type MutationCreateEnduserArgs = {
  enduser_id?: Maybe<Scalars['Int']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  end_user_phone_no: Scalars['String'];
  optional_value?: Maybe<Scalars['String']>;
  certificate_id: Scalars['Int'];
};


export type MutationUpdateEnduserArgs = {
  enduser_id?: Maybe<Scalars['Int']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  end_user_phone_no: Scalars['String'];
  optional_value?: Maybe<Scalars['String']>;
  certificate_id: Scalars['Int'];
};


export type MutationDeleteEnduserArgs = {
  enduser_id?: Maybe<Scalars['Int']>;
};


export type MutationQuiz_InitialCallCreateOnlineQuizArgs = {
  certificate_id: Scalars['Int'];
  end_user_phone_number: Scalars['String'];
};


export type MutationQuiz_StartContentArgs = {
  online_quiz_id: Scalars['Int'];
  quiz_token_id: Scalars['String'];
};


export type MutationQuiz_GradestatementArgs = {
  statement_id: Scalars['Int'];
  pass_status: Scalars['Boolean'];
};


export type MutationQuiz_GradeslideArgs = {
  quiz_slide_id: Scalars['Int'];
};


export type MutationQuiz_GradingArgs = {
  slide_to_be_graded: Quiz_Slide;
};


export type MutationQuiz_FinalizetocreateonlinequizresultArgs = {
  online_quiz_id: Scalars['Int'];
  quiz_token_id: Scalars['String'];
  enduser_certificate_link?: Maybe<Scalars['String']>;
  online_quiz_time_taken?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Enduser service data lookouts */
  getAllEnduser: Scalars['JSON'];
  getAllEnduserbyMobileno?: Maybe<Scalars['JSON']>;
  getEnduserbyId?: Maybe<Scalars['JSON']>;
  /** Online quiz data lookouts */
  quiz_GetRandomSlide?: Maybe<Scalars['JSON']>;
  quiz_GetSlideStatements?: Maybe<Scalars['JSON']>;
  quiz_GetQuizContent?: Maybe<Scalars['JSON']>;
  quiz_GetQuizSlides?: Maybe<Scalars['JSON']>;
  quiz_GetQuizStatements?: Maybe<Scalars['JSON']>;
  /** Certificate/Content service data lookouts */
  getCertificate?: Maybe<Scalars['JSON']>;
  getUserLogoCert?: Maybe<Scalars['String']>;
  getContentCertificate: Scalars['JSON'];
  /** Azure queries */
  getSAS: Scalars['String'];
  getSASPDF: Scalars['String'];
  encodingProgress: Scalars['JSON'];
};


export type QueryGetAllEnduserbyMobilenoArgs = {
  enduser_mobile_no?: Maybe<Scalars['String']>;
};


export type QueryGetEnduserbyIdArgs = {
  enduser_id?: Maybe<Scalars['Int']>;
};


export type QueryQuiz_GetRandomSlideArgs = {
  certificate_id?: Maybe<Scalars['Int']>;
};


export type QueryQuiz_GetSlideStatementsArgs = {
  slide_id?: Maybe<Scalars['Int']>;
  certificate_id?: Maybe<Scalars['Int']>;
};


export type QueryQuiz_GetQuizContentArgs = {
  online_quiz_id: Scalars['Int'];
};


export type QueryQuiz_GetQuizSlidesArgs = {
  online_quiz_id: Scalars['Int'];
  certificate_id: Scalars['Int'];
};


export type QueryQuiz_GetQuizStatementsArgs = {
  slide_id: Scalars['Int'];
  certificate_id: Scalars['Int'];
};


export type QueryGetCertificateArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryGetUserLogoCertArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryGetContentCertificateArgs = {
  certificate_id: Scalars['Int'];
};


export type QueryEncodingProgressArgs = {
  certificate_id: Scalars['ID'];
};


/** Custom Types */
export type Quiz_Slide = {
  quiz_slide_id: Scalars['Int'];
  statements: Array<Maybe<Statement>>;
};

export type Statement = {
  statement_id: Scalars['Int'];
  pass_status: Scalars['Boolean'];
};

export type GetAllEnduserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllEnduserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllEnduser'>
);

export type GetAllEnduserbyMobilenoQueryVariables = Exact<{
  enduser_mobile_no?: Maybe<Scalars['String']>;
}>;


export type GetAllEnduserbyMobilenoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllEnduserbyMobileno'>
);

export type GetEnduserbyIdQueryVariables = Exact<{
  enduser_id?: Maybe<Scalars['Int']>;
}>;


export type GetEnduserbyIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEnduserbyId'>
);

export type VerifyMobileNoMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
  cert_phone_text: Scalars['String'];
}>;


export type VerifyMobileNoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'verifyMobileNo'>
);

export type CreateEnduserMutationVariables = Exact<{
  enduser_id?: Maybe<Scalars['Int']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  end_user_phone_no: Scalars['String'];
  optional_value?: Maybe<Scalars['String']>;
  certificate_id: Scalars['Int'];
}>;


export type CreateEnduserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createEnduser'>
);

export type UpdateEnduserMutationVariables = Exact<{
  enduser_id?: Maybe<Scalars['Int']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  end_user_phone_no: Scalars['String'];
  optional_value?: Maybe<Scalars['String']>;
  certificate_id: Scalars['Int'];
}>;


export type UpdateEnduserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateEnduser'>
);

export type DeleteEnduserMutationVariables = Exact<{
  enduser_id: Scalars['Int'];
}>;


export type DeleteEnduserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEnduser'>
);

export type Quiz_InitialCallCreateOnlineQuizMutationVariables = Exact<{
  certificate_id: Scalars['Int'];
  end_user_phone_number: Scalars['String'];
}>;


export type Quiz_InitialCallCreateOnlineQuizMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'quiz_InitialCallCreateOnlineQuiz'>
);

export type GetContentCertificateQueryVariables = Exact<{
  certificate_id: Scalars['Int'];
}>;


export type GetContentCertificateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContentCertificate'>
);

export type GetCertificateQueryVariables = Exact<{
  certificate_id: Scalars['Int'];
}>;


export type GetCertificateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCertificate'>
);

export type GetSasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSasQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSAS'>
);

export type Quiz_StartContentMutationVariables = Exact<{
  online_quiz_id: Scalars['Int'];
  quiz_token_id: Scalars['String'];
}>;


export type Quiz_StartContentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'quiz_StartContent'>
);

export type Quiz_GetQuizContentQueryVariables = Exact<{
  online_quiz_id: Scalars['Int'];
}>;


export type Quiz_GetQuizContentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'quiz_GetQuizContent'>
);

export type Quiz_GradestatementMutationVariables = Exact<{
  statement_id: Scalars['Int'];
  pass_status: Scalars['Boolean'];
}>;


export type Quiz_GradestatementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'quiz_gradestatement'>
);

export type Quiz_GradeslideMutationVariables = Exact<{
  quiz_slide_id: Scalars['Int'];
}>;


export type Quiz_GradeslideMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'quiz_gradeslide'>
);

export type Quiz_GradingMutationVariables = Exact<{
  slide_to_be_graded: Quiz_Slide;
}>;


export type Quiz_GradingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'quiz_grading'>
);

export type Quiz_FinalizetocreateonlinequizresultMutationVariables = Exact<{
  online_quiz_id: Scalars['Int'];
  quiz_token_id: Scalars['String'];
  online_quiz_time_taken?: Maybe<Scalars['String']>;
}>;


export type Quiz_FinalizetocreateonlinequizresultMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'quiz_finalizetocreateonlinequizresult'>
);

export type EncodingProgressQueryVariables = Exact<{
  certificate_id: Scalars['ID'];
}>;


export type EncodingProgressQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'encodingProgress'>
);

export type GetSaspdfQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSaspdfQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSASPDF'>
);

export type GetUserLogoCertQueryVariables = Exact<{
  certificate_id: Scalars['Int'];
}>;


export type GetUserLogoCertQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserLogoCert'>
);


export const GetAllEnduserDocument = gql`
    query getAllEnduser {
  getAllEnduser
}
    `;

/**
 * __useGetAllEnduserQuery__
 *
 * To run a query within a React component, call `useGetAllEnduserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEnduserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEnduserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEnduserQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEnduserQuery, GetAllEnduserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEnduserQuery, GetAllEnduserQueryVariables>(GetAllEnduserDocument, options);
      }
export function useGetAllEnduserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEnduserQuery, GetAllEnduserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEnduserQuery, GetAllEnduserQueryVariables>(GetAllEnduserDocument, options);
        }
export type GetAllEnduserQueryHookResult = ReturnType<typeof useGetAllEnduserQuery>;
export type GetAllEnduserLazyQueryHookResult = ReturnType<typeof useGetAllEnduserLazyQuery>;
export type GetAllEnduserQueryResult = Apollo.QueryResult<GetAllEnduserQuery, GetAllEnduserQueryVariables>;
export const GetAllEnduserbyMobilenoDocument = gql`
    query getAllEnduserbyMobileno($enduser_mobile_no: String) {
  getAllEnduserbyMobileno(enduser_mobile_no: $enduser_mobile_no)
}
    `;

/**
 * __useGetAllEnduserbyMobilenoQuery__
 *
 * To run a query within a React component, call `useGetAllEnduserbyMobilenoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEnduserbyMobilenoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEnduserbyMobilenoQuery({
 *   variables: {
 *      enduser_mobile_no: // value for 'enduser_mobile_no'
 *   },
 * });
 */
export function useGetAllEnduserbyMobilenoQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEnduserbyMobilenoQuery, GetAllEnduserbyMobilenoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEnduserbyMobilenoQuery, GetAllEnduserbyMobilenoQueryVariables>(GetAllEnduserbyMobilenoDocument, options);
      }
export function useGetAllEnduserbyMobilenoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEnduserbyMobilenoQuery, GetAllEnduserbyMobilenoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEnduserbyMobilenoQuery, GetAllEnduserbyMobilenoQueryVariables>(GetAllEnduserbyMobilenoDocument, options);
        }
export type GetAllEnduserbyMobilenoQueryHookResult = ReturnType<typeof useGetAllEnduserbyMobilenoQuery>;
export type GetAllEnduserbyMobilenoLazyQueryHookResult = ReturnType<typeof useGetAllEnduserbyMobilenoLazyQuery>;
export type GetAllEnduserbyMobilenoQueryResult = Apollo.QueryResult<GetAllEnduserbyMobilenoQuery, GetAllEnduserbyMobilenoQueryVariables>;
export const GetEnduserbyIdDocument = gql`
    query getEnduserbyId($enduser_id: Int) {
  getEnduserbyId(enduser_id: $enduser_id)
}
    `;

/**
 * __useGetEnduserbyIdQuery__
 *
 * To run a query within a React component, call `useGetEnduserbyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnduserbyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnduserbyIdQuery({
 *   variables: {
 *      enduser_id: // value for 'enduser_id'
 *   },
 * });
 */
export function useGetEnduserbyIdQuery(baseOptions?: Apollo.QueryHookOptions<GetEnduserbyIdQuery, GetEnduserbyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnduserbyIdQuery, GetEnduserbyIdQueryVariables>(GetEnduserbyIdDocument, options);
      }
export function useGetEnduserbyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnduserbyIdQuery, GetEnduserbyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnduserbyIdQuery, GetEnduserbyIdQueryVariables>(GetEnduserbyIdDocument, options);
        }
export type GetEnduserbyIdQueryHookResult = ReturnType<typeof useGetEnduserbyIdQuery>;
export type GetEnduserbyIdLazyQueryHookResult = ReturnType<typeof useGetEnduserbyIdLazyQuery>;
export type GetEnduserbyIdQueryResult = Apollo.QueryResult<GetEnduserbyIdQuery, GetEnduserbyIdQueryVariables>;
export const VerifyMobileNoDocument = gql`
    mutation verifyMobileNo($certificate_id: Int!, $cert_phone_text: String!) {
  verifyMobileNo(
    certificate_id: $certificate_id
    cert_phone_text: $cert_phone_text
  )
}
    `;
export type VerifyMobileNoMutationFn = Apollo.MutationFunction<VerifyMobileNoMutation, VerifyMobileNoMutationVariables>;

/**
 * __useVerifyMobileNoMutation__
 *
 * To run a mutation, you first call `useVerifyMobileNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMobileNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMobileNoMutation, { data, loading, error }] = useVerifyMobileNoMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      cert_phone_text: // value for 'cert_phone_text'
 *   },
 * });
 */
export function useVerifyMobileNoMutation(baseOptions?: Apollo.MutationHookOptions<VerifyMobileNoMutation, VerifyMobileNoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyMobileNoMutation, VerifyMobileNoMutationVariables>(VerifyMobileNoDocument, options);
      }
export type VerifyMobileNoMutationHookResult = ReturnType<typeof useVerifyMobileNoMutation>;
export type VerifyMobileNoMutationResult = Apollo.MutationResult<VerifyMobileNoMutation>;
export type VerifyMobileNoMutationOptions = Apollo.BaseMutationOptions<VerifyMobileNoMutation, VerifyMobileNoMutationVariables>;
export const CreateEnduserDocument = gql`
    mutation createEnduser($enduser_id: Int, $first_name: String!, $last_name: String!, $end_user_phone_no: String!, $optional_value: String, $certificate_id: Int!) {
  createEnduser(
    enduser_id: $enduser_id
    first_name: $first_name
    last_name: $last_name
    end_user_phone_no: $end_user_phone_no
    optional_value: $optional_value
    certificate_id: $certificate_id
  )
}
    `;
export type CreateEnduserMutationFn = Apollo.MutationFunction<CreateEnduserMutation, CreateEnduserMutationVariables>;

/**
 * __useCreateEnduserMutation__
 *
 * To run a mutation, you first call `useCreateEnduserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnduserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnduserMutation, { data, loading, error }] = useCreateEnduserMutation({
 *   variables: {
 *      enduser_id: // value for 'enduser_id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      end_user_phone_no: // value for 'end_user_phone_no'
 *      optional_value: // value for 'optional_value'
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useCreateEnduserMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnduserMutation, CreateEnduserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEnduserMutation, CreateEnduserMutationVariables>(CreateEnduserDocument, options);
      }
export type CreateEnduserMutationHookResult = ReturnType<typeof useCreateEnduserMutation>;
export type CreateEnduserMutationResult = Apollo.MutationResult<CreateEnduserMutation>;
export type CreateEnduserMutationOptions = Apollo.BaseMutationOptions<CreateEnduserMutation, CreateEnduserMutationVariables>;
export const UpdateEnduserDocument = gql`
    mutation updateEnduser($enduser_id: Int, $first_name: String!, $last_name: String!, $end_user_phone_no: String!, $optional_value: String, $certificate_id: Int!) {
  updateEnduser(
    enduser_id: $enduser_id
    first_name: $first_name
    last_name: $last_name
    end_user_phone_no: $end_user_phone_no
    optional_value: $optional_value
    certificate_id: $certificate_id
  )
}
    `;
export type UpdateEnduserMutationFn = Apollo.MutationFunction<UpdateEnduserMutation, UpdateEnduserMutationVariables>;

/**
 * __useUpdateEnduserMutation__
 *
 * To run a mutation, you first call `useUpdateEnduserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnduserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnduserMutation, { data, loading, error }] = useUpdateEnduserMutation({
 *   variables: {
 *      enduser_id: // value for 'enduser_id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      end_user_phone_no: // value for 'end_user_phone_no'
 *      optional_value: // value for 'optional_value'
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useUpdateEnduserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnduserMutation, UpdateEnduserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnduserMutation, UpdateEnduserMutationVariables>(UpdateEnduserDocument, options);
      }
export type UpdateEnduserMutationHookResult = ReturnType<typeof useUpdateEnduserMutation>;
export type UpdateEnduserMutationResult = Apollo.MutationResult<UpdateEnduserMutation>;
export type UpdateEnduserMutationOptions = Apollo.BaseMutationOptions<UpdateEnduserMutation, UpdateEnduserMutationVariables>;
export const DeleteEnduserDocument = gql`
    mutation deleteEnduser($enduser_id: Int!) {
  deleteEnduser(enduser_id: $enduser_id)
}
    `;
export type DeleteEnduserMutationFn = Apollo.MutationFunction<DeleteEnduserMutation, DeleteEnduserMutationVariables>;

/**
 * __useDeleteEnduserMutation__
 *
 * To run a mutation, you first call `useDeleteEnduserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnduserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnduserMutation, { data, loading, error }] = useDeleteEnduserMutation({
 *   variables: {
 *      enduser_id: // value for 'enduser_id'
 *   },
 * });
 */
export function useDeleteEnduserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEnduserMutation, DeleteEnduserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEnduserMutation, DeleteEnduserMutationVariables>(DeleteEnduserDocument, options);
      }
export type DeleteEnduserMutationHookResult = ReturnType<typeof useDeleteEnduserMutation>;
export type DeleteEnduserMutationResult = Apollo.MutationResult<DeleteEnduserMutation>;
export type DeleteEnduserMutationOptions = Apollo.BaseMutationOptions<DeleteEnduserMutation, DeleteEnduserMutationVariables>;
export const Quiz_InitialCallCreateOnlineQuizDocument = gql`
    mutation quiz_InitialCallCreateOnlineQuiz($certificate_id: Int!, $end_user_phone_number: String!) {
  quiz_InitialCallCreateOnlineQuiz(
    certificate_id: $certificate_id
    end_user_phone_number: $end_user_phone_number
  )
}
    `;
export type Quiz_InitialCallCreateOnlineQuizMutationFn = Apollo.MutationFunction<Quiz_InitialCallCreateOnlineQuizMutation, Quiz_InitialCallCreateOnlineQuizMutationVariables>;

/**
 * __useQuiz_InitialCallCreateOnlineQuizMutation__
 *
 * To run a mutation, you first call `useQuiz_InitialCallCreateOnlineQuizMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuiz_InitialCallCreateOnlineQuizMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizInitialCallCreateOnlineQuizMutation, { data, loading, error }] = useQuiz_InitialCallCreateOnlineQuizMutation({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *      end_user_phone_number: // value for 'end_user_phone_number'
 *   },
 * });
 */
export function useQuiz_InitialCallCreateOnlineQuizMutation(baseOptions?: Apollo.MutationHookOptions<Quiz_InitialCallCreateOnlineQuizMutation, Quiz_InitialCallCreateOnlineQuizMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Quiz_InitialCallCreateOnlineQuizMutation, Quiz_InitialCallCreateOnlineQuizMutationVariables>(Quiz_InitialCallCreateOnlineQuizDocument, options);
      }
export type Quiz_InitialCallCreateOnlineQuizMutationHookResult = ReturnType<typeof useQuiz_InitialCallCreateOnlineQuizMutation>;
export type Quiz_InitialCallCreateOnlineQuizMutationResult = Apollo.MutationResult<Quiz_InitialCallCreateOnlineQuizMutation>;
export type Quiz_InitialCallCreateOnlineQuizMutationOptions = Apollo.BaseMutationOptions<Quiz_InitialCallCreateOnlineQuizMutation, Quiz_InitialCallCreateOnlineQuizMutationVariables>;
export const GetContentCertificateDocument = gql`
    query getContentCertificate($certificate_id: Int!) {
  getContentCertificate(certificate_id: $certificate_id)
}
    `;

/**
 * __useGetContentCertificateQuery__
 *
 * To run a query within a React component, call `useGetContentCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentCertificateQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useGetContentCertificateQuery(baseOptions: Apollo.QueryHookOptions<GetContentCertificateQuery, GetContentCertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentCertificateQuery, GetContentCertificateQueryVariables>(GetContentCertificateDocument, options);
      }
export function useGetContentCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentCertificateQuery, GetContentCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentCertificateQuery, GetContentCertificateQueryVariables>(GetContentCertificateDocument, options);
        }
export type GetContentCertificateQueryHookResult = ReturnType<typeof useGetContentCertificateQuery>;
export type GetContentCertificateLazyQueryHookResult = ReturnType<typeof useGetContentCertificateLazyQuery>;
export type GetContentCertificateQueryResult = Apollo.QueryResult<GetContentCertificateQuery, GetContentCertificateQueryVariables>;
export const GetCertificateDocument = gql`
    query getCertificate($certificate_id: Int!) {
  getCertificate(certificate_id: $certificate_id)
}
    `;

/**
 * __useGetCertificateQuery__
 *
 * To run a query within a React component, call `useGetCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useGetCertificateQuery(baseOptions: Apollo.QueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCertificateQuery, GetCertificateQueryVariables>(GetCertificateDocument, options);
      }
export function useGetCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCertificateQuery, GetCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCertificateQuery, GetCertificateQueryVariables>(GetCertificateDocument, options);
        }
export type GetCertificateQueryHookResult = ReturnType<typeof useGetCertificateQuery>;
export type GetCertificateLazyQueryHookResult = ReturnType<typeof useGetCertificateLazyQuery>;
export type GetCertificateQueryResult = Apollo.QueryResult<GetCertificateQuery, GetCertificateQueryVariables>;
export const GetSasDocument = gql`
    query getSAS {
  getSAS
}
    `;

/**
 * __useGetSasQuery__
 *
 * To run a query within a React component, call `useGetSasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSasQuery(baseOptions?: Apollo.QueryHookOptions<GetSasQuery, GetSasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSasQuery, GetSasQueryVariables>(GetSasDocument, options);
      }
export function useGetSasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSasQuery, GetSasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSasQuery, GetSasQueryVariables>(GetSasDocument, options);
        }
export type GetSasQueryHookResult = ReturnType<typeof useGetSasQuery>;
export type GetSasLazyQueryHookResult = ReturnType<typeof useGetSasLazyQuery>;
export type GetSasQueryResult = Apollo.QueryResult<GetSasQuery, GetSasQueryVariables>;
export const Quiz_StartContentDocument = gql`
    mutation quiz_StartContent($online_quiz_id: Int!, $quiz_token_id: String!) {
  quiz_StartContent(
    online_quiz_id: $online_quiz_id
    quiz_token_id: $quiz_token_id
  )
}
    `;
export type Quiz_StartContentMutationFn = Apollo.MutationFunction<Quiz_StartContentMutation, Quiz_StartContentMutationVariables>;

/**
 * __useQuiz_StartContentMutation__
 *
 * To run a mutation, you first call `useQuiz_StartContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuiz_StartContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizStartContentMutation, { data, loading, error }] = useQuiz_StartContentMutation({
 *   variables: {
 *      online_quiz_id: // value for 'online_quiz_id'
 *      quiz_token_id: // value for 'quiz_token_id'
 *   },
 * });
 */
export function useQuiz_StartContentMutation(baseOptions?: Apollo.MutationHookOptions<Quiz_StartContentMutation, Quiz_StartContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Quiz_StartContentMutation, Quiz_StartContentMutationVariables>(Quiz_StartContentDocument, options);
      }
export type Quiz_StartContentMutationHookResult = ReturnType<typeof useQuiz_StartContentMutation>;
export type Quiz_StartContentMutationResult = Apollo.MutationResult<Quiz_StartContentMutation>;
export type Quiz_StartContentMutationOptions = Apollo.BaseMutationOptions<Quiz_StartContentMutation, Quiz_StartContentMutationVariables>;
export const Quiz_GetQuizContentDocument = gql`
    query quiz_GetQuizContent($online_quiz_id: Int!) {
  quiz_GetQuizContent(online_quiz_id: $online_quiz_id)
}
    `;

/**
 * __useQuiz_GetQuizContentQuery__
 *
 * To run a query within a React component, call `useQuiz_GetQuizContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuiz_GetQuizContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuiz_GetQuizContentQuery({
 *   variables: {
 *      online_quiz_id: // value for 'online_quiz_id'
 *   },
 * });
 */
export function useQuiz_GetQuizContentQuery(baseOptions: Apollo.QueryHookOptions<Quiz_GetQuizContentQuery, Quiz_GetQuizContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Quiz_GetQuizContentQuery, Quiz_GetQuizContentQueryVariables>(Quiz_GetQuizContentDocument, options);
      }
export function useQuiz_GetQuizContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Quiz_GetQuizContentQuery, Quiz_GetQuizContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Quiz_GetQuizContentQuery, Quiz_GetQuizContentQueryVariables>(Quiz_GetQuizContentDocument, options);
        }
export type Quiz_GetQuizContentQueryHookResult = ReturnType<typeof useQuiz_GetQuizContentQuery>;
export type Quiz_GetQuizContentLazyQueryHookResult = ReturnType<typeof useQuiz_GetQuizContentLazyQuery>;
export type Quiz_GetQuizContentQueryResult = Apollo.QueryResult<Quiz_GetQuizContentQuery, Quiz_GetQuizContentQueryVariables>;
export const Quiz_GradestatementDocument = gql`
    mutation quiz_gradestatement($statement_id: Int!, $pass_status: Boolean!) {
  quiz_gradestatement(statement_id: $statement_id, pass_status: $pass_status)
}
    `;
export type Quiz_GradestatementMutationFn = Apollo.MutationFunction<Quiz_GradestatementMutation, Quiz_GradestatementMutationVariables>;

/**
 * __useQuiz_GradestatementMutation__
 *
 * To run a mutation, you first call `useQuiz_GradestatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuiz_GradestatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizGradestatementMutation, { data, loading, error }] = useQuiz_GradestatementMutation({
 *   variables: {
 *      statement_id: // value for 'statement_id'
 *      pass_status: // value for 'pass_status'
 *   },
 * });
 */
export function useQuiz_GradestatementMutation(baseOptions?: Apollo.MutationHookOptions<Quiz_GradestatementMutation, Quiz_GradestatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Quiz_GradestatementMutation, Quiz_GradestatementMutationVariables>(Quiz_GradestatementDocument, options);
      }
export type Quiz_GradestatementMutationHookResult = ReturnType<typeof useQuiz_GradestatementMutation>;
export type Quiz_GradestatementMutationResult = Apollo.MutationResult<Quiz_GradestatementMutation>;
export type Quiz_GradestatementMutationOptions = Apollo.BaseMutationOptions<Quiz_GradestatementMutation, Quiz_GradestatementMutationVariables>;
export const Quiz_GradeslideDocument = gql`
    mutation quiz_gradeslide($quiz_slide_id: Int!) {
  quiz_gradeslide(quiz_slide_id: $quiz_slide_id)
}
    `;
export type Quiz_GradeslideMutationFn = Apollo.MutationFunction<Quiz_GradeslideMutation, Quiz_GradeslideMutationVariables>;

/**
 * __useQuiz_GradeslideMutation__
 *
 * To run a mutation, you first call `useQuiz_GradeslideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuiz_GradeslideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizGradeslideMutation, { data, loading, error }] = useQuiz_GradeslideMutation({
 *   variables: {
 *      quiz_slide_id: // value for 'quiz_slide_id'
 *   },
 * });
 */
export function useQuiz_GradeslideMutation(baseOptions?: Apollo.MutationHookOptions<Quiz_GradeslideMutation, Quiz_GradeslideMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Quiz_GradeslideMutation, Quiz_GradeslideMutationVariables>(Quiz_GradeslideDocument, options);
      }
export type Quiz_GradeslideMutationHookResult = ReturnType<typeof useQuiz_GradeslideMutation>;
export type Quiz_GradeslideMutationResult = Apollo.MutationResult<Quiz_GradeslideMutation>;
export type Quiz_GradeslideMutationOptions = Apollo.BaseMutationOptions<Quiz_GradeslideMutation, Quiz_GradeslideMutationVariables>;
export const Quiz_GradingDocument = gql`
    mutation quiz_grading($slide_to_be_graded: quiz_slide!) {
  quiz_grading(slide_to_be_graded: $slide_to_be_graded)
}
    `;
export type Quiz_GradingMutationFn = Apollo.MutationFunction<Quiz_GradingMutation, Quiz_GradingMutationVariables>;

/**
 * __useQuiz_GradingMutation__
 *
 * To run a mutation, you first call `useQuiz_GradingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuiz_GradingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizGradingMutation, { data, loading, error }] = useQuiz_GradingMutation({
 *   variables: {
 *      slide_to_be_graded: // value for 'slide_to_be_graded'
 *   },
 * });
 */
export function useQuiz_GradingMutation(baseOptions?: Apollo.MutationHookOptions<Quiz_GradingMutation, Quiz_GradingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Quiz_GradingMutation, Quiz_GradingMutationVariables>(Quiz_GradingDocument, options);
      }
export type Quiz_GradingMutationHookResult = ReturnType<typeof useQuiz_GradingMutation>;
export type Quiz_GradingMutationResult = Apollo.MutationResult<Quiz_GradingMutation>;
export type Quiz_GradingMutationOptions = Apollo.BaseMutationOptions<Quiz_GradingMutation, Quiz_GradingMutationVariables>;
export const Quiz_FinalizetocreateonlinequizresultDocument = gql`
    mutation quiz_finalizetocreateonlinequizresult($online_quiz_id: Int!, $quiz_token_id: String!, $online_quiz_time_taken: String) {
  quiz_finalizetocreateonlinequizresult(
    online_quiz_id: $online_quiz_id
    quiz_token_id: $quiz_token_id
    online_quiz_time_taken: $online_quiz_time_taken
  )
}
    `;
export type Quiz_FinalizetocreateonlinequizresultMutationFn = Apollo.MutationFunction<Quiz_FinalizetocreateonlinequizresultMutation, Quiz_FinalizetocreateonlinequizresultMutationVariables>;

/**
 * __useQuiz_FinalizetocreateonlinequizresultMutation__
 *
 * To run a mutation, you first call `useQuiz_FinalizetocreateonlinequizresultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuiz_FinalizetocreateonlinequizresultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizFinalizetocreateonlinequizresultMutation, { data, loading, error }] = useQuiz_FinalizetocreateonlinequizresultMutation({
 *   variables: {
 *      online_quiz_id: // value for 'online_quiz_id'
 *      quiz_token_id: // value for 'quiz_token_id'
 *      online_quiz_time_taken: // value for 'online_quiz_time_taken'
 *   },
 * });
 */
export function useQuiz_FinalizetocreateonlinequizresultMutation(baseOptions?: Apollo.MutationHookOptions<Quiz_FinalizetocreateonlinequizresultMutation, Quiz_FinalizetocreateonlinequizresultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Quiz_FinalizetocreateonlinequizresultMutation, Quiz_FinalizetocreateonlinequizresultMutationVariables>(Quiz_FinalizetocreateonlinequizresultDocument, options);
      }
export type Quiz_FinalizetocreateonlinequizresultMutationHookResult = ReturnType<typeof useQuiz_FinalizetocreateonlinequizresultMutation>;
export type Quiz_FinalizetocreateonlinequizresultMutationResult = Apollo.MutationResult<Quiz_FinalizetocreateonlinequizresultMutation>;
export type Quiz_FinalizetocreateonlinequizresultMutationOptions = Apollo.BaseMutationOptions<Quiz_FinalizetocreateonlinequizresultMutation, Quiz_FinalizetocreateonlinequizresultMutationVariables>;
export const EncodingProgressDocument = gql`
    query encodingProgress($certificate_id: ID!) {
  encodingProgress(certificate_id: $certificate_id)
}
    `;

/**
 * __useEncodingProgressQuery__
 *
 * To run a query within a React component, call `useEncodingProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useEncodingProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEncodingProgressQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useEncodingProgressQuery(baseOptions: Apollo.QueryHookOptions<EncodingProgressQuery, EncodingProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EncodingProgressQuery, EncodingProgressQueryVariables>(EncodingProgressDocument, options);
      }
export function useEncodingProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EncodingProgressQuery, EncodingProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EncodingProgressQuery, EncodingProgressQueryVariables>(EncodingProgressDocument, options);
        }
export type EncodingProgressQueryHookResult = ReturnType<typeof useEncodingProgressQuery>;
export type EncodingProgressLazyQueryHookResult = ReturnType<typeof useEncodingProgressLazyQuery>;
export type EncodingProgressQueryResult = Apollo.QueryResult<EncodingProgressQuery, EncodingProgressQueryVariables>;
export const GetSaspdfDocument = gql`
    query getSASPDF {
  getSASPDF
}
    `;

/**
 * __useGetSaspdfQuery__
 *
 * To run a query within a React component, call `useGetSaspdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaspdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaspdfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSaspdfQuery(baseOptions?: Apollo.QueryHookOptions<GetSaspdfQuery, GetSaspdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSaspdfQuery, GetSaspdfQueryVariables>(GetSaspdfDocument, options);
      }
export function useGetSaspdfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSaspdfQuery, GetSaspdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSaspdfQuery, GetSaspdfQueryVariables>(GetSaspdfDocument, options);
        }
export type GetSaspdfQueryHookResult = ReturnType<typeof useGetSaspdfQuery>;
export type GetSaspdfLazyQueryHookResult = ReturnType<typeof useGetSaspdfLazyQuery>;
export type GetSaspdfQueryResult = Apollo.QueryResult<GetSaspdfQuery, GetSaspdfQueryVariables>;
export const GetUserLogoCertDocument = gql`
    query getUserLogoCert($certificate_id: Int!) {
  getUserLogoCert(certificate_id: $certificate_id)
}
    `;

/**
 * __useGetUserLogoCertQuery__
 *
 * To run a query within a React component, call `useGetUserLogoCertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLogoCertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLogoCertQuery({
 *   variables: {
 *      certificate_id: // value for 'certificate_id'
 *   },
 * });
 */
export function useGetUserLogoCertQuery(baseOptions: Apollo.QueryHookOptions<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>(GetUserLogoCertDocument, options);
      }
export function useGetUserLogoCertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>(GetUserLogoCertDocument, options);
        }
export type GetUserLogoCertQueryHookResult = ReturnType<typeof useGetUserLogoCertQuery>;
export type GetUserLogoCertLazyQueryHookResult = ReturnType<typeof useGetUserLogoCertLazyQuery>;
export type GetUserLogoCertQueryResult = Apollo.QueryResult<GetUserLogoCertQuery, GetUserLogoCertQueryVariables>;