import React, { useEffect, useRef, useState } from "react";
import QuizVideoNavigation from "./QuizVideoNavigation";
import "../styles/QuizModule.scss";
import Countdown, { calcTimeDelta, formatTimeDelta, zeroPad } from "react-countdown";
import { hmsMinus, hmsToSecondsOnly } from "../../../services/utilFunctions";
interface CounterProps {
	key: string;
	timeToComplete: string;
	setTimeTaken: any;
	setEndPrematurely: any;
}

// eslint-disable-next-line react/display-name
export const Counter: React.FC<CounterProps> = React.memo((props: CounterProps) => {
	const time = Date.now() + hmsToSecondsOnly(props.timeToComplete);
	console.log(time);
	const hours = props.timeToComplete.substring(0, 2);
	const minutes = props.timeToComplete.substring(3, 5);
	const seconds = props.timeToComplete.substring(6, 8);

	return (
		<Countdown
			className="counter"
			key={props.timeToComplete}
			date={Date.now() + hmsToSecondsOnly(props.timeToComplete)}
			onTick={(data) => {
				const delta = calcTimeDelta(time);
				const formattedDelta = formatTimeDelta(delta);
				const format = `${zeroPad(formattedDelta.hours, 2)}:${zeroPad(formattedDelta.minutes, 2)}:${zeroPad(
					formattedDelta.seconds,
					2,
				)}`;
				props.setTimeTaken(hmsMinus(props.timeToComplete, format));
			}}
			onComplete={() => {
				props.setEndPrematurely(true);
			}}
		/>
	);
});

export default Counter;
