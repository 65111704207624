/* eslint-disable react/prop-types */
import { Button } from "@material-ui/core";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable, { MRT_ColumnDef, MRT_Row } from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { certTag, EnduserStats, EnduserStatsExtended } from "../../models";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Checkbox from "@mui/material/Checkbox";
import { Invoiced, useDeleteEnduserBatchMutation, useManageInvoiceMutation, useSendDiplomaSmsLazyQuery } from "../../../graphql/generated/graphql";
import { findIndexById } from "../../modelFunctions";
import { FIND_INDEX_STATUS_NOT_FOUND } from "../../../constants/generalConstants";
import "./styles/StatisticsPageTable.scss";
import { getStringsForChosenLanguage, getChosenLanguage } from "../../../translations/translationProvider";
import { useDeleteEnduserMutation } from "../../../graphql/generated/endusergraphql";
import { certificateData } from "../StatisticsPage";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import Diploma, { DiplomaProps } from "../../global/components/Diploma";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { Close } from "@material-ui/icons";
import { convertImgToBase64URL } from "../../../services/utilFunctions";
import {
	convertCertificateHeadlineTypeToString,
	convertIDToHeadlineType,
} from "../../../translations/translationEnumConverter";
import { UserInput } from "../../../graphql/generated/graphql";
import Loading from "../../global/components/Loading";

export type IStatisticsPageTableProps = {
	data?: EnduserStatsExtended[];
	tagChosen?: certTag;
	refreshTags: () => void;
	certificateData?: certificateData;
};

export function formatDate(date) {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
}


const StatisticsPageTable: React.FC<IStatisticsPageTableProps> = (props: IStatisticsPageTableProps) => {
	const location = useLocation();

	const languageString = getStringsForChosenLanguage(getChosenLanguage());

	const [diplomaInformations, setdiplomaInformations] = useState<DiplomaProps>();

	const [currentData, setcurrentData] = useState<EnduserStatsExtended[]>([]);

	const [sendDiplomaSMS] = useSendDiplomaSmsLazyQuery();

	const [isLoadingSMS, setisLoadingSMS] = useState(false);

	const handleDownloadPDF = (PDFIn: () => JSX.Element, name: string): void => {
		if (PDFIn()) {
			const PDF = pdf(PDFIn()).toBlob();
			PDF.then((data) => {
				const fileURL = URL.createObjectURL(data);
				const link = document.createElement("a");
				link.href = fileURL;
				link.download = name + ".pdf";
				link.click();
			});
		}
		//SEE DOWNLOAD PDF LINK https://react-pdf.org/advanced#on-the-fly-rendering
		//MAKE USE OF THIS PACKAGE FOR DOWNLOADING
	};

	const handleSendDiplomaSMSSingle = async (enduser: any) => {
		if(enduser && props.certificateData){

			sendDiplomaSMS({variables:{users: [{userID: enduser.id, phoneNumber: enduser.phone_number}], certInfo: {certCode: props.certificateData?.password, certName: props.certificateData?.name} }})
		}
	}

	const handleSendDiplomaSMSBulk = async (rows: MRT_Row<EnduserStatsExtended>[]) => {
		if(rows && props.certificateData){
			setisLoadingSMS(true);
			const tempEndusers: UserInput[] = [];
			rows.forEach((enduser) => {
				if(enduser.original.diplomaPassed){
					tempEndusers.push({userID: enduser.original.id, phoneNumber: enduser.original.phoneNumber})
				}
			})
			sendDiplomaSMS({variables:{users: tempEndusers, certInfo: {certCode: props.certificateData?.password, certName: props.certificateData?.name} }}).then((data) => {
				if(data.data?.sendDiplomaSMSNow === "SUCCESS"){
					setTimeout(() => setisLoadingSMS(false), 2000)
				}
			})
		}
	}


	const handleDiplomaClick = async (id?: number) => {
		if (currentData && id) {
			const chosenTableRowContendIndex = findIndexById(currentData, id);

			if (chosenTableRowContendIndex !== FIND_INDEX_STATUS_NOT_FOUND) {
				if (diplomaInformations === undefined && props.certificateData) {
					const diploma = () => {
						return (
							<Diploma
								contentProvider={props.certificateData!.authorName}
								diplomaTypeHeader={props.certificateData!.headerID}
								certificateName={props.certificateData!.name}
								description={props.certificateData!.description}
								clientName={""}
								studentName={
									currentData[chosenTableRowContendIndex].firstName +
									" " +
									currentData[chosenTableRowContendIndex].lastName
								}
								languageString={1}
								finishDate={
									currentData[chosenTableRowContendIndex].timeEnded
										? formatDate(new Date(currentData[chosenTableRowContendIndex].timeEnded!))
										: ""
								}
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								uploadedLogo={props.certificateData!.logo}
								enduserID={id}
								certificateID={props.certificateData!.id}
							/>
						);
					};
					handleDownloadPDF(
						diploma,
						formatDate(new Date(currentData[chosenTableRowContendIndex].timeEnded!)) +
							"_" +
							currentData[chosenTableRowContendIndex].firstName +
							"_" +
							currentData[chosenTableRowContendIndex].lastName,
					);
				} else {
					setdiplomaInformations(undefined);
				}
			}
		} else {
			setdiplomaInformations(undefined);
		}
	};

	const [manageInvoiced] = useManageInvoiceMutation();
	const [manageDelete] = useDeleteEnduserBatchMutation();

	const [onlyNotInvoiced, setonlyNotInvoiced] = useState(false);

	const handleInvoiceClick = (id: number, newValue: boolean) => {
		if (currentData) {
			const chosenTableRowContendIndex = findIndexById(currentData, id);

			if (chosenTableRowContendIndex !== FIND_INDEX_STATUS_NOT_FOUND) {
				manageInvoiced({ variables: { user_invoices: [{ invoiced: newValue, enduser_id: String(id) }] } });
				const tableRowsContentCopy = [...currentData];
				tableRowsContentCopy[chosenTableRowContendIndex].invoiced = newValue;
				setcurrentData(tableRowsContentCopy);
			}
		}
	};

	const handleDeleteEnduser = (id: number) => {
		if (currentData) {
			const chosenTableRowContendIndex = findIndexById(currentData, id);

			if (chosenTableRowContendIndex !== FIND_INDEX_STATUS_NOT_FOUND) {
				manageDelete({ variables: { enduser_list: { enduser_id: String(id) } } }).then(() => {
					setTimeout(() => props.refreshTags(), 2000);
				});
				const tableRowsContentCopy = [...currentData];
				tableRowsContentCopy.splice(chosenTableRowContendIndex, 1);
				setcurrentData(tableRowsContentCopy);
			}
		}
	};

	const handleOnlyNotInvoice = () => {
		setonlyNotInvoiced(!onlyNotInvoiced);
	};

	function convertDate(string) {
		const dateComponents = string.split(/[-\s:]/);
		const outputString =
			dateComponents[2] +
			"/" +
			dateComponents[1] +
			"/" +
			dateComponents[0] +
			" " +
			dateComponents[3] +
			":" +
			dateComponents[4] +
			":" +
			dateComponents[5];
		return outputString;
	}

	const handleMarkSelected = (rows: MRT_Row<EnduserStatsExtended>[]) => {
		console.log(rows);
		const rowsToAPI: Invoiced[] = [];
		rows.forEach((row, index) => {
			rowsToAPI.push({ invoiced: true, enduser_id: String(row.original.id) });
			const chosenTableRowContendIndex = findIndexById(currentData, row.original.id);

			if (chosenTableRowContendIndex !== FIND_INDEX_STATUS_NOT_FOUND) {
				const tableRowsContentCopy = [...currentData];
				tableRowsContentCopy[chosenTableRowContendIndex].invoiced = true;
				setcurrentData(tableRowsContentCopy);
			}
		});
		manageInvoiced({ variables: { user_invoices: rowsToAPI } });
	};
	const columns: MRT_ColumnDef<EnduserStatsExtended>[] = [
		{
			accessorKey: "id",
			header: "ID",
			size: 40,
		},
		{
			accessorKey: "invoiced",
			header: languageString.statisticsPage.statisticsTable.headerTitles.invoiced,
			size: 40,
			// eslint-disable-next-line react/prop-types
			Cell: ({ cell, row }) => (
				// eslint-disable-next-line react/jsx-no-comment-textnodes
				<div>
					<Checkbox
						checked={cell.getValue() === true ? true : false}
						onChange={(event) =>
							handleInvoiceClick(Number(currentData[row.index].id), event.target.checked)
						}
					/>
				</div>
			),
		},
		{
			accessorKey: "firstName",
			header: languageString.statisticsPage.statisticsTable.headerTitles.firstName,
			size: 120,
		},
		{
			accessorKey: "lastName",
			header: languageString.statisticsPage.statisticsTable.headerTitles.lastName,
			size: 120,
		},
		{
			accessorKey: "timeTaken",
			header: languageString.statisticsPage.statisticsTable.headerTitles.time,
			size: 120,
		},
		{
			accessorKey: "timeEnded",
			header: languageString.statisticsPage.statisticsTable.headerTitles.date,
			Cell: ({ cell }) => <div>{cell.getValue() !== null ? convertDate(cell.getValue()) : " "}</div>,
		},
		{
			accessorKey: "attempts",
			header: languageString.statisticsPage.statisticsTable.headerTitles.attempts,
			size: 120,
		},
		{
			accessorKey: "passed",
			header: languageString.statisticsPage.statisticsTable.headerTitles.status,
			size: 120,
			// eslint-disable-next-line react/prop-types
			Cell: ({ cell }) => (
				<div>
					{cell.getValue<any>() === true ? (
						<div>{languageString.statisticsPage.statisticsTable.headerTitles.passed}</div>
					) : (
						<div>{languageString.statisticsPage.statisticsTable.headerTitles.failed}</div>
					)}
				</div>
			),
		},
		{
			accessorKey: "diplomaPassed",
			size: 100,
			header: "",
			// eslint-disable-next-line react/prop-types
			Cell: ({ cell, row }) => (
				<div>
					{cell.getValue<any>() === true ? (
						<Button onClick={() => handleDiplomaClick(Number(currentData[row.index].id))}>
							<WorkspacePremiumIcon />
						</Button>
					) : (
						<Button disabled>
							<WorkspacePremiumIcon />
						</Button>
					)}
				</div>
			),
		},
		{
			accessorKey: "errorsInTag",
			header: languageString.statisticsPage.statisticsTable.headerTitles.diploma,
			size: 120,
		},
		{
			accessorKey: "phoneNumber",
			header: "",
			Cell: ({ cell, row }) => (
				<Button onClick={() => handleDeleteEnduser(Number(currentData[row.index].id))}>
					<ClearIcon />
				</Button>
			),
			size: 10,
		},
	];

	const handleExportRows = (rows: MRT_Row<EnduserStatsExtended>[], isDanskFørstehjælp?: boolean) => {
		console.log(rows);
		const csvExport: any[] = [];

		const csvOptions = {
			filename: isDanskFørstehjælp
				? "dfr"
				: props.certificateData!.name + (props.tagChosen ? "_" + props.tagChosen.name : ""),
			fieldSeparator: ",",
			quoteStrings: '"',
			decimalSeparator: ".",
			showLabels: true,
			useBom: true,
			useKeysAsHeaders: true,
			headers: columns.map((c) => c.header),
		};

		const csvExporter = new ExportToCsv(csvOptions);
		rows.forEach((row) => {
			if (isDanskFørstehjælp) {
				csvExport.push({
					"CPR-nummer": "-XXXX",
					Deltagernavn: row.original.firstName + " " + row.original.lastName,
					Email: " ",
				});
			} else {
				if (props.tagChosen) {
					csvExport.push({
						ID: row.original.id,
						Navn: row.original.firstName,
						Efternavn: row.original.lastName,
						Tids_Forbrug: row.original.timeTaken ? row.original.timeTaken : " ",
						Slut_tidspunkt: row.original.timeEnded ? row.original.timeEnded : " ",
						Max_antal_forsøg_nået: row.original.maxReached,
						Antal_forsøg: row.original.attempts,
						Bestået: row.original.passed,
						Antal_fejl_i_valgt_tag: row.original.errorsInTag,
						Telefon_nummer: row.original.phoneNumber,
					});
				} else {
					csvExport.push({
						ID: row.original.id,
						Navn: row.original.firstName,
						Efternavn: row.original.lastName,
						Tids_Forburg: row.original.timeTaken ? row.original.timeTaken : " ",
						Slut_tidspunkt: row.original.timeEnded ? row.original.timeEnded : " ",
						Max_antal_forsøg_nået: row.original.maxReached,
						Antal_forsøg: row.original.attempts,
						Bestået: row.original.passed,
						Telefon_nummer: row.original.phoneNumber,
					});
				}
			}
		});
		csvExporter.generateCsv(csvExport);
	};

	useEffect(() => {
		if (props.data) {
			setcurrentData(props.data);
			setonlyNotInvoiced(false);
		}
	}, [props.data]);

	useEffect(() => {
		console.log(onlyNotInvoiced);

		if (onlyNotInvoiced) {
			const currentCopy = [...currentData];
			const filtered = currentCopy.filter((enduser) => enduser.invoiced === false);
			console.log(filtered);

			setcurrentData(filtered);
		} else {
			if (props.data) {
				setcurrentData(props.data);
			}
		}
	}, [onlyNotInvoiced]);

	return (
		<div className="StatisticsPageTable">
			<MaterialReactTable
				columns={columns}
				data={currentData}
				enableRowSelection
				enableDensityToggle={false}
				enableFullScreenToggle={false}
				enableColumnVirtualization={false}
				enableColumnFilterModes={false}
				enableColumnActions={false}
				enableColumnResizing={false}
				enableColumnFilters={false}
				enableHiding={false}
				positionToolbarAlertBanner="bottom"
				state={{
					isLoading: props.data ? false : true,
					columnVisibility: { errorsInTag: props.tagChosen ? true : false },
				}}
				enablePagination={false}
				renderTopToolbarCustomActions={({ table }) => (
					<div className="bar">
						<div className="Buttons">
							<div className="exportAll">
								<Button
									disabled={table.getPrePaginationRowModel().rows.length === 0}
									//export all rows, including from the next page, (still respects filtering and sorting)
									onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
									startIcon={<FileDownloadIcon />}
									variant="contained"
								>
									{languageString.statisticsPage.statisticsTable.footer.exportAllRows}
								</Button>
							</div>
							<Button
								disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
								//only export selected rows
								onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
								startIcon={<FileDownloadIcon />}
								variant="contained"
							>
								{languageString.statisticsPage.statisticsTable.footer.exportChosenRows}
							</Button>
							<Button
								disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
								//only export selected rows
								onClick={() => handleMarkSelected(table.getSelectedRowModel().rows)}
								variant="contained"
							>
								{languageString.statisticsPage.statisticsTable.footer.markAllChosen}
							</Button>
							<Button onClick={() => handleOnlyNotInvoice()}>
								{onlyNotInvoiced
									? languageString.statisticsPage.statisticsTable.footer.showAll
									: languageString.statisticsPage.statisticsTable.footer.showOnlyNotInvoiced}
							</Button>
							<Button
								disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
								//only export selected rows
								onClick={() => handleSendDiplomaSMSBulk(table.getSelectedRowModel().rows)}
								variant="contained"
							>
								{"SMS chosen diplomas"}
							</Button>
						</div>
						<div
									className="linkTo"
									onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, true)}
								>
									DFR export
								</div>
					</div>
				)}
			/>
			{isLoadingSMS ? <div className="statsLoading"><Loading/></div> : null}
		</div>
	);
};

export { StatisticsPageTable };
