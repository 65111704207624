import React, { useEffect, useRef, useState } from "react";
import QuizVideoNavigation from "./QuizVideoNavigation";
import "../styles/QuizModule.scss";
import { Video } from "./Video";
import { VideoType } from "../../models";
import EPCover from "../../../assets/EPCover.jpg";
interface QuizMediaProps {
	key: string;
	isPreview?: boolean;
	mediaFile: string;
	isVideo: boolean;
	hasVideoEnded: boolean;
	setHasVideoEnded: (value: boolean | ((prevState: boolean) => boolean)) => void;
}
/* eslint-disable react/display-name */
export const QuizMedia: React.FC<QuizMediaProps> = React.memo((props: QuizMediaProps) => {
	return (
		<>
			<div className={"quiz-module-page__media-container"}>
				{props.hasVideoEnded ? <img className="coverImage" src={EPCover}></img> : null}
				<Video key={props.mediaFile} isPreview={props.isPreview} video={props.mediaFile} videoType={VideoType.STREAM} hasVideoEnded={props.hasVideoEnded} setHasVideoEnded={props.setHasVideoEnded}/>
			</div>
		</>
	);
});

export default QuizMedia;
