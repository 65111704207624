import React, { MouseEvent, ReactNode, useEffect } from "react";
import { AnswerButtonType, EnduserStatement } from "../../models";
import "./styles/QuizAnswerButton.scss";

interface QuizAnswerButtonProps {
	buttonType: AnswerButtonType;
	buttonText: string | ReactNode;
	onButtonClicked: () => void;
	selectedStatement: EnduserStatement;
}

export const QuizAnswerButton: React.FC<QuizAnswerButtonProps> = (props: QuizAnswerButtonProps) => {
	const { buttonType, buttonText, onButtonClicked } = props;

	const handleClick = (e: MouseEvent): void => {
		e.preventDefault();

		onButtonClicked();
	};

	const getCSSClassAnswer = () => {
		if (props.selectedStatement) {
			if (props.selectedStatement.answer !== 0) {
				if (props.selectedStatement.answer === buttonType) return "quiz-answer-button-chosen";
			} else {
				return "quiz-answer-button-notChosen";
			}
		}
	};

	const getButtonTypeCssClass = () => {
		if (buttonType === AnswerButtonType.True) {
			return "quiz-answer-button--yes";
		} else if (buttonType === AnswerButtonType.False) {
			return "quiz-answer-button--no";
		} else {
			return "";
		}
	};

	return (
		<div onClick={handleClick} className={`quiz-answer-button ${getCSSClassAnswer()}`}>
			<label className="quiz-answer-button__label">{buttonText}</label>
		</div>
	);
};

export default QuizAnswerButton;
