import React, { useEffect, useState } from "react";
import logo from "../../assets/EasyPiecyLogo.png";
import { Button } from "@material-ui/core";
import {
	useCheckPasswordLazyQuery,
	useCheckPasswordQuery,
	useGetCertificateLazyQuery,
	useGetCertificateQuery,
} from "../../graphql/generated/graphql";
import Loading from "../global/components/Loading";
import { convertImgToBase64URL } from "../../services/utilFunctions";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import Diploma from "../global/components/Diploma";
import "./UserDiplomaPage.scss"
import { formatDate } from "../statisticsPage/components/StatisticsPageTable";
import { convertCertificateHeadlineTypeToString, convertIDToHeadlineType } from "../../translations/translationEnumConverter";

interface UserDiplomaPageProps {}

const UserDiplomaPage: React.FC<UserDiplomaPageProps> = () => {
	const [secretPasswordInput, setsecretPasswordInput] = useState<string>();

	const [currentStage, setcurrentStage] = useState<number>(1);

	const [userDiplomaInfo, setuserDiplomaInfo] = useState<any>();

	const [isLoading, setisLoading] = useState(false);

	const [hasError, sethasError] = useState(false);

	const enduserID = Number(location.pathname.split("/")[2]);

	const [callCheckPassword] = useCheckPasswordLazyQuery();

	const [useQuery, { data: certificateInfo }] = useGetCertificateLazyQuery();

	const handlePasswordClick = () => {
		setisLoading(true);
		if (secretPasswordInput) {
			callCheckPassword({ variables: { id: enduserID, password: secretPasswordInput } }).then((data) => {
				console.log(data.data);
				if (data.data?.checkPassword === "WRONG PASSWORD") {
					sethasError(true);
					setisLoading(false);
					
				} else {
					const dataReturned = data.data?.checkPassword;
					useQuery({ variables: { certificate_id: dataReturned.certificate_id } }).then((data) => {
						const certData = data.data?.getCertificate;
						if (certData) {
							convertImgToBase64URL(
								`https://easypiecystorageaccountp.blob.core.windows.net/user${certData.user_id}/${dataReturned.cert_custom_logo}`,
							).then((data) => {
								setuserDiplomaInfo({
									id: dataReturned.certificate_id,
									name: dataReturned.cert_name,
									headerID: certData.cert_headline_id,
									authorName: certData.cp_name,
									description: dataReturned.cert_content_desc,
									logo: String(data),
                                    language: certData.ep_cert_language_id,
                                    userName: dataReturned.first_name + " " + dataReturned.last_name,
                                    endtime: formatDate(new Date(dataReturned.end_time))
								});
                                setTimeout(() => {setisLoading(false)
                                    setcurrentStage(2);
                                }, 2000)
                                
							}).catch((e) => {
								setuserDiplomaInfo({
									id: dataReturned.certificate_id,
									name: dataReturned.cert_name,
									headerID: certData.cert_headline_id,
									authorName: certData.cp_name,
									description: dataReturned.cert_content_desc,
									logo: "",
                                    language: certData.ep_cert_language_id,
                                    userName: dataReturned.first_name + " " + dataReturned.last_name,
                                    endtime: formatDate(new Date(dataReturned.end_time))
								});
                                setTimeout(() => {setisLoading(false)
                                    setcurrentStage(2);
                                }, 2000)
							});
						}
					});
				}
			});
		}else{
			setisLoading(false);
		}
	};

	useEffect(() => {
		console.log(userDiplomaInfo);
	}, [userDiplomaInfo]);

	//THIS ONE WILL RENDER WHEN THE CURRENT STAGE IS 1
	const renderPasswordScreen = () => {
		return (
			<div className="boox">
				<div className="inputBox">
					<div className={hasError ? "inputDiv" : "inputDiv"}>
						<input
						placeholder="Password"
						value={secretPasswordInput}
						maxLength={4}
						onChange={(event) => {setsecretPasswordInput(event.target.value); sethasError(false);}}
						></input>
						<div className="userError">{hasError ? "WRONG PASSWORD" : null}</div>
						</div>
					<Button variant="contained" onClick={handlePasswordClick}>LOGIN</Button>
				</div>
			</div>
		);
	};



    const renderPDF = () => {
        return(
            <Diploma
                contentProvider={userDiplomaInfo.authorName}
                diplomaTypeHeader={userDiplomaInfo.headerID}
                certificateName={userDiplomaInfo.name}
                description={userDiplomaInfo.description}
                optionalInfo={""}
                clientName={""}
                uploadedLogo={userDiplomaInfo.logo}
                languageString={userDiplomaInfo.language}
                studentName={userDiplomaInfo.userName}
                enduserID={enduserID}
                finishDate={userDiplomaInfo.endtime}
                certificateID={userDiplomaInfo.id}
            />
        )
    }

	const handleDownloadDiploma = (): void => {
		const PDF = pdf(renderPDF()).toBlob();
		PDF.then((data) => {
			const fileURL = URL.createObjectURL(data);
			const link = document.createElement("a");
			link.href = fileURL;
			link.download =
				userDiplomaInfo.name + ".pdf";
			link.click();
		});

		//SEE DOWNLOAD PDF LINK https://react-pdf.org/advanced#on-the-fly-rendering
		//MAKE USE OF THIS PACKAGE FOR DOWNLOADING
	};

	//THIS ONE WILL RENDER WHEN THE CURRENT STAGE IS 2
	const renderPDFDownload = () => {
		return(
			<div className="boox">
				<Button variant="contained" className="PDFDownloadButton"onClick={() => handleDownloadDiploma()}>{"DOWNLOAD DIPLOMA"}</Button>
			</div>
		)
	}
    
	//RENDER DECIDER
	const whatToRender = (current) => {
		switch (current) {
			case 1:
				return renderPasswordScreen();
			case 2:
				return renderPDFDownload();
			default:
				return renderPasswordScreen();
		}
	};
	return (
		<div className="UserDiplomaPage">
			{isLoading ? <div className="LoadingBox"><Loading /></div> : null}
			<div className="EPLogo">
					<img src={logo}></img>
				</div>
			{whatToRender(currentStage)}
		</div>
	);
};

export default UserDiplomaPage;
