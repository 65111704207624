import React, { FC } from "react";
import "./styles/CertificateConfigurationInput.scss";

export enum InputType {
	Text = "text",
	Number = "number",
	Date = "date",
	Time = "time",
}

export interface CertificateConfigurationInputProps {
	ref?: any;
	name?: string;
	isFaded: boolean;
	isRequired?: boolean;
	step?: string;
	labelText: string;
	hasSemicolonAfterLabel: boolean;
	inputType: InputType;
	placeholder: string | undefined;
	value: number | string | Date;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const transformValue = (value: number | string | Date): string | number => {
	if (value instanceof Date) {
		return value.toISOString().substring(0, 10);
	}

	return value;
};

export const CertificateConfigurationInput: FC<CertificateConfigurationInputProps> = (
	props: CertificateConfigurationInputProps,
) => {
	return (
		<div className={props.isFaded ? "inputConfiguration fadedStyle" : "inputConfiguration"}>
			<label>
				{props.labelText}
				{props.hasSemicolonAfterLabel ? ": " : ""}
			</label>
			<input
				name={props.name}
				ref={props.ref}
				step={props.step}
				type={props.inputType}
				placeholder={props.placeholder}
				value={transformValue(props.value)}
				onChange={props.handleChange}
				readOnly={props.isFaded}
				required={props.isRequired ? true : false}
			/>
		</div>
	);
};

export default CertificateConfigurationInput;
