import { Slide } from "@material-ui/core";
import React from "react";
import { zeroPad } from "react-countdown";
import { CertificateDetailsFormState, QuizState, SettingsFormState, SlideTag, slideTags } from "../pages/models";
import { BaseEnum } from "./utilTypes";

export const checkIfStringContains = (text: string, query: string): boolean => {
	if (text) {
		return text.toLowerCase().includes(query.toLowerCase());
	} else {
		return false;
	}
};

export const getEnumAsArray = (enumType: BaseEnum): string[] => {
	return Object.keys(enumType);
};

export const getIntegerValuesFromEnum = (enumType: BaseEnum): number[] => {
	return Object.keys(enumType)
		.filter((item) => !isNaN(parseInt(item)))
		.map((item) => parseInt(item));
};

export const getNumberOrEmptyStringFromEventTarget = (eventTarget: EventTarget & HTMLInputElement): number | string => {
	return isNaN(eventTarget.valueAsNumber) ? eventTarget.value : eventTarget.valueAsNumber;
};

export const parseHtmlSelectValueToEnum = (eventTarget: EventTarget & HTMLSelectElement): number => {
	return parseInt(eventTarget.value);
};

export const fillArrayWithEmptyStrings = (numberOfItemsInArray: number): string[] => {
	const inputValues: string[] = [];
	for (let i = 0; i < numberOfItemsInArray; i++) {
		inputValues.push("");
	}

	return inputValues;
};

export const getMergedStringsFromArray = (arrayToConvert: string[]): string => {
	return arrayToConvert.toString().split(",").join("");
};

export function getNextSiblingsFromRefObject<T1 extends Element, T2 extends Element>(
	refObject: React.RefObject<T1>,
): T2 {
	return refObject.current?.nextSibling as T2;
}

export function getPreviousSiblingsFromRefObject<T1 extends Element, T2 extends Element>(
	refObject: React.RefObject<T1>,
): T2 {
	return refObject.current?.previousSibling as T2;
}

export function getParentFromRefObject<T1 extends Element, T2 extends Element>(refObject: React.RefObject<T1>): T2 {
	return refObject.current?.parentElement as unknown as T2;
}

export function clearJsxElementArray<T1 extends JSX.Element[]>(array: T1): void {
	array.splice(0, array.length);
}

export function convertStringToArray(string: string): any {
	const tempArray: slideTags[] = [];
	const array = string.split(",");
	for (let i = 0; i < array.length; i++) {
		tempArray.push({
			tag_name: array[i],
		});
	}
	return tempArray;
}
export function convertArrayToString(array: SlideTag[]) {
	const nameArray: string[] = [];
	for (let i = 0; i < array.length; i++) {
		nameArray.push(array[i].name);
	}
	return nameArray.toString();
}

export function convertNulltoZero(inputValue: any) {
	const conversion = Number(inputValue);
	if (conversion === null) {
		return 0;
	} else if (inputValue === "-") {
		return 0;
	} else {
		return inputValue;
	}
}

export function isEndUser(pathString: string): boolean {
	if (pathString.includes("/easypiecylink/")) {
		return true;
	} else {
		return false;
	}
}

export function convertStringToValue(string: any): any {
	if (string == "" || string == " ") {
		return -1;
	} else {
		return Number(string);
	}
}
export function convertValueToString(value: any): any {
	if (value === -1) {
		return "";
	} else {
		return value;
	}
}

export function validateForm(
	settingsFormState: SettingsFormState,
	setSettingsFormState: any,
	certificateDetailsFormState: CertificateDetailsFormState,
	setCertificateDetailsFormState: any,
): boolean {
	const copyAndUpdateSettingsFormState = (callback: (settingsFormState: SettingsFormState) => void): void => {
		const settingsFormStateCopy: SettingsFormState = { ...settingsFormState };
		callback(settingsFormState);
		setSettingsFormState(settingsFormStateCopy);
	};
	const copyAndUpdateDetailsFormState = (
		callback: (settingsFormState: CertificateDetailsFormState) => void,
	): void => {
		const detailsFormStateCopy: CertificateDetailsFormState = { ...certificateDetailsFormState };
		callback(certificateDetailsFormState);
		setCertificateDetailsFormState(detailsFormStateCopy);
	};

	//Validation of SettingsFormState
	function checkIfDuplicateExists(mobileArray) {
		return new Set(mobileArray).size !== mobileArray.length;
	}
	let isErrorFound = false;

	/*Splitting with "," and then mapping over it, to remove any whitespaces so the validation is immune to the kind of errors */
	if (settingsFormState.listOfMobileNumbers) {
		settingsFormState.listOfMobileNumbersError = false;
		const TempMobileArray = settingsFormState.listOfMobileNumbers.split(",");
		TempMobileArray.map((number) => {
			if (number === "") {
				settingsFormState.listOfMobileNumbersError = true;
				isErrorFound = true;
			}
		});
		const mobileArray = TempMobileArray.map((string) => string.trim());
		if (checkIfDuplicateExists(mobileArray)) {
			settingsFormState.listOfMobileNumbersError = true;
			isErrorFound = true;
		}
	}

	copyAndUpdateSettingsFormState((settingsFormState) => settingsFormState);
	//Validation of DetailsFormState
	certificateDetailsFormState.chosenCertificateHeadlineError = false;
	if (certificateDetailsFormState.chosenCertificateHeadline === 0) {
		certificateDetailsFormState.chosenCertificateHeadlineError = true;
		isErrorFound = true;
	}
	certificateDetailsFormState.chosenCertificateLanguageError = false;
	if (certificateDetailsFormState.chosenCertificateLanguage === 0) {
		certificateDetailsFormState.chosenCertificateLanguageError = true;
		isErrorFound = true;
	}

	//Sending the answer back here
	if (isErrorFound) {
		return true;
	} else {
		return false;
	}
}

export function validateSlides(quizState: QuizState, setQuizState: any, shouldAutogen: boolean): boolean {
	const copyAndUpdateQuizState = (callback: (quizState: QuizState) => void): void => {
		const quizStateCopy: QuizState = { ...quizState };
		callback(quizStateCopy);
		setQuizState(quizStateCopy);
	};

	let isErrorFound = false;

	quizState.slides.map((slide) => {
		if (slide.introMedia !== undefined && slide.introductionQuestion !== undefined) {
			slide.hasIntroError = false;
			slide.hasError = false;
			if (slide.introductionQuestion === "" && !shouldAutogen) {
				slide.hasIntroError = true;
				slide.hasError = true;
				isErrorFound = true;
			}
			if (slide.introMedia === "" && !shouldAutogen) {
				slide.hasIntroError = true;
				slide.hasError = true;
				isErrorFound = true;
			}
			if (slide.introMedia === "" && !shouldAutogen) {
				slide.hasError = true;
				isErrorFound = true;
			}
		}
		if (slide.questions.length > 0) {
			slide.questions.map((statement) => {
				statement.hasError = false;
				if (statement.correctAnswer === 0) {
					statement.hasError = true;
					slide.hasError = true;
					isErrorFound = true;
				}
				if (statement.narrationMediaElement.statement === "") {
					statement.hasError = true;
					slide.hasError = true;
					isErrorFound = true;
				}
			});
		}
	});
	copyAndUpdateQuizState((quizState) => quizState);
	if (isErrorFound) {
		return false;
	} else {
		return true;
	}
}

export function hmsToSecondsOnly(str) {
	// eslint-disable-next-line prefer-const
	let p = str.split(":"),
		s = 0,
		m = 1;

	while (p.length > 0) {
		s += m * parseInt(p.pop(), 10);
		m *= 60;
	}

	return s * 1000;
}

export function hmsMinus(str, str2) {
	const number1 = hmsToSecondsOnly(str);
	const number2 = hmsToSecondsOnly(str2);

	return convertMS(number1 - number2);
}

function convertMS(ms) {
	// eslint-disable-next-line no-var
	var d, h, m, s;
	s = Math.floor(ms / 1000);
	m = Math.floor(s / 60);
	s = s % 60;
	h = Math.floor(m / 60);
	m = m % 60;
	d = Math.floor(h / 24);
	h = h % 24;
	h += d * 24;
	return zeroPad(h, 2) + ":" + zeroPad(m, 2) + ":" + zeroPad(s, 2);
}

export function makeid(length) {
	let result = "";
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export const convertImgToBase64URL = (url) =>
	new Promise((resolve, reject) => {
		const img = new Image();
		img.crossOrigin = "Anonymous";
		img.onerror = (e) => {
			reject(e);
		};
		img.onload = function () {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const canvas: HTMLCanvasElement = document.createElement("Canvas");
			const ctx = canvas.getContext("2d");
			canvas.height = img.height;
			canvas.width = img.width;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			ctx.drawImage(img, 0, 0, img.width, img.height);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const dataURL = canvas.toDataURL();
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			canvas.remove();
			resolve(dataURL);
		};
		img.src = url;
	});
