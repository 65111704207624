import React, { useEffect, useRef, useState } from "react";
import QuizVideoNavigation from "./QuizVideoNavigation";
import "../styles/QuizModule.scss";
interface QuizMediaProps {
	key: string;
	mediaFile: string;
	isVideo: boolean;
	hasVideoEnded: boolean;
	setHasVideoEnded: (value: boolean | ((prevState: boolean) => boolean)) => void;
}

export const QuizMedia: React.FC<QuizMediaProps> = (props: QuizMediaProps) => {
	const [src, setSrc] = useState<string>(props.mediaFile);

	return (
		<>
			<div
				className={
					!props.isVideo ? "quiz-module-page__media-container" : "quiz-module-page__media-container-hidden"
				}
			>
				<img src={!props.isVideo ? src : undefined} alt="image" />
			</div>
		</>
	);
};

export default QuizMedia;
