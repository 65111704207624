import React, { FC, lazy, Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { PATH_QUIZ_MODULE } from "../constants/pathConstants";
import Loading from "../pages/global/components/Loading";

export interface EndUserRoutesProps {}

export const EndUserRoutes: FC<EndUserRoutesProps> = () => {
	const UserScreenPage = lazy(() => import("../pages/userScreenPage/UserScreenPage"));
	const ErrorPage = lazy(() => import("../pages/errorPage/ErrorPage"));
	const QuizModulePage = lazy(() => import("../pages/quizModulePage/QuizModulePage"));
	console.log("i get reached in app-service");

	//Setting the title, when you are acessing it through the link.
	document.title = "Easypiecylink";

	const { path, url } = useRouteMatch();

	const EndUserroutes = [
		<Route key="userScreenPage" exact path={`${path}`} render={() => <UserScreenPage />} />,
		<Route key="quizModulePage" exact path={`${path}/quiz-module`} render={() => <QuizModulePage />} />,
		<Route key="errorPage" component={ErrorPage} />,
	];

	return (
		<Suspense fallback={<Loading />}>
			<Switch>{EndUserroutes}</Switch>
		</Suspense>
	);
};

export default EndUserRoutes;
