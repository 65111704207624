import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetCertTagInfoQuery, useGetEndusersQuery } from '../../graphql/generated/graphql';
import { EnduserStats, certTag } from '../models';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { findIndexById } from '../modelFunctions';
import { StatisticsPageNoLoginTable } from './StatisticsPageNoLoginTable';
import EasyPiecyLogo from '../global/components/EasyPiecyLogo';
import "./StatisticsPageNoLogin.scss";
import { useGetCertificateQuery } from '../../graphql/generated/endusergraphql';
import { convertImgToBase64URL } from '../../services/utilFunctions';


interface enduser{
    id: number;
    name: string;
    fails?: number;
    time_spent: string;
    attempts: number;
    reached_max_attempts: boolean;
    invoiced: boolean;
    time_taken: Date
}

interface certificateData {
	id: number;
	name: string;
	headerID: number;
	authorName: string;
	description: string;
	logo: string;
    password: string;
}

export type IStatisticsPageNoLoginProps = {
    
}

const StatisticsPageNoLogin: React.FC<IStatisticsPageNoLoginProps> = ({ }) => {
    const location = useLocation();

    const cert_id =  Number(location.pathname.split("/")[2])

    
    const [currentTags, setcurrentTags] = useState<certTag[]>();
    const [currentEndusers, setcurrentEndusers] = useState<EnduserStats[]>();
    const [activeTag, setactiveTag] = useState<certTag>();
    const [certificateData, setcertificateData] = useState<certificateData>();


    
    const {data: enduserData} = useGetEndusersQuery({variables:{certificate_id: cert_id, tag_id: activeTag ?activeTag.id : undefined}});
    const {data: certTagData} = useGetCertTagInfoQuery({variables: {cert_id: String(cert_id)}})

    
	const { data: certificateInfo } = useGetCertificateQuery({variables: {certificate_id: cert_id}})

	useEffect(() => {
		if (certificateInfo?.getCertificate) {
			const cert = certificateInfo?.getCertificate;
			if (cert.cert_custom_logo) {
				convertImgToBase64URL(
					`https://easypiecystorageaccountp.blob.core.windows.net/user${cert.user_id}/${cert.cert_custom_logo}`,
				).then((data) => {
					setcertificateData({
						id: cert_id,
						name: cert.cert_name,
						headerID: cert.cert_headline_id,
						authorName: cert.cp_name,
						description: cert.cert_content_desc,
						logo: String(data),
                        password: ""
					});
				});
			} else {
				setcertificateData({
					id: cert_id,
					name: cert.cert_name,
					headerID: cert.cert_headline_id,
					authorName: cert.cp_name,
					description: cert.cert_content_desc,
					logo: `https://easypiecystorageaccountp.blob.core.windows.net/user${cert.user_id}/${cert.cert_custom_logo}`,
                    password: ""
				});
			}
		}
	}, [certificateInfo]);


    const handleClickGraph = (data, index) => {
        if(currentTags){
            if(activeTag){
                if(findIndexById(currentTags, activeTag?.id) === index){
                    setactiveTag(undefined);
                }else{
                    setactiveTag(currentTags[index]);
                }
            }else{
                setactiveTag(currentTags[index]);
            }
            
        }
    }

    useEffect(() => {
        if(certTagData){
            const tags = certTagData.getCertTagInfo;
            const tempTags: certTag[] = [];
            tags.forEach((tag) => {
                tempTags.push({id: tag.tag_id, name: tag.tag_name, tag_fails: tag.tag_fails})
            })
            setcurrentTags(tempTags);
        }
    }, [certTagData]);

    useEffect(() => {
        if(enduserData){
        if(Array.isArray(enduserData.getEndusers)){
            const endusers = enduserData.getEndusers;
            console.log(endusers);
            
            const tempEndusers: EnduserStats[] = [];
            endusers.forEach((enduser) => {
                console.log(enduser.max_Reached);
                console.log();
                if(activeTag){
                    if(enduser.pass_status_count !== 0){
                        tempEndusers.push({
                            id: enduser.endUser_id,
                            firstName: enduser.first_name,
                            lastName: enduser.last_name,
                            attempts: enduser.no_of_attempts,
                            maxReached: Number(enduser.max_Reached) === 0 ? false : true,
                            timeTaken: enduser.online_Quiz_Time_Taken ? enduser.online_Quiz_Time_Taken : undefined,
                            errorsInTag: enduser.pass_status_count === 0 ? undefined : enduser.pass_status_count,
                            diplomaPassed: enduser.quiz_Pass_Status === 1 ? true : false,
                            passed: enduser.quiz_Pass_Status === 1 ? true : false,
                            timeEnded: enduser.end_time
                        })
                    }
                }else{

                    
                    tempEndusers.push({
                    id: enduser.endUser_id,
                    firstName: enduser.first_name,
                    lastName: enduser.last_name,
                    attempts: enduser.no_of_attempts,
                    maxReached: Number(enduser.max_Reached) === 0 ? false : true,
                    timeTaken: enduser.online_Quiz_Time_Taken ? enduser.online_Quiz_Time_Taken : undefined,
                    errorsInTag: enduser.pass_status_count === 0 ? undefined : enduser.pass_status_count,
                    diplomaPassed: enduser.quiz_Pass_Status === 1 ? true : false,
                    passed: enduser.quiz_Pass_Status === 1 ? true : false,
                    timeEnded: enduser.end_time
                })
            }
            })
            console.log(tempEndusers);
            
            setcurrentEndusers(tempEndusers.sort((a,b) => {return a.id - b.id}));
        }        
    }
    }, [enduserData?.getEndusers]);
    

    //THIS IS RESPONSIBLE FOR DISPLAYING THE TAGS THAT ARE CURRENTLY ON THE CERTIFICATE
    const tagChart = (
        currentTags ? 
          <div className='ChartBox'>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart width={150} height={40} data={currentTags}>
                    <Tooltip formatter={(value, name, props) => [value, "Students"]} labelFormatter={(value, payload) => {
                        if(payload[0]){
                            return payload[0].payload.name;
                        }
                        }}
                        />
                <Bar dataKey="tag_fails" onClick={handleClickGraph}>
                  {currentTags.map((entry, index) => (
                    <Cell cursor="pointer" fill={activeTag ? index === findIndexById(currentTags, activeTag.id) ? '#711b41' : '#0066ff' : "#0066ff" } key={`cell-${index}`} />
                  ))}
                </Bar>
                <XAxis dataKey="name" stroke='white'/>
                <YAxis stroke='white'/>
              </BarChart>
            </ResponsiveContainer>
          </div>
    : null
    )
    
    return (
        <div className='StatisticsPageNoLogin'>
            <EasyPiecyLogo/>
            {tagChart}
            <StatisticsPageNoLoginTable data={currentEndusers} tagChosen={activeTag} certificateData={certificateData} />
        </div>
    );
}

export { StatisticsPageNoLogin };