import React, { FC } from "react";
import diplomaIcon from "../../../../assets/diploma-icon.png";

export interface UserScreenDiplomaIconProps {
	className?: string;
	handleClick: () => void;
}

export const UserScreenDiplomaIcon: FC<UserScreenDiplomaIconProps> = (props: UserScreenDiplomaIconProps) => {
	return <img src={diplomaIcon} alt="Icon" onClick={props.handleClick} className={props.className} />;
};

export default UserScreenDiplomaIcon;
